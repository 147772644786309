import React from "react";
import ReactDOM from "react-dom/client";

import "react-dates-17/initialize";
import "react-dates-17/lib/css/_datepicker.css";
import "rc-slider/assets/index.css";

import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import environment from "config/environment";
import ReactPixel from "library/react-facebook-pixel";
import { FetchInterceptor } from "intercepters/FetchInterceptor";
import { loadState } from "utils/localStorage";
import { AuthUserWithId } from "domain/user.type";

/**
 * FetchInterceptor to detect the token expiry and auto refresh it
 */
FetchInterceptor({
  refreshTokenUrl: `${environment.apiUrl}/refresh-token`,
  refreshTokenCookieName: "refresh_token",
  accessTokenCookieName: "access_token",
  signInPath: "/sign-in",
  allowedHosts: [new URL(environment.apiUrl as string).host],
  skipUrls: [
    `${environment.apiUrl}/sign-in`,
    `${environment.apiUrl}/sign-up`,
    `${environment.apiUrl}/update-password`,
  ],
}).registerInterceptors();

/**
 * Meta Pixel to track the user conversions
 */
const user = loadState<AuthUserWithId>("user");
const advancedMatching = {
  ct: "",
  country: "",
  db: "",
  em: user?.email || "",
  fn: user?.firstName || "",
  ge: "",
  ln: user?.lastName || "",
  ph: user?.mobile || "",
  st: "",
  zp: "",
};
const reactPixelOptions = {
  autoConfig: true,
  debug: false, // enable logs
};
if (environment.metaPixelId) {
  ReactPixel.init(environment.metaPixelId, advancedMatching, reactPixelOptions);
  ReactPixel.pageView();
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
