import React, { FC, useEffect } from "react";
import Glide from "@glidejs/glide";

import male1 from "images/testimonials/male1.webp";
import female1 from "images/testimonials/female1.webp";
import female2 from "images/testimonials/female2.webp";

import quotationImg from "images/quotation.png";

import useUniqueId from "hooks/useUniqueId";
import SectionHeading from "components/SectionHeading";

export interface SectionClientSayProps {
  className?: string;
  uniqueClassName: string;
}

const REVIEWS = [
  {
    id: 1,
    clientName: "Mrinal Saurabh",
    clientAddress: "Bangalore",
    photo: male1,
    content:
      "The hotels provided were beyond my expectations. Great service by the team, we had a great stay!",
  },
  {
    id: 2,
    clientName: "Shraddha Kaur",
    clientAddress: "Delhi",
    photo: female1,
    content:
      "The holiday packages are really of great value and has great no of options in less price.",
  },
  {
    id: 3,
    clientName: "Berta Emili",
    clientAddress: "Mumbai",
    photo: female2,
    content:
      "The diskounto holidays is always my first choice. They help to plan the stays and activities in very affordable prices.",
  },
];

const SectionClientSay: FC<SectionClientSayProps> = ({
  className = "",
  uniqueClassName = "",
}) => {
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}` + useUniqueId();

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      setTimeout(() => {
        new Glide(`.${UNIQUE_CLASS}`, {
          perView: 1,
        }).mount();
      }, 10);
    }
  }, []);

  return (
    <div
      className={`app-SectionClientSay relative ${className} `}
      data-app-id="SectionClientSay"
    >
      <SectionHeading desc="Let's see what people think of us" isCenter>
        Good news from far away
      </SectionHeading>
      <div className="relative md:mb-16 max-w-2xl mx-auto">        
        <div className={`mt-12 lg:mt-16 relative ${UNIQUE_CLASS}`}>
          <img
            className="opacity-50 hidden md:block md:opacity-100 absolute -mr-16 lg:mr-3 right-full top-1/3"
            src={quotationImg}
            alt=""
          />
          <img
            className="opacity-50 hidden md:block md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-1/3 -scale-x-100"
            src={quotationImg}
            alt=""
          />
          <div className="glide__track " data-glide-el="track">
            <ul className="glide__slides ">
              {REVIEWS.map((item) => (
                <li
                  key={item.id}
                  className="glide__slide flex flex-col items-center text-center px-12"
                >
                  <img className="mx-auto w-28 mb-3 rounded-full border-solid border-2 border-neutral-300" src={item.photo} alt="" />
                  <span className="block md:text-2xl">{item.content}</span>
                  <span className="block mt-8 md:text-2xl font-semibold">
                    {item.clientName}
                  </span>
                  <div className="flex items-center space-x-2 text-lg mt-2 text-neutral-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <span>{item.clientAddress}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div
            className="mt-10 glide__bullets flex items-center justify-center"
            data-glide-el="controls[nav]"
          >
            {REVIEWS.map((item, index) => (
              <button
                key={item.id}
                className="glide__bullet w-2 h-2 rounded-full bg-neutral-300 mx-1 focus:outline-none"
                data-glide-dir={`=${index}`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionClientSay;
