import React, { Fragment, useState } from "react";
import { Dialog, DialogBackdrop, Transition, TransitionChild } from "@headlessui/react";

import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { HotelWithId } from "domain/hotel.type";

interface HotelAmenitiesProps {
  hotel: HotelWithId;
}

const HotelAmenities = ({ hotel }: HotelAmenitiesProps) => {
  const [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const closeModalAmenities = () => {
    setIsOpenModalAmenities(false);
  };

  const openModalAmenities = () => {
    setIsOpenModalAmenities(true);
  };

  const amenities = [
    { icon: "la-snowflake", value: hotel.amenities.ac, text: "AC" },
    { icon: "la-bath ", value: hotel.amenities.toiletries, text: "Toiletries" },
    { icon: "la-stop ", value: hotel.amenities.towel, text: "Towel" },
    { icon: "la-wifi ", value: hotel.amenities.wifi, text: "WiFi" },
    { icon: "la-tv ", value: hotel.amenities.tv, text: "TV" },
    {
      icon: "la-burn ",
      value: hotel.amenities.roomHeater,
      text: "Room Heater",
    },
    { icon: "la-fan ", value: hotel.amenities.fan, text: "Fan" },
    { icon: "la-align-center", value: hotel.amenities.iron, text: "Iron" },
    {
      icon: "la-tenge ",
      value: hotel.amenities.ironingBoard,
      text: "Ironing Board",
    },
    {
      icon: "la-coffee ",
      value: hotel.amenities.coffeeKit,
      text: "Coffee Kit",
    },
    { icon: "la-plug ", value: hotel.amenities.hairDryer, text: "Hair Dryer" },
    {
      icon: "la-tshirt",
      value: hotel.amenities.clothStand,
      text: "Cloth Stand",
    },
    {
      icon: "la-ice-cream ",
      value: hotel.amenities.refrigerator,
      text: "Refrigerator",
    },
    {
      icon: "la-bed ",
      value: hotel.amenities.extraCushion,
      text: "Extra Cushion",
    },
    {
      icon: "la-shoe-prints ",
      value: hotel.amenities.slipper,
      text: "Slipper",
    },
    { icon: "la-tty ", value: hotel.amenities.intercom, text: "Intercom" },
    {
      icon: "la-bread-slice ",
      value: hotel.amenities.toaster,
      text: "Toaster",
    },
    {
      icon: "la-mug-hot",
      value: hotel.amenities.electricKettle,
      text: "Electric Kettle",
    },
  ];

  return (
    <div className="w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8">
      <div>
        <h2 className="text-2xl font-semibold">Amenities </h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          About the property's amenities and services
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
        {amenities
          .filter((amenity) => amenity.value)
          .map((item) => (
            <div key={item.text} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.text}</span>
            </div>
          ))
          .slice(0, 6)}
      </div>

      <div className="w-14 border-b border-neutral-200"></div>

      <div>
        <ButtonSecondary onClick={openModalAmenities}>
          View more amenities
        </ButtonSecondary>
      </div>
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-40" />
            </TransitionChild>TransitionChild

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {amenities.filter((amenity) => amenity.value).map((item) => (
                      <div
                        key={item.text}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-600 las ${item.icon}`}
                        ></i>
                        <span>{item.text}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </TransitionChild>TransitionChild
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HotelAmenities;
