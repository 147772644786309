import Flags from "country-flag-icons/react/3x2";

interface CountryFlagProps  {
  countryCode: string;
  title?: string;
  className?: string;
};

const CountryFlag = ({ countryCode, title, className }: CountryFlagProps) => {
  const FlagComponent = Flags[countryCode.toUpperCase() as keyof typeof Flags];
  return <FlagComponent title={title} className={className} />;
};

export default CountryFlag