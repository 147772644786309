import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface HotelGooglePlaceIdProps {
  register: UseFormRegister<HotelWithId>;
  error?: FieldError;
  className?: string;
}

const HotelGooglePlaceId: FC<HotelGooglePlaceIdProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label>Google place id</Label>
      <Input
        className="mt-1"
        placeholder="Google place id"
        {...register("address.googlePlaceId", { required: false })}
      />
      {error && (
        <span className="text-sm text-red-500">
          Please enter google place id to accurately locate on map
        </span>
      )}
    </div>
  );
};

export default HotelGooglePlaceId;
