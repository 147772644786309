import copyToClipboard from "copy-to-clipboard";
import environment from "config/environment";
import {
  ArrowUpOnSquareStackIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/24/outline";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { HotelWithId } from "domain/hotel.type";
import { generatePathWithQuery } from "utils/url";
import useFetch from "hooks/useFetch";
import List from "shared/List/List";
import LazyImage from "shared/LazyImage/LazyImage";
import Flex from "shared/Flex/Flex";
import Button from "shared/Button/Button";
import CreateHotel from "./CreateHotel";
import DeleteHotel from "./DeleteHotel";
import HotelRooms from "./HotelRooms";
import SiderContentHeading from "components/SiderContentHeading";
import ListLoader from "shared/Loader/ListLoader";

const Hotels = () => {
  const {
    isLoading,
    data = [],
    setData,
    showLoadMore,
    loadMoreData,
  } = useFetch<HotelWithId[]>({ url: `${environment.apiUrl}/hotel` }, 50);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: showLoadMore,
    onLoadMore: loadMoreData,
    disabled: false,
    rootMargin: "0px 0px 10px 0px",
  });

  return (
    <Flex className="w-full">
      <SiderContentHeading
        icon={<BuildingOfficeIcon className="w-6 mr-3" />}
        heading={"Hotels"}
        actionsComponent={
          <CreateHotel onAddHotel={(hotel) => setData([...data, hotel])} />
        }
      />
      <div className="p-5 w-full">
        <List
          loading={isLoading}
          items={data}
          rowKey="id"
          renderItem={({ item }) => (
            <div className="grid grid-cols-12 p-2 bg-white">
              <Flex direction="row" align="center" className="col-span-5">
                <ArrowUpOnSquareStackIcon
                  className="text-base cursor-pointer h-6 mr-2 hover:text-primary-700"
                  onClick={() =>
                    copyToClipboard(
                      `${environment.apiUrl}/view/hotel/${item.id}`
                    )
                  }
                />
                <LazyImage
                  src={`${environment.assetsUrl}/${item.thumbnail?.path}`}
                  className="w-10 h-10 border border-primary-500 rounded-full"
                  containerClassName="w-auto"
                />
                <Flex className="ml-2">
                  <Flex direction="row" align="center" className="text-base">
                    <div>{item.name}</div>
                  </Flex>
                  <div className="text-sm text-neutral-400">
                    {[item.place?.name, item.place?.country.name].join(", ")}
                  </div>
                </Flex>
              </Flex>
              <Flex
                direction="row"
                justify="end"
                align="center"
                className="col-span-2"
              >
                <HotelRooms hotel={item} />
              </Flex>
              <Flex
                direction="row"
                justify="end"
                align="center"
                className="col-span-5"
              >
                <Button
                  className="ml-2"
                  sizeClass="px-5 py-1"
                  targetBlank
                  href={generatePathWithQuery("/hotel/:slug?id=:hotelId", {
                    slug: item.slug,
                    hotelId: item.id,
                  })}
                >
                  View
                </Button>
                <Button
                  targetBlank
                  className="ml-2"
                  sizeClass="px-5 py-1"
                  href={generatePathWithQuery("/manage/hotel/:hotelId", {
                    hotelId: item.id,
                  })}
                >
                  Edit
                </Button>

                <DeleteHotel
                  hotel={item}
                  onDeleteHotel={(hotel) =>
                    setData(data.filter((i) => i.id !== hotel.id))
                  }
                />
              </Flex>
            </div>
          )}
        />
        {(isLoading || showLoadMore) && (
          <div ref={sentryRef}>
            <ListLoader />
          </div>
        )}
      </div>
    </Flex>
  );
};

export default Hotels;
