import React, { FC } from "react";

export interface SocialType {
  name: string;
  icon: string;
  href: string;
}


export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = [
  { name: "Facebook", icon: "lab la-facebook-square", href: "https://www.facebook.com/diskountoholidays" },
  { name: "Twitter", icon: "lab la-twitter", href: "https://twitter.com/holidays0369" },
  { name: "Youtube", icon: "lab la-youtube", href: "https://www.youtube.com/@diskountoholidays" },
  { name: "Instagram", icon: "lab la-instagram", href: "https://www.instagram.com/diskounto.holidays" },
];

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "flex items-center",
  socials = socialsDemo,
}) => {
  return (
    <nav
      className={`app-SocialsList flex space-x-2.5 text-neutral-600 dark:text-neutral-300 ${className}`}
      data-app-id="SocialsList"
    >
      {socials.map((item, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <i className={`text-2xl ${item.icon}`} />
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
