import React, { FC } from "react";
import { Link } from "react-router-dom";

import Logo from "shared/Logo/Logo";
// import MenuBar from "shared/MenuBar/MenuBar";
// import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import DropdownLookingFor from "./DropdownLookingFor";
import LangDropdown from "./LangDropdown";
import CurrencyDropdown from "./CurrencyDropdown";
import NotifyDropdown from "./NotifyDropdown";
import ProfileMenu from "./ProfileMenu";

import { useAuth } from "context/AuthProvider";
import DesktopNavigation from "./DesktopNavigation";
// import HeroSearchFormMobile from "components/HeroSearchFormMobile/HeroSearchFormMobile";

export interface HeaderNavProps {
  className?: string;
}

const HeaderNav: FC<HeaderNavProps> = ({ className = "" }) => {
  const { isAuthenticated, user } = useAuth();
  return (
    <div className={`header-nav relative z-10 ${className}`}>
      <div className="px-4 md:container py-5 relative flex justify-between items-center">
        <div className="md:flex justify-start flex-1 items-center space-x-3 sm:space-x-8 md:space-x-10">
          <Logo className="h-12" />
          <div className="hidden md:block h-10 border-l border-neutral-300 dark:border-neutral-500"></div>

          <DesktopNavigation />

          <div className="hidden">
            <DropdownLookingFor />
          </div>
        </div>

        {/* <div className="md:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchFormMobile />
        </div> */}

        <div className="xs:flex md:flex-none fflex-1 lex-shrink-0 items-center justify-end  text-neutral-700 dark:text-neutral-100">
          <div className="hidden items-center md:flex space-x-1">
            <div className="hidden">
              <CurrencyDropdown />
              <LangDropdown />
            </div>
            {!isAuthenticated && (
              <Link
                to="/sign-up"
                className="text-opacity-90 group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              >
                Become a member
              </Link>
            )}

            <div className="pr-1.5">{/* <SwitchDarkMode /> */}</div>

            <div className="hidden pr-1.5">
              <NotifyDropdown />
            </div>

            {user ? (
              <ProfileMenu />
            ) : (
              <ButtonPrimary href="/sign-in">Sign in</ButtonPrimary>
            )}
          </div>
          <div className="flex items-center space-x-2 md:hidden">
            {user ? (
              <ProfileMenu />
            ) : (
              <ButtonPrimary href="/sign-in">Sign in</ButtonPrimary>
            )}
            {/* <MenuBar /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNav;
