import copyToClipboard from "copy-to-clipboard";
import {
  ArrowUpOnSquareStackIcon,
  GlobeAsiaAustraliaIcon,
} from "@heroicons/react/24/outline";
import useInfiniteScroll from "react-infinite-scroll-hook";

import environment from "config/environment";
import useFetch from "hooks/useFetch";
import { TourWithId } from "domain/tour.type";
import List from "shared/List/List";
import LazyImage from "shared/LazyImage/LazyImage";
import Flex from "shared/Flex/Flex";
import { getTourNoOfNights } from "utils/tourUtils";
import { generatePathWithQuery } from "utils/url";
import { handlePlural } from "utils/string";
import SiderContentHeading from "components/SiderContentHeading";
import Button from "shared/Button/Button";
import ListLoader from "shared/Loader/ListLoader";
import CreateTour from "./CreateTour";
import EditTour from "./EditTour";
import DeleteTour from "./DeleteTour";
import TourStatus from "./TourStatus";
import CloneTour from "./CloneTour";

const Tours = () => {
  const {
    isLoading,
    data = [],
    setData,
    showLoadMore,
    loadMoreData,
  } = useFetch<TourWithId[]>({ url: `${environment.apiUrl}/tour` }, 50);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: showLoadMore,
    onLoadMore: loadMoreData,
    disabled: false,
    rootMargin: "0px 0px 10px 0px",
  });

  return (
    <Flex className="w-full">
      <SiderContentHeading
        icon={<GlobeAsiaAustraliaIcon className="w-6 mr-3" />}
        heading={"Tours"}
        actionsComponent={
          <CreateTour onAddTour={(tour) => setData([...data, tour])} />
        }
      />
      <div className="p-5 w-full">
        <List
          loading={isLoading}
          items={data}
          rowKey="id"
          renderItem={({ item }) => {
            const noOfNights = getTourNoOfNights(item);
            const nights = handlePlural(noOfNights, "Night", "s");
            const days = handlePlural(noOfNights + 1, "Day", "s");
            return (
              <div className="grid grid-cols-12 p-2 bg-white">
                <Flex direction="row" align="center" className="col-span-6">
                  <ArrowUpOnSquareStackIcon
                    className="text-base cursor-pointer h-6 mr-2 hover:text-primary-700"
                    onClick={() =>
                      copyToClipboard(
                        `${environment.apiUrl}/view/holiday-package/${item.id}`
                      )
                    }
                  />
                  <LazyImage
                    src={`${environment.assetsUrl}/${item.thumbnail?.path}`}
                    className="w-10 h-10 border border-primary-500 rounded-full"
                    containerClassName="w-auto"
                  />
                  <Flex className="flex-1 ml-2">
                    <Flex direction="row" align="center" className="text-base">
                      <span>{item.title}</span>
                    </Flex>

                    <div className="text-sm font-thin text-neutral-400">
                      <span className="text-sm font-thin text-neutral-500">
                        {`${nights}/${days}`}
                      </span>
                      {item.placesWithOptions.length > 0 && (
                        <span className="mx-2">•</span>
                      )}
                      <span>
                        {item.placesWithOptions
                          .map((i) => `${i.place?.name}(${i.noOfNights}N)`)
                          .join(" → ")}
                      </span>
                    </div>
                  </Flex>
                </Flex>

                <Flex
                  direction="row"
                  justify="end"
                  align="center"
                  className="col-span-6"
                >
                  <TourStatus
                    tour={item}
                    onSuccess={(tour) => {
                      setData(
                        data.map((i) =>
                          i.id === tour.id ? { ...i, status: tour.status } : i
                        )
                      );
                    }}
                  />
                  <Button
                    className="ml-4"
                    sizeClass="px-5 py-1"
                    targetBlank
                    href={generatePathWithQuery(
                      "/holiday-package/:slug?id=:tourId",
                      {
                        slug: item.slug,
                        tourId: item.id,
                      }
                    )}
                  >
                    View
                  </Button>
                  <CloneTour
                    tour={item}
                    onAddTour={(tour) => setData([...data, tour])}
                  />

                  <EditTour
                    tour={item}
                    onEditTour={(tour) =>
                      setData(
                        data.map((i) =>
                          i.id === tour.id ? { ...i, ...tour } : i
                        )
                      )
                    }
                  />
                  <DeleteTour
                    tour={item}
                    onDeleteTour={(tour) =>
                      setData(data.filter((i) => i.id !== tour.id))
                    }
                  />
                </Flex>
              </div>
            );
          }}
        />
        {(isLoading || showLoadMore) && (
          <div ref={sentryRef}>
            <ListLoader />
          </div>
        )}
      </div>
    </Flex>
  );
};

export default Tours;
