import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

import { ToursListingWithId } from "domain/tours-listing.type";
import { CustomError } from "domain/custom-error.type";
import environment from "config/environment";
import { fetcher } from "hooks/useFetch";
import TourListingSection from "components/TourListingSection";
import CommonLayout from "./CommonLayout";

export interface ToursListingPreviewProps {
  toursListing?: ToursListingWithId;
  step: number;
  onClickPrev: () => void;
  onSuccess: (data: ToursListingWithId) => void;
}

const ToursListingPreview: FC<ToursListingPreviewProps> = ({
  toursListing,
  step,
  onClickPrev = () => {},
  onSuccess = () => {},
}) => {
  const { handleSubmit } = useForm<ToursListingWithId>();
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);

  const onSubmit = async () => {
    const path = toursListing?.id ? `/tours-listing/${toursListing?.id}` : '/tours-listing'
    const method = toursListing?.id ? "PUT" : "POST"
    const result = await fetcher({
      url: `${environment.apiUrl}${path}`,
      method,
      body: JSON.stringify(toursListing),
    });
    if (result.errors) {
      setServerErrors(result.errors);
    } else {
      onSuccess(result.toursListing as ToursListingWithId);
    }
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        
        <div>
          <div className="max-w-full">
            <TourListingSection
              className="mt-8"
              toursListing={{ id: "new", ...toursListing } as ToursListingWithId}
            />
          </div>
        </div>
        <div className="col-span-2">
          {serverErrors.map((error) => (
            <p key={error.code} className="text-red-500">
              {error.userMessage}
            </p>
          ))}
        </div>
      </>
    </CommonLayout>
  );
};

export default ToursListingPreview;
