import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { pick } from "lodash";

import { SupplierWithId } from "domain/supplier.type";
import CommonLayout from "./CommonLayout";
import SupplierMarginB2B from "../Inputs/SupplierMarginB2B";
import SupplierMarginB2C from "../Inputs/SupplierMarginB2C";
import SupplierAdvancePaymentValue from "../Inputs/SupplierAdvancePaymentValue";
import SupplierBalanceDueDays from "../Inputs/SupplierBalanceDueDays";
import environment from "config/environment";
import { fetcher } from "hooks/useFetch";
import { CustomError } from "domain/custom-error.type";

export interface SupplierMarginAndPaymentFormProps {
  supplier?: SupplierWithId;
  step: number;
  onClickPrev: () => void;
  onSuccess: (data: SupplierWithId) => void;
}

const SupplierMarginAndPaymentForm: FC<SupplierMarginAndPaymentFormProps> = ({
  supplier,
  step,
  onClickPrev = () => {},
  onSuccess = () => {},
}) => {
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SupplierWithId>({
    defaultValues: pick<SupplierWithId>(supplier, ["placeId", "address"]),
  });

  const onSubmit = async () => {
    const path = supplier?.id ? `/supplier/${supplier?.id}` : "/supplier";
    const method = supplier?.id ? "PUT" : "POST";
    const result = await fetcher({
      url: `${environment.apiUrl}${path}`,
      method,
      body: JSON.stringify(supplier),
    });
    if (result.errors) {
      setServerErrors(result.errors);
    } else {
      onSuccess(result.supplier as SupplierWithId);
    }
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Margin & Payment Terms</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-2 gap-6">
          <SupplierMarginB2B
            register={register}
            error={errors.b2bMarginPercent}
          />
          <SupplierMarginB2C
            register={register}
            error={errors.b2cMarginPercent}
          />
          <SupplierAdvancePaymentValue
            register={register}
            error={errors.advancePercent}
          />
          <SupplierBalanceDueDays
            register={register}
            error={errors.balanceDueDays}
          />
          <div className="col-span-2">
            {serverErrors.map((error) => (
              <p key={error.code} className="text-red-500">
                {error.userMessage}
              </p>
            ))}
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default SupplierMarginAndPaymentForm;
