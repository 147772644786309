import React, { Fragment } from "react";
import { matchPath, useLocation } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

const LayoutWithContent = ({ children }) => {
  return <div>{children}</div>;
};

const LayoutWithHeaderAndContentAndBlankFooter = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <div>{children}</div>
    </Fragment>
  );
};

const LayoutWithHeaderAndContentAndFooter = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <div>{children}</div>
      <Footer />
    </Fragment>
  );
};

const Layout = ({ children }) => {
  const location = useLocation();

  const pagesWithContentOnly = [
    "/sign-in",
    "/sign-up",
    "/forgot-password",
    "/reset-password",
    "/admin-panel/*",
    "/manage/*",
    "/supplier-panel/*"
  ];
  const pagesWithHeaderAndContentAndNoFooter = ["/pay-us", "/payment-status"];

  const hasAnyMatch = (routes = []) =>
    !!routes.find((route) => matchPath(route, location.pathname));

  return (
    <>
      {hasAnyMatch(pagesWithContentOnly) && (
        <LayoutWithContent>{children}</LayoutWithContent>
      )}
      {hasAnyMatch(pagesWithHeaderAndContentAndNoFooter) && (
        <LayoutWithHeaderAndContentAndBlankFooter>
          {children}
        </LayoutWithHeaderAndContentAndBlankFooter>
      )}
      {!hasAnyMatch(pagesWithContentOnly) &&
        !hasAnyMatch(pagesWithHeaderAndContentAndNoFooter) && (
          <LayoutWithHeaderAndContentAndFooter>
            {children}
          </LayoutWithHeaderAndContentAndFooter>
        )}
    </>
  );
};

export default Layout;
