/**
 * React Facebook Pixel Module
 *
 * @package react-facebook-pixel
 */

import {
  Options,
  AdvancedMatching,
  Data,
  FacebookPixel,
} from "./react-facebook-pixel.d";

let initialized = false;
let debug = false;

/**
 * Utilities
 */

/**
 * Warn
 * @param  {...any} args
 */
const warn = (...args: any[]) => {
  if (!debug) {
    return;
  }
  console.info(...["[react-facebook-pixel]"].concat(args));
};

/**
 * Log
 * @param  {...any} args
 */
const log = (...args: any[]) => {
  if (!debug) {
    return;
  }
  console.info(...["[react-facebook-pixel]"].concat(args));
};

const verifyInit = (): boolean => {
  if (!initialized) {
    warn(
      "Pixel not initialized before using. Call ReactPixel.init with required params"
    );
  }
  return initialized;
};

const defaultOptions: Options = {
  autoConfig: true,
  debug: false,
};

// Declare fbq globally
declare const fbq: (...args: any[]) => void;

const Pixel: FacebookPixel = {
  init(
    pixelId: string,
    advancedMatching: AdvancedMatching = {},
    options: Options = defaultOptions
  ) {
    if (typeof window === "undefined") {
      initialized = false;
      return;
    }

    initialized = !!window.fbq;
    (function (
      f: any,
      b: Document,
      e: string,
      v: string,
      n?: any,
      t?: HTMLScriptElement,
      s?: HTMLScriptElement
    ) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e) as HTMLScriptElement;
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0] as HTMLScriptElement;
      if (s.parentNode) {
        s.parentNode.insertBefore(t, s);
      }
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );

    if (!pixelId) {
      warn("Please insert pixel id for initializing");
    } else {
      if (options.autoConfig === false) {
        fbq("set", "autoConfig", false, pixelId);
      }

      if (!verifyInit()) {
        fbq("init", pixelId, advancedMatching);
      }

      initialized = true;
      debug = options.debug;
    }
  },

  pageView() {
    if (!verifyInit()) {
      return;
    }

    fbq("track", "PageView");

    if (debug) {
      log("called fbq('track', 'PageView');");
    }
  },

  track(title: string, data?: Data) {
    if (!verifyInit()) {
      return;
    }

    fbq("track", title, data);

    if (debug) {
      log(`called fbq('track', '${title}');`);

      if (data) {
        log("with data", data);
      }
    }
  },

  trackSingle(pixel: string, title: string, data?: Data) {
    if (!verifyInit()) {
      return;
    }

    fbq("trackSingle", pixel, title, data);

    if (debug) {
      log(`called fbq('trackSingle', '${pixel}', '${title}');`);

      if (data) {
        log("with data", data);
      }
    }
  },

  trackCustom(event: string, data?: Data) {
    if (!verifyInit()) {
      return;
    }

    fbq("trackCustom", event, data);

    if (debug) {
      log(`called fbq('trackCustom', '${event}');`);

      if (data) {
        log("with data", data);
      }
    }
  },

  trackSingleCustom(pixel: string, event: string, data?: Data) {
    if (!verifyInit()) {
      return;
    }

    fbq("trackSingle", pixel, event, data);

    if (debug) {
      log(`called fbq('trackSingleCustom', '${pixel}', '${event}');`);

      if (data) {
        log("with data", data);
      }
    }
  },

  grantConsent() {
    if (!verifyInit()) {
      return;
    }

    fbq("consent", "grant");

    if (debug) {
      log(`called fbq('consent', 'grant');`);
    }
  },

  revokeConsent() {
    if (!verifyInit()) {
      return;
    }

    fbq("consent", "revoke");

    if (debug) {
      log(`called fbq('consent', 'revoke');`);
    }
  },

  fbq(...args: any[]) {
    if (!verifyInit()) {
      return;
    }

    fbq(...args);

    if (debug) {
      log(`called fbq('${args.slice(0, 2).join("', '")}')`);

      if (args[2]) {
        log("with data", args[2]);
      }
    }
  },
};

export default Pixel;
