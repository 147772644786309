import React from "react";

import { ToursListingWithId } from "domain/tours-listing.type";
import Modal from "shared/Modal/Modal";
import Button from "shared/Button/Button";
import ToursListingForm from "./ToursListingForm";

interface EditToursListingProps {
  toursListing: ToursListingWithId;
  onEditToursListing: (toursListing: ToursListingWithId) => void;
}

const EditToursListing = ({ toursListing, onEditToursListing }: EditToursListingProps) => {
  return (
    <Modal
      modalTitle={`Edit ${toursListing.title}`}
      fullScreen
      renderTrigger={({ openModal }) => (
        <Button sizeClass="px-5 py-1" onClick={openModal}>
          Edit
        </Button>
      )}
      renderContent={({ closeModal }) => {
        return (
          <ToursListingForm
            toursListing={toursListing}
            onSuccess={(toursListing) => {
              onEditToursListing(toursListing);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

export default EditToursListing;
