import environment from "config/environment";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { SupplierWithId } from "domain/supplier.type";
import { generatePathWithQuery } from "utils/url";
import useFetch from "hooks/useFetch";
import List from "shared/List/List";
import Flex from "shared/Flex/Flex";
import Button from "shared/Button/Button";
import ListLoader from "shared/Loader/ListLoader";
import CreateSupplier from "./CreateSupplier";
import DeleteSupplier from "./DeleteSupplier";
import SiderContentHeading from "components/SiderContentHeading";

const Suppliers = () => {
  const {
    isLoading,
    data = [],
    setData,
    showLoadMore,
    loadMoreData,
  } = useFetch<SupplierWithId[]>({ url: `${environment.apiUrl}/supplier` }, 50);
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: showLoadMore,
    onLoadMore: loadMoreData,
    disabled: false,
    rootMargin: "0px 0px 10px 0px",
  });

  return (
    <Flex className="w-full">
      <SiderContentHeading
        icon={<BuildingOfficeIcon className="w-6 mr-3" />}
        heading={"Suppliers"}
        actionsComponent={
          <CreateSupplier
            onAddSupplier={(supplier) => setData([...data, supplier])}
          />
        }
      />
      <div className="p-5 w-full">
        <List
          loading={isLoading}
          items={data}
          rowKey="id"
          renderItem={({ item }) => (
            <div className="grid grid-cols-12 p-2 bg-white">
              <Flex direction="row" align="center" className="col-span-5">
                <Flex className="ml-2">
                  <Flex direction="row" align="center" className="text-base">
                    <div>{item.name}</div>
                  </Flex>
                  <div className="text-sm text-neutral-400"></div>
                </Flex>
              </Flex>
              <Flex
                direction="row"
                justify="end"
                align="center"
                className="col-span-2"
              ></Flex>
              <Flex
                direction="row"
                justify="end"
                align="center"
                className="col-span-5"
              >
                <Button
                  targetBlank
                  className="ml-2"
                  href={generatePathWithQuery("/manage/supplier/:supplierId", {
                    supplierId: item.id,
                  })}
                >
                  Edit
                </Button>

                <DeleteSupplier
                  supplier={item}
                  onDeleteSupplier={(supplier) =>
                    setData(data.filter((i) => i.id !== supplier.id))
                  }
                />
              </Flex>
            </div>
          )}
        />
        {(isLoading || showLoadMore) && (
          <div ref={sentryRef}>
            <ListLoader />
          </div>
        )}
      </div>
    </Flex>
  );
};

export default Suppliers;
