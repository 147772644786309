import React from "react";
import environment from "config/environment";
import useFetch from "hooks/useFetch";
import { PopularPlace } from "domain/popular-place.type";
import SectionSliderCards from "./SectionSliderCards";

export interface SectionPopularDestinationsProps {
  scope: "domestic" | "international" | "pilgrimage";
}

const SectionPopularDestinations: React.FC<SectionPopularDestinationsProps> = ({
  scope,
}) => {
  const { isLoading, data = [] } = useFetch<PopularPlace[]>({
    url: `${environment.apiUrl}/site-data/popular-places/${scope}`,
  });

  if (isLoading || !data.length) {
    return null;
  }

  return (
    <SectionSliderCards
      heading="Top Destinations"
      subHeading="Discover our most popular travel spots and experience the best."
      destinations={data[0].places}
      uniqueClassName="Popular_Destination"
      cardType="large"
    />
  );
};

export default SectionPopularDestinations;
