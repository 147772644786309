import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface HotelStreetAddressProps {
  register: UseFormRegister<HotelWithId>;
  error?: FieldError;
  className?: string;
}

const HotelStreetAddress: FC<HotelStreetAddressProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label>Street Address</Label>
      <Input
        className="mt-1"
        placeholder="Street name"
        {...register("address.street", { required: true })}
      />
      {error && (
        <span className="text-sm text-red-500">Please enter street name</span>
      )}
    </div>
  );
};

export default HotelStreetAddress;
