import React, { FC, useEffect, useMemo } from "react";
import Glide from "@glidejs/glide";

import { PlaceWithId } from "domain/place.type";
import useUniqueId from "hooks/useUniqueId";
import NextPrev from "shared/NextPrev/NextPrev";
import SectionHeading from "components/SectionHeading";
import DestinationCard from "components/DestinationCard";

export interface SectionSliderCardsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  destinations?: PlaceWithId[];
  cardType?: "large" | "normal" | "small";
  itemPerRow?: 4 | 5;
  sliderButtons?: "top-right" | "bottom-center";
  uniqueClassName: string;
}

const SectionSliderCards: FC<SectionSliderCardsProps> = ({
  heading = "",
  subHeading = "",
  className = "",
  itemClassName = "",
  itemPerRow = 5,
  destinations = [],
  cardType = "normal",
  sliderButtons = "top-right",
  uniqueClassName,
}) => {
  const UNIQUE_CLASS = "SectionSliderCards__" + uniqueClassName + useUniqueId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    MY_GLIDEJS.mount();
    return () => {
      MY_GLIDEJS.destroy();
    };
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  const renderCard = (item: PlaceWithId, index: number) => {
    switch (cardType) {
      case "large":
        return <DestinationCard destination={item} size="large" />;
      case "normal":
        return <DestinationCard destination={item} size="normal" />;
      case "small":
        return <DestinationCard destination={item} size="small" />;
      default:
        return <DestinationCard destination={item} size="normal" />;
    }
  };

  return (
    <div className={`app-SectionSliderCards ${className}`}>
      <div className={`glide ${UNIQUE_CLASS} flow-root`}>
        <SectionHeading
          desc={subHeading}
          hasNextPrev={sliderButtons === "top-right"}
          isCenter={sliderButtons === "bottom-center"}
        >
          {heading}
        </SectionHeading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {destinations.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                {renderCard(item, index)}
              </li>
            ))}
          </ul>
        </div>

        {sliderButtons === "bottom-center" && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
    </div>
  );
};

export default SectionSliderCards;
