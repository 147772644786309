import React, { FC } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ArrowLongDownIcon, ArrowLongUpIcon } from "@heroicons/react/24/solid";
import { get, pick } from "lodash";
import { TourWithId } from "domain/tour.type";
import Input from "shared/Input/Input";
import Label from "shared/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import DragUploader from "components/DragUploader/DragUploader";
import Flex from "shared/Flex/Flex";

export interface DayByDayPlanProps {
  tour?: TourWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<TourWithId>) => void;
}

const DayByDayPlan: FC<DayByDayPlanProps> = ({
  tour,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TourWithId>({
    defaultValues: pick<TourWithId>(tour, ["dayByDay"]),
  });

  const { fields: days, move: moveDay } = useFieldArray({
    control,
    name: "dayByDay",
  });

  const onSubmit = (data: TourWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <div className="grid grid-cols-1 gap-6">
          {days.map((day, index) => {
            return (
              <div key={index}>
                <Flex direction="row" justify="between">
                  <h2 className="text-2xl font-semibold">Day {index + 1}</h2>
                  <Flex direction="row">
                    <ArrowLongUpIcon
                      className="w-5 h-5 text-gray-700 cursor-pointer hover:text-black"
                      onClick={() => moveDay(index, Math.max(0, index - 1))}
                    />
                    <div className="w-3" />
                    <ArrowLongDownIcon
                      className="w-5 h-5 text-gray-700 cursor-pointer hover:text-black"
                      onClick={() =>
                        moveDay(index, Math.min(days.length - 1, index + 1))
                      }
                    />
                  </Flex>
                </Flex>
                <div className="col-span-2">
                  <Label>Title</Label>
                  <Input
                    className="mt-1"
                    placeholder="Day title"
                    {...register(`dayByDay.${index}.title`, { required: true })}
                  />
                  {get(errors, `dayByDay.${index}.title`) && (
                    <span className="text-sm text-red-500">
                      Please enter day title
                    </span>
                  )}
                </div>

                <div className="col-span-2">
                  <Label>Plan</Label>
                  <Textarea
                    className="mt-1"
                    placeholder="Plan for the day"
                    {...register(`dayByDay.${index}.plan`, { required: true })}
                  />
                  {get(errors, `dayByDay.${index}.plan`) && (
                    <span className="text-sm text-red-500">
                      Please enter day plan in brief
                    </span>
                  )}
                </div>

                <div className="col-span-2">
                  <Label>Photos</Label>
                  <p className="text-xs text-neutral-500">
                    Upload few photos which will be covered in the day (.webp,
                    .jpg, .jpeg, .png & .gif). Prefer aspect ratio of 4:3(W:H).
                  </p>
                  <Controller
                    name={`dayByDay.${index}.photos`}
                    control={control}
                    // rules={{
                    //   validate: (files) => {
                    //     return !!files.length;
                    //   },
                    // }}
                    render={({ field: { onChange } }) => (
                      <DragUploader
                        mode="tour"
                        className="mt-1"
                        maxFiles={100}
                        value={day.photos}
                        defaultValue={
                          get(tour, `dayByDay.${index}.photos`) || []
                        }
                        onChange={onChange}
                      />
                    )}
                  />
                  {get(errors, `dayByDay.${index}.photos`) && (
                    <span className="text-sm text-red-500">
                      Please upload day activity photos
                    </span>
                  )}
                </div>

                {index + 1 !== days.length && (
                  <div className="col-span-2">
                    <hr className="bg-black mt-4" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </>
    </CommonLayout>
  );
};

export default DayByDayPlan;
