import React, { FC } from "react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";

import useFetch from "hooks/useFetch";
import environment from "config/environment";
import { BookingRequestWithId } from "domain/booking-request.type";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LazyImage from "shared/LazyImage/LazyImage";
import Flex from "shared/Flex/Flex";
import Badge from "shared/Badge/Badge";
import Loader from "shared/Loader/Loader";
import { handlePlural } from "utils/string";

export interface BookingRequestSuccessProps {
  className?: string;
}

const BookingRequestSuccess: FC<BookingRequestSuccessProps> = ({
  className = "",
}) => {
  const { bookingRequestId } = useParams();
  const { isLoading, data = [] } = useFetch<BookingRequestWithId[]>({
    url: `${environment.apiUrl}/my/booking-request/${bookingRequestId}`,
  });
  const bookingRequest = data[0];

  if (isLoading) {
    return <Loader />;
  }
  if (!bookingRequest) {
    return null;
  }

  const tripStartDate = moment(bookingRequest.tripStartDate);
  const tripEndDate = moment(bookingRequest.tripEndDate);
  return (
    <div
      className={`app-BookingRequestSuccess ${className}`}
      data-app-id="BookingRequestSuccess"
    >
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">
          <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
            <h2 className="text-3xl lg:text-4xl font-semibold">
              Congratulations 🎉
            </h2>

            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

            {/* ------------------------ */}
            <div className="space-y-6">
              <h3 className="text-2xl font-semibold">Your booking</h3>
              <div className="flex flex-col sm:flex-row sm:items-center">
                <div className="flex-shrink-0 w-full sm:w-40">
                    <LazyImage
                      src={`${environment.assetsUrl}/${bookingRequest.hotel?.thumbnail?.path}`}
                      containerClassName="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl"
                    />
                </div>
                <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
                  <div>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                      Hotel room at{" "}
                    </span>
                    <Link
                      to={`/hotel/${bookingRequest.hotel?.id}`}
                      className="text-base sm:text-lg font-medium mt-1 block hover:underline"
                    >
                      {bookingRequest.hotel?.name}
                    </Link>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                      {[
                        bookingRequest.hotel?.address.street,
                        bookingRequest.hotel?.place?.name,
                        bookingRequest.hotel?.place?.country.name,
                      ]
                        .join(", ")
                        .replaceAll(",,", ",")}{" "}
                    </span>
                  </div>

                  <Flex direction="row">
                    {new Array(
                      Number(bookingRequest.hotel?.details.starRating)
                    ).fill(<StarIcon className="h-5 text-yellow-500" />)}
                  </Flex>

                  <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>

                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                    {handlePlural(bookingRequest.noOfRooms, "room", "s")}
                  </span>
                </div>
              </div>
              <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                <div className="flex-1 p-5 flex space-x-4">
                  <CalendarIcon color="#D1D5DB" width={32} height={32} />
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {`${tripStartDate.format(
                        "DD MMM YYYY"
                      )} - ${tripEndDate.format("DD MMM YYYY")}`}
                    </span>
                  </div>
                </div>
                <div className="flex-1 p-5 flex space-x-4">
                  <svg
                    className="w-8 h-8 text-neutral-300 dark:text-neutral-600"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                      stroke="#D1D5DB"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {handlePlural(
                        bookingRequest.noOfAdults +
                          bookingRequest.noOfChildrens,
                        "Guest",
                        "s"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* ------------------------ */}
            <div className="space-y-6">
              <h3 className="text-2xl font-semibold">Booking details</h3>
              <div className="flex flex-col space-y-4">
                <div className="flex text-neutral-600 dark:text-neutral-300">
                  <span className="flex-1">Booking ID</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                    {bookingRequest.bookingId}
                  </span>
                </div>
                <div className="flex text-neutral-600 dark:text-neutral-300">
                  <span className="flex-1">Created At</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                    {moment(bookingRequest.createdAt).format(
                      "DD MMM YYYY hh:mm A"
                    )}
                  </span>
                </div>

                <div className="flex text-neutral-600 dark:text-neutral-300">
                  <span className="flex-1">Nights</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                    {handlePlural(bookingRequest.noOfNights, "Night", "s")}
                  </span>
                </div>

                <div className="flex text-neutral-600 dark:text-neutral-300">
                  <span className="flex-1">Status</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                    <Badge name={bookingRequest.status?.replaceAll("_", "")} />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <ButtonPrimary href="/">Explore more places</ButtonPrimary>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BookingRequestSuccess;
