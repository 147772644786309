import React from "react";
import { SupplierServiceWithId } from "domain/supplier-service.type";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import SupplierServiceForm from "./SupplierServiceForm";

interface CreateSupplierServiceProps {
  formType: "service" | "supplier";
  serviceType?: SupplierServiceWithId["serviceType"];
  service?: SupplierServiceWithId["service"];
  supplier?: SupplierServiceWithId["supplier"];
  onAddSupplierService: (supplierService: SupplierServiceWithId) => void;
}

const CreateSupplierService = ({
  formType,
  serviceType,
  service,
  supplier,
  onAddSupplierService,
}: CreateSupplierServiceProps) => {
  return (
    <Modal
      modalTitle={`Link a ${formType}`}
      className="w-1/2"
      renderTrigger={({ openModal }) => (
        <ButtonPrimary onClick={openModal}>Link {formType}</ButtonPrimary>
      )}
      renderContent={({ closeModal }) => {
        return (
          <SupplierServiceForm
            formType={formType}
            restrictToServiceType={serviceType}
            restrictToService={service}
            restrictToSupplier={supplier}
            onCancel={closeModal}
            onSuccess={(supplierService) => {
              onAddSupplierService(supplierService);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

export default CreateSupplierService;
