import { FC, ReactNode, useId } from "react";

type Value = string | number | boolean;
interface RadioGroupProps {
  name?: string;
  className?: string;
  options: {
    label: ReactNode;
    value: Value;
  }[];
  value?: Value;
  onChange?: (checkedValue: Value) => void;
}

const RadioGroup: FC<RadioGroupProps> = ({
  name,
  className="",
  options,
  value,
  onChange = () => {},
}) => {
  const id =  useId();
  const groupId = name || id;
  return (
    <div className={`flex flex-row gap-4 ${className}`}>
      {options.map((option) => (
        <div key={groupId + option.value} className="flex items-center">
          <input
            readOnly
            id={groupId + option.value}
            name={groupId}
            checked={option.value === value}
            type="radio"
            className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent cursor-pointer"
            onClick={() => onChange(option.value)}
          />
          <label
            htmlFor={groupId + option.value}
            className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
