import React, { FC } from "react";

import Image from "shared/LazyImage/LazyImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import rightImgSrc from "images/homepage-join-us.jpg";

export interface SectionBecomeMemberProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeMember: FC<SectionBecomeMemberProps> = ({
  className = "",
  rightImg = rightImgSrc,
}) => {
  return (
    <div
      className={`app-SectionBecomeMember relative flex flex-col lg:flex-row items-center  ${className}`}
      data-app-id="SectionBecomeMember"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          Intrested in joining us?
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          Access 1000+ resorts across India and the world, where a universe of experiences opens up for you. There's so much waiting for you.
        </span>
        <ButtonPrimary href="/sign-up" className="mt-6 sm:mt-11">
          Sign up
        </ButtonPrimary>
      </div>
      <div className="flex-grow">
        <Image src={rightImg} />
      </div>
    </div>
  );
};

export default SectionBecomeMember;
