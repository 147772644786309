import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import rightImg from "images/contact-us.jpg";
import environment from "config/environment";

export interface ContactUsProps {
  className?: string;
}

const info = [
  {
    title: "🗺 REGISTERED ADDRESS",
    desc: [
      "Diskounto Holidays Private Limited, Office No 415, Raj Corner, Near L.P. Savani Circle, Adajan, Surat, Gujarat, 395009",
    ],
  },
  {
    title: "🗺 BRANCH OFFICES",
    desc: [
      "<b>Goa: </b>Behind Gracia Garden, Altinho, Panaji, Goa, 403001",
      "<b>Patna: </b>OP Complex, Opp Loyola School, Kurji, Patna, Bihar, 800013",
    ],
  },
  {
    title: "💌 EMAIL",
    desc: ["holidays@diskounto.com"],
  },
  {
    title: "☎ PHONE",
    desc: ["91-635-366-5744"],
  },
];

const ContactUs: FC<ContactUsProps> = ({ className = "" }) => {
  return (
    <div
      className={`app-ContactUs overflow-hidden ${className}`}
      data-app-id="ContactUs"
    >
      <Helmet>
        <title>{environment.appName} | Contact Us</title>
      </Helmet>
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc?.length ? (<ol>{item.desc.map((html, i) => <li key={i} dangerouslySetInnerHTML={{ __html: html }} />)}</ol>) : <span dangerouslySetInnerHTML={{ __html: item.desc[0] }}/>}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div className="flex-grow">
              <img className="w-full" src={rightImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
