import React from "react";
import { HotelWithId } from "domain/hotel.type";
import environment from "config/environment";

type HotelLocationProps = {
  hotel: HotelWithId;
};

const HotelLocation = ({ hotel }: HotelLocationProps) => {
  const lat = Number(hotel.address.coordinates[0]);
  const lng = Number(hotel.address.coordinates[1]);
  const placeId = hotel.address.googlePlaceId;

  const q = placeId ? `place_id:${placeId}` : `${lat},${lng}`;
  return (
    <div className="w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8">
      {/* HEADING */}
      <div>
        <h2 className="text-2xl font-semibold">Location</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {[
            hotel.address.street,
            hotel.place?.name,
            hotel.place?.country.name,
            hotel.address.zip,
          ]
            .filter(Boolean)
            .join(", ")}
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

      {/* MAP */}
      <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
        <div className="rounded-xl overflow-hidden">
          {!isNaN(lat) && !isNaN(lng) && (
            <iframe
              title="hotel-map"
              width="100%"
              height="100%"
              src={`https://www.google.com/maps/embed/v1/place?key=${environment.googleMapEmbedApiKey}&q=${q}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HotelLocation;
