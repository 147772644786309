import { useAuth } from "context/AuthProvider";
import React, { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";

type ProtectedProps = {
  children: ReactElement;
};

const Protected: React.FC<ProtectedProps> = ({ children }) => {
  const { pathname, search } = useLocation();

  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: `/sign-in`,
          search: `?redirect=${pathname}${search || ""}`,
        }}
        replace={true}
      />
    );
  }
  return children;
};

export default Protected;
