import React from "react";
import { HotelWithId } from "domain/hotel.type";
import HotelRoomCard from "components/HotelRoomCard";

type HotelRoomsProps = {
  hotel: HotelWithId;
};

const HotelRooms = ({ hotel }: HotelRoomsProps) => {
  if(!hotel.rooms?.length) {
    return null
  }
  return (
    <div className="w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8">
      <h2 className="text-2xl font-semibold">Rooms</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {hotel.rooms?.map((room) => (
        <HotelRoomCard key={room.id} room={room} />
      ))}
    </div>
  );
};

export default HotelRooms;
