import { HomeIcon } from "@heroicons/react/24/solid";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";

import SiderLayout, { MenuListConfig } from "components/SiderLayout/SiderLayout";
import useFetch from "hooks/useFetch";
import { SupplierWithId } from "domain/supplier.type";
import environment from "config/environment";
import Loader from "shared/Loader/Loader";
import SupplierInformation from "./Information/SupplierInformation";
import SupplierServices from "./Services";

const ManageSupplier = () => {
  const { supplierId } = useParams();
  const {
    isLoading,
    data: suppliers,
    setData,
  } = useFetch<SupplierWithId[]>(
    { url: `${environment.apiUrl}/supplier/${supplierId}` },
    1
  );

  if (isLoading) {
    return <Loader />;
  }
  if (!suppliers?.length) {
    return null;
  }

  const supplier = suppliers[0];
  const menu: MenuListConfig = [
    {
      key: "supplier/information",
      path: "",
      text: "Information",
      element: (
        <SupplierInformation
          supplier={supplier}
          onUpdateSupplier={(updatedSupplier) =>
            setData([{ ...supplier, ...updatedSupplier }])
          }
        />
      ),
      icon: <HomeIcon />,
      auth: true,
    },
    {
      key: "supplier/services",
      path: "service",
      text: "Services",
      element: (
        <SupplierServices supplier={supplier}/>
      ),
      icon: <GlobeAltIcon />,
      auth: true,
    },
  ];

  return <SiderLayout menu={menu} showLogo={true} />;
};

export default ManageSupplier;
