import React, { FC } from "react";
// import { MapPinIcon } from "@heroicons/react/24/solid";
// import GoogleMapReact from "google-map-react";
import { useForm } from "react-hook-form";
import { pick } from "lodash";

import { HotelWithId } from "domain/hotel.type";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
// import LocationMarker from "shared/LocationMarker/LocationMarker";
import Label from "shared/Label/Label";
// import Flex from "shared/Flex/Flex";
import CommonLayout from "./CommonLayout";
import HotelPlace from "../Inputs/HotelPlace";
import HotelZipCode from "../Inputs/HotelZipCode";
import HotelStreetAddress from "../Inputs/HotelStreetAddress";
import HotelCoordinates from "../Inputs/HotelCoordinates";
import HotelGooglePlaceId from "../Inputs/HotelGooglePlaceId";

export interface HotelLocationFormProps {
  hotel?: HotelWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<HotelWithId>) => void;
}

const HotelLocationForm: FC<HotelLocationFormProps> = ({
  hotel,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<HotelWithId>({
    defaultValues: pick<HotelWithId>(hotel, ["placeId", "address"]),
  });

  const place = watch("place");
  const street = watch("address.street");
  const zip = watch("address.zip");

  const onSubmit = (data: HotelWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Your hotel location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-2 gap-6">
          <HotelPlace
            register={register}
            watch={watch}
            error={errors.placeId}
          />

          <HotelZipCode register={register} error={errors.address?.zip} />

          <HotelStreetAddress
            register={register}
            error={errors.address?.street}
            className="col-span-2"
          />

          <div className="col-span-2">
            <Label>Detailed address</Label>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
              {[street, place?.name, place?.country.name, zip]
                .filter(Boolean)
                .join(", ")}
            </span>
          </div>

          <HotelGooglePlaceId
            register={register}
            error={errors.address?.googlePlaceId}
          />

          <HotelCoordinates
            register={register}
            error={
              errors.address?.coordinates?.[0] ||
              errors.address?.coordinates?.[1]
            }
          />

          {/* <Flex
            direction="row"
            align="end"
            justify="end"
            className="col-span-12 align-bottom"
          >
            <ButtonSecondary>
              <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
              <span className="ml-3">Use current location</span>
            </ButtonSecondary>
          </Flex>

          <div className="col-span-12">
            <div className="mt-1">
              <div className="aspect-w-5 aspect-h-2">
                <div className="rounded-xl overflow-hidden">
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: environment.googleMapEmbedApiKey as string,
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={15}
                    defaultCenter={{
                      lat: hotel?.address.coordinates[0] || 55.9607277,
                      lng: hotel?.address.coordinates[1] ||  36.2172614,
                    }}
                  >
                    <LocationMarker />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </>
    </CommonLayout>
  );
};

export default HotelLocationForm;
