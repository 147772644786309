import React, { FC } from "react";

import Image from "shared/LazyImage/LazyImage";
import SectionHeading from "components/SectionHeading";

import HIW1img from "images/how-it-works/destination.svg";
import HIW2img from "images/how-it-works/flight.svg";
import HIW3img from "images/how-it-works/camera.svg";

import HorizontalCurveLine from "images/how-it-works/path-horizontal.svg";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    sizeClass: string;
  }[];
}

const DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "1. Plan",
    desc: "Choose your dream destination and customize your perfect holiday package",
    sizeClass: "w-52 md:w-32 lg:w-52",
  },
  {
    id: 2,
    img: HIW2img,
    title: "2. Travel",
    desc: "Embark on a seamless journey with our hassle-free travel arrangements",
    sizeClass: "w-48 md:w-28 lg:w-48",
  },
  {
    id: 3,
    img: HIW3img,
    title: "3. Enjoy",
    desc: "Relax and savor unforgettable experiences at your chosen destination",
    sizeClass: "w-56 md:w-36 lg:w-56",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DATA,
}) => {
  return (
    <div
      className={`app-SectionHowItWork  ${className}`}
      data-app-id="SectionHowItWork"
    >
      <SectionHeading
        isCenter
        desc="Seamless travel planning for your dream getaway"
      >
        How it works
      </SectionHeading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-12 md:gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10 px-24"
          src={HorizontalCurveLine}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <Image
              containerClassName={`block mb-8 w-40 mx-auto ${item.sizeClass}`}
              src={item.img}
            />
            <div className="text-center mt-auto">
              <h3 className="text-xl md:text-2xl font-semibold">
                {item.title}
              </h3>
              <span className="block text-base md:text-lg mt-5 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
