import React, { useEffect, useMemo, useState } from "react";
import {
  CalendarDaysIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import moment, { Moment } from "moment";
import { groupBy } from "lodash";

import { HotelWithId } from "domain/hotel.type";
import { HotelRoomRateWithId } from "domain/hotel-room-rate.type";
import { SupplierServiceWithId } from "domain/supplier-service.type";
import { addQueryToUrl } from "utils/url";
import useFetch, { fetcher } from "hooks/useFetch";
import environment from "config/environment";
import Flex from "shared/Flex/Flex";
import List from "shared/List/List";
import AutoComplete from "shared/AutoComplete/AutoComplete";
import DateSelect from "shared/DateSelect/DateSelect";
import HotelHeading from "../HotelHeading";
import AddEditHotelRoomRate from "components/HotelRoomRateForm/AddEditHotelRoomRate";
import HotelRoomRateRow from "components/HotelRoomRateForm/HotelRoomRateRow";
import HotelRoomRateWithMealPlan from "components/HotelRoomRateForm/HotelRoomRateWithMealPlan";

interface HotelRoomRatesProps {
  hotel: HotelWithId;
}

const HotelRoomRates = ({ hotel }: HotelRoomRatesProps) => {
  const noOfDaysToDisplay = 7;
  const rateApiUrl = `${environment.apiUrl}/hotel-room-rate?hotelId=${hotel.id}`;
  const {
    data = [],
    isLoading,
    reFetch,
    upsertData,
  } = useFetch<HotelRoomRateWithId[]>(
    { url: rateApiUrl },
    150, // Suports maximum 21 rooms in a hotel
    true,
    500
  );
  const [firstDate, setFirstDate] = useState<Moment>(moment());
  const [supplierService, setSupplierService] = useState<
    SupplierServiceWithId | undefined
  >();

  const dateRange: Moment[] = useMemo(
    () =>
      Array.from({ length: noOfDaysToDisplay }, (_, index) =>
        firstDate.clone().add(index, "days")
      ),
    [firstDate]
  );

  const isSupplierSelected = !!supplierService?.supplierId;
  const ratesByRooms = useMemo(() => groupBy(data, "roomId"), [data]);

  useEffect(() => {
    if (supplierService) {
      reFetch({
        url: addQueryToUrl(rateApiUrl, {
          supplierId: supplierService.supplierId,
          fromDate: firstDate.format("YYYY-MM-DD"),
          toDate: firstDate.clone().add(7, "days"),
        }),
      });
    }
  }, [supplierService, firstDate.toISOString()]);

  return (
    <Flex className="w-full">
      <HotelHeading
        hotel={hotel}
        actionsComponent={
          <AddEditHotelRoomRate
            buttonType="primary"
            showButton={isSupplierSelected}
            supplierService={supplierService}
            rooms={hotel.rooms || []}
            onChangeHotelRoomRate={() => reFetch({ reset: true })}
          />
        }
      />
      <Flex direction="row" align="center" className="w-full px-5 pt-5">
        <HotelRoomRateRow
          left={
            <Flex
              direction="row"
              align="center"
              justify="between"
              className="w-full"
            >
              <Flex direction="row" align="center" className="flex-1">
                <AutoComplete<SupplierServiceWithId>
                  className="w-full rounded-none text-sm"
                  placeholder="Select Supplier"
                  loadOptions={(query) => {
                    return fetcher({
                      url: addQueryToUrl(
                        `${environment.apiUrl}/supplier-service`,
                        {
                          skip: 0,
                          limit: 100,
                          serviceId: hotel.id,
                          searchText: query,
                        }
                      ),
                    });
                  }}
                  isClearable={false}
                  defaultOptions={true}
                  getOptionLabel={(option) => option.supplier?.name || ""}
                  getOptionValue={(option) => option.supplierId}
                  onChange={(serviceSupplier) => {
                    setSupplierService(serviceSupplier ?? undefined);
                  }}
                />
              </Flex>
              <ChevronLeftIcon
                className="mx-1 cursor-pointer text-primary-500 hover:text-primary-800"
                width={30}
                onClick={() =>
                  setFirstDate((activeDate) =>
                    activeDate.clone().subtract(7, "day")
                  )
                }
              />
            </Flex>
          }
          right={
            <>
              <ChevronRightIcon
                className="mx-1 cursor-pointer text-primary-500 hover:text-primary-800"
                width={30}
                onClick={() =>
                  setFirstDate((activeDate) => activeDate.clone().add(7, "day"))
                }
              />
              <DateSelect
                onDateChange={(date) => {
                  if (date) setFirstDate(date);
                }}
              >
                <CalendarDaysIcon width={30} className="mr-1 cursor-pointer" />
              </DateSelect>
            </>
          }
        >
          {dateRange.map((date) => {
            return (
              <Flex
                key={date.toISOString()}
                direction="col"
                align="center"
                justify="center"
                className="w-full p-2 cursor-default text-center text-xs"
              >
                <div className="font-semibold">{date.format("ddd")}</div>
                <div className="text-neutral-500">
                  {date.format("DD MMM YYYY")}
                </div>
              </Flex>
            );
          })}
        </HotelRoomRateRow>
      </Flex>
      <List
        items={isSupplierSelected ? hotel.rooms || [] : []}
        rowKey="id"
        className="w-full px-5"
        emptyMessage={
          <div className="text-sm">
            {!isSupplierSelected
              ? "Select supplier to view rates"
              : "No room available"}
          </div>
        }
        renderItem={({ item }) => (
          <div className="my-4">
            <HotelRoomRateWithMealPlan
              isLoading={isLoading}
              supplierService={supplierService}
              room={item}
              dateRange={dateRange}
              rates={ratesByRooms[item.id]}
              onChangeHotelRoomRate={(hotelRoomRate) => {
                if (hotelRoomRate?.id) {
                  upsertData(hotelRoomRate, ["id"]);
                } else {
                  reFetch({ reset: true });
                }
              }}
            />
          </div>
        )}
      />
    </Flex>
  );
};

export default HotelRoomRates;
