import React, { FC, useState } from "react";

import { TourWithId } from "domain/tour.type";
import BasicInformation from "components/TourForm/BasicInformation";
import TourMeta from "components/TourForm/TourMeta";
import PlaceWithOptions from "components/TourForm/PlaceWithOptions";
import DayByDayPlan from "components/TourForm/DayByDayPlan";
import InclusionsAndExclusions from "components/TourForm/InclusionsAndExclusions";
import TourPreview from "components/TourForm/TourPreview";

export interface TourFormProps {
  tour?: TourWithId;
  onSuccess?: (tour: TourWithId) => void;
}

type State = {
  tour: TourWithId;
  step: number;
};

export const initialState: TourWithId = {
  id: "",
  slug: "",
  title: "",
  maxPricePerPax: 0,
  pricePerPax: 0,
  overview: "",
  thumbnail: undefined as never as TourWithId["thumbnail"],
  coverPhotos: {
    mobile: [],
    desktop: []
  },
  marketingPhoto: null as never as TourWithId["marketingPhoto"],
  placesWithOptions: [],
  components: {
    trains: false,
    bus: false,
    flights: false,
    transfers: true,
    hotels: true,
    sightseeing: true,
    meals: true,
  },
  idealFor: {
    couple: true,
    friends: false,
    family: false,
  },
  inclusions: [""],
  exclusions: [""],
  notes: [],
  dayByDay: [{ title: "", plan: "", photos: [] }],
  status: "INACTIVE",
};

const TourForm: FC<TourFormProps> = ({ tour, onSuccess = () => {} }) => {
  let stepComponent;
  const maxStep = 6;
  const [state, setState] = useState<State>({
    tour: tour || initialState,
    step: 1,
  });

  const handlePrev = () => {
    setState({
      ...state,
      step: Math.max(state.step - 1, 1),
    });
  };

  const handleNext = (partialTour: Partial<TourWithId>) => {
    setState({
      ...state,
      step: Math.min(state.step + 1, maxStep),
      tour: { ...state.tour, ...partialTour },
    });
  };

  switch (state.step) {
    case 1:
      stepComponent = (
        <BasicInformation
          step={1}
          tour={state.tour}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 2:
      stepComponent = (
        <TourMeta
          step={2}
          tour={state.tour}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 3:
      stepComponent = (
        <PlaceWithOptions
          step={3}
          tour={state.tour}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 4:
      stepComponent = (
        <DayByDayPlan
          step={4}
          tour={state.tour}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 5:
      stepComponent = (
        <InclusionsAndExclusions
          step={5}
          tour={state.tour}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 6:
      stepComponent = (
        <TourPreview
          step={6}
          tour={state.tour}
          onClickPrev={handlePrev}
          onSuccess={onSuccess}
        />
      );
      break;

    default:
      stepComponent = null;
  }

  return <div>{stepComponent}</div>;
};

export default TourForm;
