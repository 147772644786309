import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Textarea from "shared/Textarea/Textarea";

export interface HotelDescriptionProps {
  register: UseFormRegister<HotelWithId>;
  error?: FieldError;
  className?: string;
}

const HotelDescription: FC<HotelDescriptionProps> = ({ register, error, className }) => {
  return (
    <div className={className}>
      <Label>Hotel description</Label>
      <Textarea
        className="mt-1"
        placeholder="Hotel description"
        {...register("description", { required: true })}
      />
      {error && (
        <span className="text-sm text-red-500">
          Please enter hotel description
        </span>
      )}
    </div>
  );
};

export default HotelDescription;
