import * as React from "react";
import get from "lodash/get";

interface ListProps<T> {
  loading?: boolean;
  className?: string;
  items: T[];
  rowKey?: string;
  emptyMessage?: React.ReactNode;
  renderItem: (renderProps: { item: T; index: number }) => React.ReactNode;
  divider?: boolean;
  bordered?: boolean;
}

interface AbstractItem {
  id?: string;
}

export default function List<T extends AbstractItem>({
  loading = false,
  className = "",
  items = [],
  rowKey = "id",
  emptyMessage = "No data",
  renderItem = () => <></>,
  divider = true,
  bordered = false,
}: ListProps<T>) {
  const dividerClass = divider ? "divide-y divide-neutral-300 rounded-sm" : "";
  const borderClass = bordered ? "border border-neutral-300" : "";

  const isEmpty = !(items || []).length
  return (
    <ul
      className={`grid grid-cols-1 ${dividerClass} ${borderClass}  ${className}`}
    > 
      {(items || []).map((item, index) => {
        return <li key={get(item, rowKey)}>{renderItem({ item, index })}</li>;
      })}
      {!loading && isEmpty && <div className="py-4 text-center">{emptyMessage}</div>}
    </ul>
  );
}
