export const loadState = <T>(key: string): T | undefined => {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) {
      return undefined;
    }
    return JSON.parse(serializedValue) as T;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key: string, value: any) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch {
    // ignore write errors
  }
};

export const removeState = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch {
    // ignore delete errors
  }
};
