import React from "react";
import Heading from "shared/Heading/Heading";

const SectionContent = () => {
  return (
    <div className={`app-SectionContent relative`} data-app-id="SectionContent">
      <Heading desc="">Terms of use</Heading>
      <p className="text-sm text-gray-500 mb-4">
        Effective Date: <span className="font-medium">01 Jan 2024</span>
      </p>

      <p className="mb-6">
        This document is an electronic record under the Information Technology
        Act, 2000, and the applicable rules thereunder, as amended by the
        Information Technology Act, 2000. This electronic record is generated by
        a computer system and does not require any physical or digital
        signatures.
      </p>

      <p className="mb-6">
        This document is published in accordance with the provisions of Rule
        3(1) of the Information Technology (Intermediaries Guidelines) Rules,
        2011, which require the publication of rules and regulations, privacy
        policy, and terms of use for access or usage of the domain{" "}
        <a href="https://holidays.diskounto.com" className="underline">
          holidays.diskounto.com
        </a>{" "}
        (“Website”), including its related mobile site and mobile application
        (hereinafter referred to as the “Platform”).
      </p>

      <p className="mb-6">
        The Platform is owned and operated by Diskounto Holidays Private
        Limited, a company registered at Office No. 415, Raj Corner, Near L.P.
        Savani Circle, Adajan, Surat, Gujarat, 395009 (hereinafter referred to
        as "Diskounto Holidays," "We," "Us," or "Our").
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        1. Acceptance of Terms
      </h2>
      <p className="mb-6">
        By accessing, browsing, or using the Platform, you ("User," "You," or
        "Your") agree to be bound by these Terms of Use, as well as any
        additional terms, policies, or guidelines incorporated by reference
        herein. If You do not agree with these Terms of Use, You should not
        access or use the Platform.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">2. Eligibility</h2>
      <p className="mb-6">
        You must be at least eighteen (18) years of age to access or use the
        Platform. By using the Platform, You represent and warrant that You are
        of legal age to form a binding contract with Diskounto Holidays. If You
        are under the age of 18, You may use the Platform only with the
        involvement and consent of a parent or guardian.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        3. User Account and Registration
      </h2>
      <p className="mb-6">
        To access certain features of the Platform, You may be required to
        create an account. You are responsible for maintaining the
        confidentiality of Your account information, including Your display name
        and password. You agree to accept responsibility for all activities that
        occur under Your account.
      </p>
      <p className="mb-6">
        If any information provided by You is found to be untrue, inaccurate, or
        incomplete, We reserve the right to suspend or terminate Your account
        and refuse any and all current or future use of the Platform.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">4. Communication</h2>
      <p className="mb-6">
        By using the Platform, You consent to receive communications from Us
        electronically. We will communicate with You by email, SMS, or by
        posting notices on the Platform. You agree that all agreements, notices,
        disclosures, and other communications provided to You electronically
        satisfy any legal requirement that such communications be in writing.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">5. Charges and Fees</h2>
      <p className="mb-6">
        Access to the Platform is free of charge for travelers. However,
        Diskounto Holidays reserves the right to introduce fees for new or
        existing services at its discretion. Any changes to our fee structure
        will be posted on the Platform and will become effective immediately
        upon posting.
      </p>
      <p className="mb-6">
        We offer premium services that require payment. These services are
        subject to a validity period, and You may choose to renew Your
        subscription manually or opt for automatic renewal. All fees are quoted
        in Indian Rupees unless otherwise stated, and You are solely responsible
        for complying with applicable laws, including those governing payment
        processing.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        6. Privacy and Data Protection
      </h2>
      <p className="mb-6">
        We respect Your privacy and are committed to protecting Your personal
        information. Our{" "}
        <a href="https://holidays.diskounto.com/privacy" className="underline">
          Privacy Policy
        </a>{" "}
        explains how We collect, use, and disclose information about You. By
        using the Platform, You consent to the collection and use of Your
        information as described in the Privacy Policy.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        7. Modification of Terms
      </h2>
      <p className="mb-6">
        Diskounto Holidays reserves the right to change, modify, add, or remove
        portions of these Terms of Use at any time without prior notice. It is
        Your responsibility to review these Terms of Use periodically for any
        changes. Continued use of the Platform following the posting of changes
        constitutes Your acceptance of those changes.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">8. Governing Law</h2>
      <p className="mb-6">
        These Terms of Use are governed by and construed in accordance with the
        laws of India. Any disputes arising out of or relating to these Terms of
        Use will be subject to the exclusive jurisdiction of the courts in
        Surat, Gujarat, India.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        9. Contact Information
      </h2>
      <p className="mb-6">
        If You have any questions or concerns regarding these Terms of Use,
        please contact Us at holidays@diskounto.com.
      </p>
    </div>
  );
};

export default SectionContent;
