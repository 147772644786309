import React, { FC, useEffect, useRef } from "react";
import ReactSelect from "react-select";
import { fill, get, pick, take } from "lodash";
import { v4 as uuid } from "uuid";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ArrowDownIcon } from "@heroicons/react/24/solid";

import { TourWithId } from "domain/tour.type";
import { HotelWithId } from "domain/hotel.type";
import { PlaceWithId } from "domain/place.type";
import environment from "config/environment";
import useFetch from "hooks/useFetch";
import Flex from "shared/Flex/Flex";
import Select from "shared/Select/Select";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { handlePlural } from "utils/string";
import { getTourNoOfNights } from "utils/tourUtils";
import CommonLayout from "./CommonLayout";

export interface PlaceWithOptionsProps {
  tour?: TourWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<TourWithId>) => void;
}

const PlaceWithOptions: FC<PlaceWithOptionsProps> = ({
  tour,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const placeUrl = `${environment.apiUrl}/place`;
  const { isLoading: isPlacesLoading, data: places = [] } = useFetch<
    PlaceWithId[]
  >({ url: placeUrl }, 1000);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<TourWithId>({
    defaultValues: pick<TourWithId>(tour, [
      "placesWithOptions",
      "components",
      "dayByDay",
    ]),
  });

  const { append: appendPlaceWithOptions, remove: removePlaceWithOptions } =
    useFieldArray({
      control,
      keyName: "id",
      name: "placesWithOptions",
    });

  const placesWithOptions = watch(
    "placesWithOptions",
    tour?.placesWithOptions || []
  );

  const onSubmit = (data: TourWithId) => {
    const noOfNights = getTourNoOfNights(data);
    const placesWithOptions = data.placesWithOptions.map((item) => ({
      ...item,
      place: places.find(p => p.id === item.placeId),
      noOfNights: Number(item.noOfNights),
    }));
    const dayByDay = take(
      [
        ...data.dayByDay,
        ...fill(Array(noOfNights + 1), {
          title: "",
          plan: "",
          photos: [],
        }),
      ],
      noOfNights + 1
    );
    setValue("dayByDay", dayByDay);
    onClickNext({ ...data, placesWithOptions, dayByDay });
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <div className="grid grid-cols-1 gap-3">
          <div>
            <h2 className="text-2xl font-semibold">Places to cover</h2>
          </div>
          {placesWithOptions.map((placeWithOptions, index) => {
            return (
              <div key={placeWithOptions.id}>
                <Flex direction="row" align="center" className="space-x-2">
                  <div className="w-full grid grid-cols-2 gap-6">
                    <div className="col-span-1">
                      <Select
                        className="mt-1"
                        isLoading={isPlacesLoading}
                        {...register(`placesWithOptions.${index}.placeId`, {
                          required: true,
                        })}
                      >
                        <option value="">Select Place</option>
                        {places.map((place) => {
                          return (
                            <option key={place.id} value={place.id}>
                              {[place.name, place.country.name].join(", ")}
                            </option>
                          );
                        })}
                      </Select>
                      {get(errors, `placesWithOptions.${index}.placeId`) && (
                        <span className="text-sm text-red-500">
                          Please select the place
                        </span>
                      )}
                    </div>

                    <div className="col-span-1">
                      <Select
                        className="mt-1"
                        {...register(`placesWithOptions.${index}.noOfNights`, {
                          required: true,
                        })}
                      >
                        <option value="">Select No of nights</option>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((night) => {
                          return (
                            <option key={night} value={night}>
                              {handlePlural(night, "night", "s")}
                            </option>
                          );
                        })}
                      </Select>
                      {get(errors, `placesWithOptions.${index}.noOfNights`) && (
                        <span className="text-sm text-red-500">
                          Please select the place
                        </span>
                      )}
                    </div>

                    <div className="col-span-2">
                      <Controller
                        name={`placesWithOptions.${index}.hotelIds`}
                        control={control}
                        rules={{
                          required: false,
                        }}
                        render={({ field: { value, onChange } }) => (
                          <HotelSelect
                            placeId={placeWithOptions.placeId}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-span-2">
                      {placesWithOptions.length - 1 !== index && (
                        <Flex align="center" className="mt-2">
                          <ArrowDownIcon width={20} />
                        </Flex>
                      )}
                    </div>
                  </div>

                  <TrashIcon
                    className="ml-2 w-5 h-5 hover:text-red-800 cursor-pointer"
                    onClick={() => {
                      removePlaceWithOptions(index);
                    }}
                  />
                </Flex>
              </div>
            );
          })}
          <ButtonSecondary
            className="mb-6 font-thin text-sm border-dotted border-neutral-400 hover:border-primary-400 rounded-2xl dark:border-neutral-700"
            onClick={() =>
              appendPlaceWithOptions({
                id: uuid() as unknown as string,
                placeId: undefined as unknown as string,
                noOfNights: undefined as unknown as number,
                hotelIds: [],
              })
            }
          >
            + Add more place
          </ButtonSecondary>
        </div>
      </>
    </CommonLayout>
  );
};

interface HotelSelectProps {
  placeId?: string;
  value: string[];
  onChange: (selected: string[]) => void;
}

const HotelSelect = ({
  placeId,
  value: hotelIds,
  onChange,
}: HotelSelectProps) => {
  const lastPlaceId = useRef(placeId);
  const {
    isLoading,
    data = [],
    reFetch,
  } = useFetch<HotelWithId[]>(
    { url: `${environment.apiUrl}/hotel?placeId=${placeId}` },
    1000,
    true
  );

  useEffect(() => {
    if (placeId) {
      reFetch({
        url: `${environment.apiUrl}/hotel?placeId=${placeId}`,
        reset: true,
      });
      if (lastPlaceId.current !== placeId) {
        lastPlaceId.current = placeId;
        onChange([]);
      }
    }
  }, [placeId]);

  const options = data.map((hotel) => ({
    value: hotel.id,
    label: hotel.name,
  }));

  return (
    <ReactSelect
      isMulti={true}
      isLoading={isLoading}
      isSearchable={false}
      closeMenuOnSelect={false}
      options={options}
      value={options.filter((option) => hotelIds.includes(option.value))}
      placeholder="Select hotel options"
      onChange={(selected: { value: string, label: string }[]) => onChange(selected.map((item) => item.value))}
      noOptionsMessage={() =>
        !placeId
          ? "Select a place to see hotel options"
          : hotelIds.length
          ? "No more hotels"
          : "No hotel here"
      }
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 16,
        colors: {
          ...theme.colors,
          primary25: "rgb(var(--c-primary-100))",
          primary50: "rgb(var(--c-primary-300))",
          primary75: "rgb(var(--c-primary-400))",
          primary: "rgb(var(--c-primary-500))",
        },
        spacing: {
          ...theme.spacing,
          controlHeight: 44,
        },
      })}
    />
  );
};

export default PlaceWithOptions;
