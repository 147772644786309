import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { SupplierWithId } from "domain/supplier.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface SupplierPanProps {
  register: UseFormRegister<SupplierWithId>;
  error?: FieldError;
  className?: string;
}

const SupplierPan: FC<SupplierPanProps> = ({ register, error, className }) => {
  return (
    <div className={className}>
      <Label>PAN</Label>
      <Input
        className="mt-1"
        placeholder="ABCTY1234D"
        {...register("pan", { required: false })}
      />
      {error && (
        <span className="text-sm text-red-500">Please enter pan number</span>
      )}
    </div>
  );
};

export default SupplierPan;
