import React, { FC } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import environment from "config/environment";
import useFetch from "hooks/useFetch";
import Loader from "shared/Loader/Loader";
import { HotelWithId } from "domain/hotel.type";
import HotelPhotos from "./HotelPhotos";
import HotelAmenities from "./HotelAmenities";
import HotelHeading from "./HotelHeading";
import HotelDescription from "./HotelDescription";
import HotelLocation from "./HotelLocation";
// import HotelBookingWidget from "./HotelBookingWidget";
import HotelThingsToKnow from "./HotelThingsToKnow";
import HotelRooms from "./HotelRooms";

export interface HotelDetailsProps {
  className?: string;
  isPreviewMode?: boolean;
}

const HotelDetails: FC<HotelDetailsProps> = ({ className = "" }) => {
  const { slug } = useParams();
  const [search] = useSearchParams();
  const hotelId = search.get("id");

  const { isLoading, data: hotels } = useFetch<HotelWithId[]>({
    url: hotelId
      ? `${environment.apiUrl}/hotel/${hotelId}`
      : `${environment.apiUrl}/hotel?slug=${slug}`,
  });

  if (isLoading) {
    return <Loader />;
  }
  if (!hotels?.length) {
    return null;
  }

  const hotel = hotels[0];
  return (
    <div
      className={`ListingDetailPage app-HotelDetails ${className}`}
      data-app-id="HotelDetails"
    >
      {/* SINGLE HEADER */}
      <HotelPhotos hotel={hotel} />

      {/* MAIN */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          <HotelHeading hotel={hotel} />
          <HotelDescription hotel={hotel} />
          <HotelRooms hotel={hotel} />
          <HotelAmenities hotel={hotel} />
          <HotelLocation hotel={hotel} />
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">
            <HotelThingsToKnow hotel={hotel} />

            {/* <HotelBookingWidget hotel={hotel} /> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default HotelDetails;
