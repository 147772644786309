import { HomeIcon, Squares2X2Icon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";

import SiderLayout, {
  MenuListConfig,
} from "components/SiderLayout/SiderLayout";
import useFetch from "hooks/useFetch";
import { HotelWithId } from "domain/hotel.type";
import environment from "config/environment";
import Loader from "shared/Loader/Loader";
import HotelInformation from "./Information/HotelInformation";
import HotelRooms from "./Rooms/HotelRooms";
import SupplierServices from "./Suppliers/SupplierServices";
import { CurrencyDollarIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import HotelRoomRates from "./RatesAndInventory/HotelRoomRates";

const ManageHotel = () => {
  const { hotelId } = useParams();
  const {
    isLoading,
    data: hotels,
    setData,
  } = useFetch<HotelWithId[]>(
    { url: `${environment.apiUrl}/hotel/${hotelId}` },
    1
  );

  if (isLoading) {
    return <Loader />;
  }
  if (!hotels?.length) {
    return null;
  }

  const hotel = hotels[0];
  const menu: MenuListConfig = [
    {
      key: "hotel/information",
      path: "",
      text: "Information",
      element: (
        <HotelInformation
          hotel={hotel}
          onUpdateHotel={(updatedHotel) =>
            setData([{ ...hotel, ...updatedHotel }])
          }
        />
      ),
      icon: <HomeIcon />,
      auth: true,
    },
    {
      key: "hotel/rooms",
      path: "rooms",
      text: "Rooms",
      element: (
        <HotelRooms
          hotel={hotel}
          onUpdateHotel={(updatedHotel) =>
            setData([{ ...hotel, ...updatedHotel }])
          }
        />
      ),
      icon: <Squares2X2Icon />,
      auth: true,
    },
    {
      key: "hotel/rates-and-inventory",
      path: "rates-and-inventory",
      text: "Rates & Inventory",
      element: <HotelRoomRates hotel={hotel} />,
      icon: <CurrencyDollarIcon />,
      auth: true,
    },
    {
      key: "hotel/suppliers",
      path: "suppliers",
      text: "Suppliers",
      element: <SupplierServices service={hotel} />,
      icon: <GlobeAltIcon />,
      auth: true,
    },
  ];

  return <SiderLayout menu={menu} showLogo={true} />;
};

export default ManageHotel;
