import React from "react";

import useWindowSize from "hooks/useWindowResize";
import SocialsList from "shared/SocialsList/SocialsList";
import PaymentMethods from "shared/PaymentMethods/PaymentMethods";

import FooterNav from "components/FooterNav";
import { NavLink } from "react-router-dom";
import environment from "config/environment";
import Logo from "shared/Logo/Logo";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: {
    label: string;
    href: string;
    targetBlank?: boolean;
  }[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Quick Links",
    menus: [
      { href: "/", label: "Home" },
      { href: "/about-us", label: "About us" },
      { href: "/places", label: "Destinations" },
    ],
  },
  {
    id: "1",
    title: "Support",
    menus: [
      { href: "/bank-details", label: "Bank details" },
      { href: "/contact-us", label: "Contact us" },
      { href: "/pay-us", label: "Pay us" },
    ],
  },
  {
    id: "2",
    title: "Legal",
    menus: [
      { href: "/terms-of-use", label: "Terms of use" },
      { href: "/refund-policy", label: "Cancellation and Refund" },
      { href: "/privacy-policy", label: "Privacy Policy" },
    ],
  },
];

const FooterWidget: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-2 md:mt-5 flex flex-row flex-wrap md:flex-col md:space-y-5">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <NavLink
                key={index}
                className="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white mr-4 md:mr-0"
                to={item.href}
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="app-Footer relative pt-24 lg:pt-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-y-10 gap-x-5 sm:gap-x-8 lg:gap-x-10 pb-24 lg:pb-28 ">
        <div className="grid grid-cols-4 gap-5 col-span-1 sm:col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList className="flex flex-row items-center space-x-3" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
        <div className="text-sm">
          <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
            SECURITY & PAYMENT
          </h2>
          <p className="mt-5">We are secured by 128 bit SSL making your booking secure</p>
          <p className="mt-5">We accept</p>
          <ul className="space-y-4">
            <PaymentMethods />
          </ul>
        </div>
      </div>
      <div className=" bg-neutral-100 text-sm text-center py-3 dark:bg-neutral-800 dark:text-gray-500">
      © {new Date().getFullYear()} {environment.appFullName}. All rights reserved
      </div>
    </div>
  );
};

const Footer = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  return (
    <>
      <div className="hidden">

      {WIN_WIDTH < 768 && <FooterNav  />}
      </div>
      <FooterWidget />
    </>
  );
};

export default Footer;
