import React, {
  FC,
  ImgHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import checkInViewIntersectionObserver from "utils/isInViewPortIntersectionObserver";
import PlaceIcon from "./PlaceIcon";

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  containerClassName?: string;
  meta?: { width: number; height: number };
}

const LazyImage: FC<ImageProps> = ({
  alt = "",
  src = "",
  className = "",
  containerClassName = "",
  meta,
  ...args
}) => {
  const _containerRef = useRef<HTMLDivElement | null>(null);
  const [_src, setSrc] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    setImageLoaded(true);
    setSrc(src);
  };

  const imageOnViewPort = () => {
    if (!src) {
      handleImageLoaded();
      return true;
    }
    const imageEl = new Image();
    imageEl.src = src;
    imageEl.addEventListener("load", handleImageLoaded);
    return true;
  };

  const checkInViewPort = () => {
    if (!_containerRef.current) return;
    checkInViewIntersectionObserver({
      target: _containerRef.current,
      options: {
        root: null,
        rootMargin: "0%",
        threshold: 0,
      },
      freezeOnceVisible: true,
      callback: imageOnViewPort,
    });
  };

  useEffect(() => {
    checkInViewPort();
  }, [src]);

  return (
    <div
      className={`relative w-full h-full overflow-hidden ${containerClassName}`}
      data-app-id="Image"
      ref={_containerRef}
    >
      {_src && imageLoaded ? (
        <img
          src={_src}
          className={`object-cover ${className}`}
          alt={alt}
          {...args}
        />
      ) : (
        <div
          className={`max-w-full w-full h-full flex justify-center items-center bg-neutral-200 dark:bg-neutral-600 ${className}`}
          style={
            meta ? { aspectRatio: `${(meta.height / meta.width)}` } : {}
          }
        >
          <div
            className="flex justify-center items-center text-neutral-100 dark:text-neutral-500"
            style={{
              height: "50%",
              maxWidth: "50%",
              maxHeight: "50%",
              aspectRatio: "1",
            }}
          >
            <PlaceIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default LazyImage;
