import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "shared/ScrollToTop/ScrollToTop";
import AuthProvider from "context/AuthProvider";
import Layout from "pages/Layout";

import Protected from "./Protected";

import Home from "pages/Home";
import NotFound from "pages/NotFound";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import ForgotPassword from "pages/ForgotPassword";
import UpdatePassword from "pages/UpdatePassword";
import AboutUs from "pages/AboutUs";
import BankDetails from "pages/BankDetails";
import TermsOfUse from "pages/TermsOfUse";
import RefundPolicy from "pages/RefundPolicy";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ContactUs from "pages/ContactUs";
import PayUs from "pages/PayUs";
import PaymentStatus from "pages/PaymentStatus";
import PlaceHotels from "pages/PlaceHotels";
import HotelDetails from "pages/HotelDetails";
import BookingRequestSuccess from "pages/BookingRequestSuccess/BookingRequestSuccess";
import Account from "pages/Account/Account";
import ChangePassword from "pages/Account/ChangePassword";
import Membership from "pages/Account/Membership";
import TourListing, { TourListingForPlace } from "pages/TourListing";
import TourItinerary from "pages/TourItinerary";
import Destinations from "pages/Destinations";
import AdminPanel from "pages/AdminPanel";
import ManageHotel from "pages/ManageHotel";
import ManageSupplier from "pages/ManageSupplier";
import SupplierPanel from "pages/SupplierPanel";

const routes = [
  { path: "/", element: <Home />, protected: false },

  /** Auth Routes */
  { path: "/sign-in", element: <SignIn />, protected: false },
  { path: "/sign-up", element: <SignUp />, protected: false },
  { path: "/forgot-password", element: <ForgotPassword />, protected: false },
  { path: "/reset-password", element: <UpdatePassword />, protected: false },

  /** Public Routes */
  { path: "/about-us", element: <AboutUs />, protected: false },
  { path: "/terms-of-use", element: <TermsOfUse />, protected: false },
  { path: "/refund-policy", element: <RefundPolicy />, protected: false },
  { path: "/privacy-policy", element: <PrivacyPolicy />, protected: false },
  { path: "/contact-us", element: <ContactUs />, protected: false },
  { path: "/bank-details", element: <BankDetails />, protected: false },
  { path: "/pay-us", element: <PayUs />, protected: false },
  { path: "/payment-status", element: <PaymentStatus />, protected: false },
  { path: "/places", element: <Destinations />, protected: false },
  { path: "/place/:slug/hotels", element: <PlaceHotels />, protected: false },
  {
    path: "/place/:slug/holiday-packages",
    element: <TourListingForPlace />,
    protected: false,
  },
  { path: "/hotel/:slug", element: <HotelDetails />, protected: false },
  {
    path: "/holiday-packages/:slug",
    element: <TourListing />,
    protected: false,
  },
  {
    path: "/holiday-package/:slug",
    element: <TourItinerary />,
    protected: false,
  },

  /** Guest logged in routes */
  {
    path: "/booking-request-success/:bookingRequestId",
    element: <BookingRequestSuccess />,
    protected: true,
  },
  { path: "/account", element: <Account />, protected: true },
  { path: "/change-password", element: <ChangePassword />, protected: true },
  { path: "/membership", element: <Membership />, protected: true },

  /** Admin panel routes */
  { path: "/admin-panel/*", element: <AdminPanel />, protected: true },
  {
    path: "/manage/hotel/:hotelId/*",
    element: <ManageHotel />,
    protected: true,
  },
  {
    path: "/manage/supplier/:supplierId/*",
    element: <ManageSupplier />,
    protected: true,
  },

  /** Supplier panel routes */
  {
    path: "/supplier-panel/:supplierId/*",
    element: <SupplierPanel />,
    protected: true,
  },

  /** Not found route */
  { path: "*", element: <NotFound />, protected: false },
];

const Router = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTop />
        <Toaster containerClassName="text-sm font-light" />
        <Layout>
          <Routes>
            {routes.map(({ path, element, protected: isProtected }) => (
              <Route
                key={path}
                path={path}
                element={
                  isProtected ? <Protected>{element}</Protected> : element
                }
              />
            ))}
          </Routes>
        </Layout>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
