import { FC } from "react";

import { HotelWithId } from "domain/hotel.type";
import HotelCard from "components/HotelCard";

export interface HotelsGridProps {
  className?: string;
  hotels?: HotelWithId[];
}

const HotelsGrid: FC<HotelsGridProps> = ({ className = "", hotels = [] }) => {
  return (
    <div className={`app-HotelsGrid ${className}`} data-app-id="HotelsGrid">
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {hotels.map((hotel) => (
          <HotelCard key={hotel.id} hotel={hotel} />
        ))}
      </div>
    </div>
  );
};

export default HotelsGrid;
