import React, { useEffect, useState } from "react";

export type Day = "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";

export interface DaySelectProps {
  defaultValue?: Day[];
  value: Day[];
  onChange: (value: Day[]) => void;
}

const DaySelect: React.FC<DaySelectProps> = ({
  defaultValue = [],
  value,
  onChange,
}) => {
  const [selectedDays, setSelectedDays] = useState(defaultValue || []);

  useEffect(() => {
    setSelectedDays(value);
  }, [value]);

  const handleDayClick = (day: Day) => {
    const newSelectedDays = [...selectedDays];
    const index = newSelectedDays.indexOf(day);
    if (index > -1) {
      newSelectedDays.splice(index, 1);
    } else {
      newSelectedDays.push(day);
    }
    setSelectedDays(newSelectedDays);
    onChange(newSelectedDays);
  };

  return (
    <div className="flex space-x-2 mt-1">
      {(["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"] as Day[]).map(
        (day) => (
          <button
            type="button"
            key={day}
            onClick={() => handleDayClick(day)}
            className={`px-4 py-2 text-sm rounded-full transition duration-200 
            ${
              selectedDays.includes(day)
                ? "bg-primary-600 text-white"
                : "bg-gray-200 text-black"
            }
            hover:bg-primary-500`}
          >
            {day}
          </button>
        )
      )}
    </div>
  );
};

export default DaySelect;
