import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface HotelNameProps {
  register: UseFormRegister<HotelWithId>;
  error?: FieldError;
  className?: string;
}

const HotelName: FC<HotelNameProps> = ({ register, error, className }) => {
  return (
    <div className={className}>
      <Label>Hotel name</Label>
      <Input
        className="mt-1"
        placeholder="Hotel name"
        {...register("name", { required: true })}
      />
      {error && (
        <span className="text-sm text-red-500">Please enter hotel name</span>
      )}
    </div>
  );
};

export default HotelName;
