import React, { FC } from "react";

export interface PaymentMethodType{
  name: string,
  icon: string
}

export interface PaymentMethodsProps {
  className?: string;
  itemClass?: string;
  methods?: PaymentMethodType[];
}

const socialsDemo: PaymentMethodType[] = [
  { name: "Visa", icon: "lab la-cc-visa" },
  { name: "Mastercard", icon: "lab la-cc-mastercard" }
];

const PaymentMethods: FC<PaymentMethodsProps> = ({
  className = "",
  itemClass = "block",
  methods = socialsDemo,
}) => {
  return (
    <nav
      className={`app-PaymentMethods flex space-x-2.5 text-2xl text-neutral-600 dark:text-neutral-300 ${className}`}
      data-app-id="PaymentMethods"
    >
      {methods.map((item, i) => (
        <span
          key={i}
          className={`${itemClass}`}
          title={item.name}
        >
          <i className={item.icon}></i>
        </span>
      ))}
    </nav>
  );
};

export default PaymentMethods;
