import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { get, pick } from "lodash";
import { TourWithId } from "domain/tour.type";
import Input from "shared/Input/Input";
import Label from "shared/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import DragUploader from "components/DragUploader/DragUploader";

export interface BasicInformationProps {
  tour?: TourWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<TourWithId>) => void;
}

const BasicInformation: FC<BasicInformationProps> = ({
  tour,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TourWithId>({
    defaultValues: pick<TourWithId>(tour, [
      "title",
      "maxPricePerPax",
      "pricePerPax",
      "overview",
      "thumbnail",
      "coverPhotos",
      "marketingPhoto",
    ]),
  });

  const onSubmit = (data: TourWithId) => {
    const maxPricePerPax = Number(data.maxPricePerPax);
    const pricePerPax = Number(data.pricePerPax);
    onClickNext({ ...data, maxPricePerPax, pricePerPax });
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Package Basic Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <Label>Tour Package Title</Label>
            <Input
              className="mt-1"
              placeholder="Tour package title"
              {...register("title", { required: true })}
            />
            {errors.title && (
              <span className="text-sm text-red-500">
                Please enter tour paackage title
              </span>
            )}
          </div>

          <div className="col-span-6">
            <Label>Max Price/Pax</Label>
            <Input
              type="number"
              className="mt-1"
              placeholder="Max Price per Pax"
              {...register("maxPricePerPax", { valueAsNumber: true, required: true, min: 1 })}
            />
            {errors.maxPricePerPax && (
              <span className="text-sm text-red-500">
                Please enter price per pax
              </span>
            )}
          </div>

          <div className="col-span-6">
            <Label>Price/Pax</Label>
            <Input
              type="number"
              className="mt-1"
              placeholder="Price per Pax"
              {...register("pricePerPax", { valueAsNumber: true, required: true, min: 1 })}
            />
            {errors.pricePerPax && (
              <span className="text-sm text-red-500">
                Please enter price per pax
              </span>
            )}
          </div>

          <div className="col-span-12">
            <Label>Tour description</Label>
            <Textarea
              className="mt-1"
              placeholder="Tour overview"
              {...register("overview", { required: true })}
            />
            {errors.overview && (
              <span className="text-sm text-red-500">
                Please enter tour overview
              </span>
            )}
          </div>

          <div className="col-span-12">
            <Label>Thumbnail</Label>
            <p className="text-xs text-neutral-500">
              Upload a thumbnail which will be displayed as card image. (.webp,
              .jpg, .jpeg, .png & .gif). Prefer aspect ratio of 4:3(W:H).
            </p>
            <Controller
              name="thumbnail"
              control={control}
              rules={{
                validate: (file) => {
                  return file?.status === "done";
                },
              }}
              render={({ field: { onChange } }) => (
                <DragUploader
                  mode="tour"
                  className="mt-1"
                  maxFiles={1}
                  defaultValue={tour?.thumbnail ? [tour?.thumbnail] : []}
                  onChange={(files) => {
                    onChange(get(files, "0"));
                  }}
                />
              )}
            />
            {errors.thumbnail && (
              <span className="text-sm text-red-500">
                Please upload a thumbnail
              </span>
            )}
          </div>

          <div className="col-span-12">
            <Label>Cover Photo - Desktop</Label>
            <p className="text-xs text-neutral-500">
              Upload photo which will be displayed as cover image on desktop.
              (.webp, .jpg, .jpeg, .png & .gif). Prefer aspect ratio of
              2:1(W:H).
            </p>
            <Controller
              name="coverPhotos.desktop"
              control={control}
              rules={{
                validate: (files) => {
                  return (
                    Array.isArray(files) &&
                    files.length > 0 &&
                    files.map((file) => file?.status === "done").filter(Boolean)
                      .length === files.length
                  );
                },
              }}
              render={({ field: { onChange } }) => (
                <DragUploader
                  mode="tour"
                  className="mt-1"
                  maxFiles={1}
                  defaultValue={
                    Array.isArray(tour?.coverPhotos.desktop)
                      ? tour?.coverPhotos.desktop
                      : []
                  }
                  onChange={onChange}
                />
              )}
            />
            {errors.coverPhotos?.desktop && (
              <span className="text-sm text-red-500">
                Please upload cover photo for desktop.
              </span>
            )}
          </div>

          <div className="col-span-12">
            <Label>Cover Photo - Mobile</Label>
            <p className="text-xs text-neutral-500">
              Upload photo which will be displayed as cover image. (.webp, .jpg,
              .jpeg, .png & .gif). Prefer aspect ratio of 3:4(W:H).
            </p>
            <Controller
              name="coverPhotos.mobile"
              control={control}
              rules={{
                validate: (files) => {
                  return (
                    Array.isArray(files) &&
                    files.length > 0 &&
                    files.map((file) => file?.status === "done").filter(Boolean)
                      .length === files.length
                  );
                },
              }}
              render={({ field: { onChange } }) => (
                <DragUploader
                  mode="tour"
                  className="mt-1"
                  maxFiles={1}
                  defaultValue={
                    Array.isArray(tour?.coverPhotos.mobile)
                      ? tour?.coverPhotos.mobile
                      : []
                  }
                  onChange={onChange}
                />
              )}
            />
            {errors.coverPhotos?.mobile && (
              <span className="text-sm text-red-500">
                Please upload cover photo for mobile.
              </span>
            )}
          </div>

          <div className="col-span-12">
            <Label>Marketing Photo (Optional)</Label>
            <p className="text-xs text-neutral-500">
              Upload a photo which will be displayed as cover image while
              sharing link in social media (.webp, .jpg, .jpeg, .png & .gif).
              Recommended Size is 1200 x 630 px. Image should be less than 300kb
              to properly work in whatsapp.
            </p>
            <Controller
              name="marketingPhoto"
              control={control}
              rules={{
                validate: (file) => {
                  return file ? file.status === "done" : true;
                },
              }}
              render={({ field: { onChange } }) => (
                <DragUploader
                  mode="tour"
                  className="mt-1"
                  maxFiles={1}
                  defaultValue={
                    tour?.marketingPhoto ? [tour?.marketingPhoto] : []
                  }
                  onChange={(files) => {
                    onChange(get(files, "0"));
                  }}
                />
              )}
            />
            {errors.marketingPhoto && (
              <span className="text-sm text-red-500">
                Please upload a marketing photo
              </span>
            )}
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default BasicInformation;
