import React from "react";
import { SupplierServiceWithId } from "domain/supplier-service.type";
import Modal from "shared/Modal/Modal";
import Button from "shared/Button/Button";
import SupplierServiceForm from "components/SupplierServiceForm/SupplierServiceForm";

interface EditSupplierServiceProps {
  formType: "service" | "supplier";
  supplierService: SupplierServiceWithId;
  onEditSupplierService: (supplierService: SupplierServiceWithId) => void;
}

const EditSupplierService = ({ formType, supplierService, onEditSupplierService }: EditSupplierServiceProps) => {
  return (
    <Modal
      modalTitle={`Configure ${supplierService.supplier?.name}`}
      className="w-1/2"
      renderTrigger={({ openModal }) => (
        <Button sizeClass="px-5 py-1" onClick={openModal}>
          Config
        </Button>
      )}
      renderContent={({ closeModal }) => {
        return (
          <SupplierServiceForm
            formType={formType}
            supplierService={supplierService}
            onCancel={closeModal}
            onSuccess={(supplierService) => {
              onEditSupplierService(supplierService);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

export default EditSupplierService;
