import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import environment from "config/environment";
import { addQueryToUrl } from "utils/url";
import { HotelWithId } from "domain/hotel.type";
import { SupplierServiceWithId } from "domain/supplier-service.type";
import { SupplierWithId } from "domain/supplier.type";
import { CustomError } from "domain/custom-error.type";
import { fetcher } from "hooks/useFetch";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Label from "shared/Label/Label";
import Select from "shared/Select/Select";
import Button from "shared/Button/Button";
import AutoComplete from "shared/AutoComplete/AutoComplete";
import RadioGroup from "shared/RadioGroup/RadioGroup";

export interface SupplierServiceFormProps {
  formType: "service" | "supplier";
  restrictToSupplier?: SupplierServiceWithId["supplier"];
  restrictToServiceType?: SupplierServiceWithId["serviceType"];
  restrictToService?: SupplierServiceWithId["service"];
  supplierService?: SupplierServiceWithId;
  className?: string;
  onCancel?: () => void;
  onSuccess?: (supplierService: SupplierServiceWithId) => void;
}

export const initialState: SupplierServiceWithId = {
  id: "",
  supplierId: "",
  serviceType: null as unknown as SupplierServiceWithId["serviceType"],
  serviceId: "",
  margin: null,
  paymentTerms: null,
  status: "INACTIVE",
};

const SupplierServiceForm: FC<SupplierServiceFormProps> = ({
  formType,
  restrictToSupplier,
  restrictToServiceType,
  restrictToService,
  supplierService,
  className = "",
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<
    SupplierServiceWithId & {
      overrideMargin: boolean;
      overridePaymentTerms: boolean;
    }
  >({
    defaultValues: {
      ...initialState,
      ...supplierService,
      overrideMargin: !!supplierService?.margin ?? false,
      overridePaymentTerms: !!supplierService?.paymentTerms ?? false,
      supplierId: supplierService?.supplierId || restrictToSupplier?.id,
      serviceId: supplierService?.serviceId || restrictToService?.id,
      supplier: supplierService?.supplier || restrictToSupplier,
      service: supplierService?.service || restrictToService,
    },
  });

  const serviceType = watch("serviceType", supplierService?.serviceType);
  const supplier = watch(
    "supplier",
    supplierService?.supplier || restrictToSupplier
  );
  const service = watch(
    "service",
    supplierService?.service || restrictToService
  );
  const overrideMargin = watch("overrideMargin", !!supplierService?.margin);
  const overridePaymentTerms = watch(
    "overridePaymentTerms",
    !!supplierService?.paymentTerms
  );

  const onSubmit = async (data: SupplierServiceWithId) => {
    try {
      const method = supplierService?.id ? "PUT" : "POST";
      const result = await fetcher({
        url: [`${environment.apiUrl}/supplier-service`, supplierService?.id]
          .filter(Boolean)
          .join("/"),
        method: method,
        body: JSON.stringify({
          ...data,
          margin: overrideMargin ? data.margin : null,
          paymentTerms: overridePaymentTerms ? data.paymentTerms : null,
        }),
      });

      if (result.errors) {
        setServerErrors(result.errors);
        if (method === "POST") {
          toast.error(`Failed to link ${formType}. Please check the errors.`);
        } else {
          toast.error(`Failed to update ${formType}. Please check the errors.`);
        }
      } else {
        onSuccess({ ...data, ...result.supplierService });
        if (method === "POST") {
          toast.success(`The ${formType} is linked successfully!`);
        } else {
          toast.success(`The ${formType} is updated successfully!`);
        }
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <form
      className={`grid grid-cols-2 gap-6 ${className}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col-span-2">
        <Label required>Supplier</Label>
        <AutoComplete<SupplierWithId>
          isDisabled={!!supplierService?.supplierId || !!restrictToSupplier?.id}
          defaultValue={supplier}
          loadOptions={(query) => {
            return fetcher({
              url: addQueryToUrl(`${environment.apiUrl}/supplier`, {
                limit: 50,
                skip: 0,
                searchText: query,
              }),
            });
          }}
          onChange={(supplier) => {
            setValue("supplierId", supplier?.id || "", {
              shouldTouch: true,
              shouldDirty: supplierService?.supplierId !== supplier?.id,
            });
            setValue("supplier", supplier ? supplier : undefined);
          }}
        />
      </div>

      <div className="col-span-2">
        <Label required>Service Type</Label>
        <Select
          className="mt-1"
          disabled={!!supplierService?.serviceId || !!restrictToServiceType}
          {...register("serviceType", { required: true })}
        >
          <option value="">Select service type</option>
          <option value="HOTEL">Hotel</option>
        </Select>
        {errors.serviceType && (
          <span className="text-sm text-red-500">
            Please select service type
          </span>
        )}
      </div>

      <div className="col-span-2">
        <Label required>Service</Label>
        <AutoComplete<SupplierServiceWithId["service"]>
          isDisabled={!!supplierService?.serviceId || !!restrictToService?.id}
          defaultValue={service}
          getOptionLabel={(item) => {
            if (serviceType === "HOTEL") {
              const { name, place } = item as HotelWithId;
              return [name, place?.name, place?.country.name].join(", ");
            }
            return (item as any)?.name;
          }}
          loadOptions={(searchText) => {
            let url = `${environment.apiUrl}/hotel`;
            if (serviceType === "HOTEL") {
              url = `${environment.apiUrl}/hotel`;
            }
            return fetcher({
              url: addQueryToUrl(url, { limit: 50, skip: 0, searchText }),
            });
          }}
          onChange={(service) => {
            setValue("serviceId", service?.id || "", {
              shouldTouch: true,
              shouldDirty: supplierService?.serviceId !== service?.id,
            });
            setValue("service", service ? service : undefined);
          }}
        />
      </div>

      <div className="col-span-2">
        <Label>Service level margin override</Label>
        <Controller
          name="overrideMargin"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <RadioGroup
                className="mt-1"
                value={value}
                onChange={onChange}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            );
          }}
        />
      </div>

      <div className={overrideMargin ? "hidden" : "block"}>
        <Label>B2B Margin %</Label>
        <Input
          disabled
          className="mt-1"
          value={`${supplier?.b2bMarginPercent ?? 0}%`}
        />
      </div>
      <div className={overrideMargin ? "block" : "hidden"}>
        <Label required>B2B Margin %</Label>
        <Input
          type="number"
          className="mt-1"
          step={0.01}
          min={0}
          placeholder="B2B Margin percent"
          {...register("margin.b2bPercent", {
            valueAsNumber: true,
            required: overrideMargin,
            min: 0,
          })}
        />
        {errors.margin?.b2bPercent && (
          <span className="text-sm text-red-500">
            Please enter b2b margin percent
          </span>
        )}
      </div>

      <div className={overrideMargin ? "hidden" : "block"}>
        <Label>B2C Margin %</Label>
        <Input
          disabled
          className="mt-1"
          value={`${supplier?.b2cMarginPercent ?? 0}%`}
        />
      </div>
      <div className={overrideMargin ? "block" : "hidden"}>
        <Label required>B2C Margin %</Label>
        <Input
          type="number"
          className="mt-1"
          step={0.01}
          min={0}
          placeholder="B2C Margin percent"
          {...register("margin.b2cPercent", {
            valueAsNumber: true,
            required: overrideMargin,
            min: 0,
          })}
        />
        {errors.margin?.b2bPercent && (
          <span className="text-sm text-red-500">
            Please enter b2c margin percent
          </span>
        )}
      </div>

      <div className="col-span-2">
        <Label>Service level payment terms override</Label>
        <Controller
          name="overridePaymentTerms"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <RadioGroup
                className="mt-1"
                value={value}
                onChange={onChange}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            );
          }}
        />
      </div>

      <div className={overridePaymentTerms ? "hidden" : "block"}>
        <Label>Advance payment %</Label>
        <Input
          disabled
          className="mt-1"
          value={supplier?.advancePercent ?? 0}
        />
      </div>
      <div className={overridePaymentTerms ? "block" : "hidden"}>
        <Label required>Advance payment %</Label>
        <Input
          type="number"
          className="mt-1"
          placeholder={`Advance percent`}
          {...register("paymentTerms.advancePercent", {
            valueAsNumber: true,
            required: overridePaymentTerms,
          })}
        />
        {errors.paymentTerms?.advancePercent && (
          <span className="text-sm text-red-500">
            Please select advance payment percent needs to done to supplier
          </span>
        )}
      </div>

      <div className={overridePaymentTerms ? "hidden" : "block"}>
        <Label>Balance payment due days</Label>
        <Input
          disabled
          className="mt-1"
          value={supplier?.balanceDueDays ?? 0}
        />
      </div>
      <div className={overridePaymentTerms ? "block" : "hidden"}>
        <Label required>Balance payment due days</Label>
        <Input
          type="number"
          className="mt-1"
          min={0}
          placeholder="Balance payment due days"
          {...register("paymentTerms.balanceDueDays", {
            valueAsNumber: true,
            required: overridePaymentTerms,
            min: 0,
          })}
        />
        {errors.paymentTerms?.balanceDueDays && (
          <span className="text-sm text-red-500">
            Please enter no of days before trip date when balance payment needs
            to be cleared
          </span>
        )}
      </div>

      <div className="col-span-2">
        {serverErrors.map((error) => (
          <p key={error.code} className="text-red-500">
            {error.userMessage}
          </p>
        ))}
      </div>

      <Button type="button" bordered onClick={onCancel}>
        Cancel
      </Button>
      <ButtonPrimary type="submit">Submit</ButtonPrimary>
    </form>
  );
};

export default SupplierServiceForm;
