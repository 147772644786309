import React, { useState, useContext } from "react";
import { redirect } from "react-router-dom";
import Cookies from "js-cookie";

import { AuthUserWithId } from "domain/user.type";
import { loadState, removeState, saveState } from "utils/localStorage";
import { saveTokenInCookie } from "utils/cookie";

const accessTokenCookieName = "access_token";
const refreshTokenCookieName = "refresh_token";
const localStorageUserKey = "user";

type Context = {
  isAuthenticated: Boolean;
  user?: AuthUserWithId;
  setUser(user: AuthUserWithId | undefined): void;
  onSignIn(
    accessToken: string,
    refreshToken: string,
    user: AuthUserWithId
  ): void;
  onSignUp(
    accessToken: string,
    refreshToken: string,
    user: AuthUserWithId
  ): void;
  onSignOut(): void;
  hasAnyRole(roles: string[]): Boolean;
  hasAnyPermission(permissions: string[]): Boolean;
};

export const AuthContext = React.createContext<Context>({
  isAuthenticated: false,
  user: undefined,
  setUser: () => {},
  onSignIn: () => {},
  onSignUp: () => {},
  onSignOut: () => {},
  hasAnyRole: () => true,
  hasAnyPermission: () => true,
});

const hasAccessToken = !!Cookies.get(accessTokenCookieName) || !!Cookies.get(refreshTokenCookieName);
const userFromLocalStorage = loadState<AuthUserWithId>(localStorageUserKey);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(hasAccessToken);
  const [user, setUser] = useState<AuthUserWithId | undefined>(
    userFromLocalStorage
  );

  const onSignIn = (
    token: string,
    refreshToken: string,
    user: AuthUserWithId
  ) => {
    saveTokenInCookie(accessTokenCookieName, token);
    saveTokenInCookie(refreshTokenCookieName, refreshToken);
    saveState(localStorageUserKey, user);

    setUser(user);
    setIsAuthenticated(true);
  };

  const onSignUp = (
    token: string,
    refreshToken: string,
    user: AuthUserWithId
  ) => {
    saveTokenInCookie(accessTokenCookieName, token);
    saveTokenInCookie(refreshTokenCookieName, refreshToken);
    saveState(localStorageUserKey, user);

    setUser(user);
    setIsAuthenticated(true);
  };

  const onSignOut = () => {
    Cookies.remove(accessTokenCookieName);
    Cookies.remove(refreshTokenCookieName);
    removeState(localStorageUserKey);

    setUser(undefined);
    setIsAuthenticated(false);
    redirect("/");
  };

  const hasAnyRole = (roles: string[] = []): Boolean => {
    let hasRole = false;
    for (const role of roles) {
      if (user?.roles.includes(role)) {
        hasRole = true;
      }
    }
    return hasRole;
  };

  const hasAnyPermission = (permissions: string[] = []): Boolean => {
    let hasPermission = false;
    for (const permission of permissions) {
      if (user?.permissions.includes(permission)) {
        hasPermission = true;
      }
    }
    return hasPermission;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setUser,
        onSignIn,
        onSignOut,
        onSignUp,
        hasAnyRole,
        hasAnyPermission,
      }}
    >
      <>{children}</>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext<Context>(AuthContext);

export default AuthProvider;
