import React, { useCallback } from "react";
import { debounce } from "lodash";
import { Theme } from "react-select";
import AsyncSelect from "react-select/async";

export interface AutoCompleteProps<T> {
  isDisabled?: boolean;
  defaultValue?: T;
  defaultOptions?: T[] | boolean;
  placeholder?: string;
  className?: string;
  debounceTime?: number;
  isClearable?: boolean;
  loadOptions: (inputValue: string) => Promise<T[]>;
  getOptionLabel?: (option: T) => string;
  getOptionValue?: (option: T) => string;
  onChange?: (selectedOption: T | null) => void;
  noOptionsMessage?: (inputValue: string) => string;
}

const AutoComplete = <T,>({
  isDisabled = false,
  defaultValue,
  defaultOptions = false,
  placeholder = "Select",
  className = "",
  debounceTime = 1000,
  isClearable = true,
  loadOptions,
  getOptionLabel = (item: T) => (item as any).name,
  getOptionValue = (item: T) => (item as any).id,
  onChange = (selectedOption: T | null) => {},
}: AutoCompleteProps<T>) => {
  const debouncedLoadOptions = useCallback(
    debounce((inputValue: string, callback: (options: T[]) => void) => {
      loadOptions(inputValue).then(callback);
    }, debounceTime),
    [loadOptions, debounceTime]
  );

  return (
    <AsyncSelect<T, false>
      className={`async-react-select ${className}`}
      classNamePrefix="react-select"
      cacheOptions
      defaultOptions={defaultOptions}
      isClearable={isClearable}
      isDisabled={isDisabled}
      loadOptions={debouncedLoadOptions}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      defaultValue={defaultValue}
      onChange={onChange}
      noOptionsMessage={({ inputValue }: any) =>
        !inputValue ? "Type to search" : "No data"
      }
      placeholder={placeholder}
      theme={(theme: Theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "rgb(var(--c-primary-100))",
          primary50: "rgb(var(--c-primary-300))",
          primary75: "rgb(var(--c-primary-400))",
          primary: "rgb(var(--c-primary-500))",
        },
      })}
    />
  );
};

export default AutoComplete;
