import React, { useState } from "react";
import environment from "config/environment";
import { SupplierServiceWithId } from "domain/supplier-service.type";
import { CustomError } from "domain/custom-error.type";
import { fetcher } from "hooks/useFetch";
import Modal from "shared/Modal/Modal";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Flex from "shared/Flex/Flex";

interface DeleteSupplierServiceProps {
  formType: "service" | "supplier";
  supplierService: SupplierServiceWithId;
  onDeleteSupplierService: (supplierService: SupplierServiceWithId) => void;
}

const DeleteSupplierService = ({
  formType,
  supplierService,
  onDeleteSupplierService,
}: DeleteSupplierServiceProps) => {
  return (
    <Modal
      modalTitle={
        formType === "service"
          ? `Unlink ${supplierService.service?.name}`
          : `Unlink ${supplierService.supplier?.name}`
      }
      className="w-96"
      renderTrigger={({ openModal }) => (
        <Button sizeClass="px-5 py-1" onClick={openModal}>
          Unlink
        </Button>
      )}
      renderContent={({ closeModal }) => {
        return (
          <DeleteSupplierServiceForm
            formType={formType}
            supplierService={supplierService}
            onSuccess={() => {
              onDeleteSupplierService(supplierService);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

interface DeleteSupplierServiceFormProps {
  formType: "service" | "supplier";
  supplierService: SupplierServiceWithId;
  onSuccess: () => void;
}

const DeleteSupplierServiceForm = ({
  formType,
  supplierService,
  onSuccess = () => {},
}: DeleteSupplierServiceFormProps) => {
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);

  const onSubmit = async () => {
    const result = await fetcher({
      url: `${environment.apiUrl}/supplier-service/${supplierService.id}`,
      method: "DELETE",
      body: JSON.stringify({}),
    });

    if (result.errors) {
      setServerErrors(result.errors);
    } else {
      onSuccess();
    }
  };

  return (
    <div>
      <div>
        Do you want to unlink
        {formType === "service"
          ? supplierService.service?.name
          : supplierService.supplier?.name}
        ?
      </div>
      <div className="col-span-2">
        {serverErrors.map((error) => (
          <p key={error.code} className="text-red-500">
            {error.userMessage}
          </p>
        ))}
      </div>
      <Flex direction="row" justify="end" className="mt-4">
        <ButtonPrimary onClick={onSubmit}>Unlink</ButtonPrimary>
      </Flex>
    </div>
  );
};

export default DeleteSupplierService;
