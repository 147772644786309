import React, { FC } from "react";
import { RingLoader } from 'react-spinners'

export interface LoaderProps {
  className?: string;
}

const Loader: FC<LoaderProps> = ({ className = "" }) => {
  return (
    <div
      className={`app-Loader flex flex-row items-center justify-center h-screen ${className}`}
    >
      <RingLoader color="#36d7b7" />
    </div>
  );
};

export default Loader;
