import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

import environment from "config/environment";
import { PlaceWithId } from "domain/place.type";
import { HotelWithId } from "domain/hotel.type";
import { CustomError } from "domain/custom-error.type";
import useFetch, { fetcher } from "hooks/useFetch";
import HotelCard from "components/HotelCard";
import CommonLayout from "./CommonLayout";

export interface HotelPreviewProps {
  hotel?: HotelWithId;
  step: number;
  onClickPrev: () => void;
  onSuccess: (data: HotelWithId) => void;
}

const HotelPreview: FC<HotelPreviewProps> = ({
  hotel,
  step,
  onClickPrev = () => {},
  onSuccess = () => {},
}) => {
  const placeUrl = `${environment.apiUrl}/place/${hotel?.placeId}`;
  const { data: places = [] } = useFetch<PlaceWithId[]>({ url: placeUrl });
  const { handleSubmit } = useForm<HotelWithId>();
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);

  const onSubmit = async () => {
    const path = hotel?.id ? `/hotel/${hotel?.id}` : '/hotel'
    const method = hotel?.id ? "PUT" : "POST"
    const result = await fetcher({
      url: `${environment.apiUrl}${path}`,
      method,
      body: JSON.stringify(hotel),
    });
    if (result.errors) {
      setServerErrors(result.errors);
    } else {
      onSuccess(result.hotel as HotelWithId);
    }
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Congratulations 🎉</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Excellent, congratulations on completing the details. 
            {hotel?.id ? `Now the update is ready to be published.` : 'Now its ready to be published.' }
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div>
          <h3 className="text-lg font-semibold">
            This is your hotel will look like to guests
          </h3>
          <div className="max-w-xs">
            <HotelCard
              className="mt-8"
              hotel={{ id: "new", place: places[0], ...hotel } as HotelWithId}
            />
          </div>
        </div>
        <div className="col-span-2">
          {serverErrors.map((error) => (
            <p key={error.code} className="text-red-500">
              {error.userMessage}
            </p>
          ))}
        </div>
      </>
    </CommonLayout>
  );
};

export default HotelPreview;
