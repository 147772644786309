import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { get, pick } from "lodash";
import Label from "shared/Label/Label";
import CommonLayout from "./CommonLayout";
import DragUploader from "components/DragUploader/DragUploader";
import { HotelRoomWithId } from "domain/hotel-room.type";

export interface HotelRoomPhotosProps {
  room?: HotelRoomWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<HotelRoomWithId>) => void;
}

const HotelRoomPhotos: FC<HotelRoomPhotosProps> = ({
  room,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<HotelRoomWithId>({
    defaultValues: pick<HotelRoomWithId>(room, ["thumbnail", "photos"]),
  });

  const onSubmit = (data: HotelRoomWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Room photos</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2">
            <Label>Thumbnail</Label>
            <p className="text-xs text-neutral-500">
              Upload a thumbnail which will be displayed as card image (.webp,
              .jpg, .jpeg, .png & .gif). Prefer aspect ratio of 4:3(W:H).
            </p>
            <Controller
              name="thumbnail"
              control={control}
              rules={
                {
                  // validate: (file) => {
                  //   return file?.status === "done";
                  // },
                }
              }
              render={({ field: { onChange } }) => (
                <DragUploader
                  mode="hotel-room"
                  className="mt-1"
                  maxFiles={1}
                  defaultValue={room?.thumbnail ? [room?.thumbnail] : []}
                  onChange={(files) => {
                    onChange(get(files, "0"));
                  }}
                />
              )}
            />
            {errors.thumbnail && (
              <span className="text-sm text-red-500">
                Please upload a thumbnail
              </span>
            )}
          </div>

          <div className="col-span-2">
            <Label>Photos</Label>
            <p className="text-xs text-neutral-500">
              Upload multiple photos of the room (.webp, .jpg, .jpeg, .png &
              .gif). Prefer aspect ratio of 4:3(W:H).
            </p>
            <Controller
              name="photos"
              control={control}
              rules={
                {
                  // validate: (files) => {
                  //   return !!files.length;
                  // },
                }
              }
              render={({ field: { onChange } }) => (
                <DragUploader
                  mode="hotel-room"
                  className="mt-1"
                  maxFiles={100}
                  defaultValue={room?.photos || []}
                  onChange={onChange}
                />
              )}
            />
            {errors.photos && (
              <span className="text-sm text-red-500">
                Please upload some photos of room
              </span>
            )}
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default HotelRoomPhotos;
