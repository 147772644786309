import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface HotelCodeProps {
  register: UseFormRegister<HotelWithId>;
  error?: FieldError;
  className?: string;
}

const HotelCode: FC<HotelCodeProps> = ({ register, error, className }) => {
  return (
    <div className={className}>
      <Label>Hotel code</Label>
      <Input
        className="mt-1"
        placeholder="Hotel code"
        {...register("code", { required: true })}
      />
      {error && (
        <span className="text-sm text-red-500">Please enter hotel code</span>
      )}
    </div>
  );
};

export default HotelCode;
