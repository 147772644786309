import React, { Fragment, useState, useRef } from "react";
import { BeatLoader } from "react-spinners";
import { Moment } from "moment";
import { debounce, keyBy } from "lodash";

import { mealPlans } from "utils/hotelUtils";
import { HotelRoomWithId } from "domain/hotel-room.type";
import { HotelRoomRateWithId } from "domain/hotel-room-rate.type";
import { SupplierServiceWithId } from "domain/supplier-service.type";
import Flex from "shared/Flex/Flex";
import Input from "shared/Input/Input";
import ExpandCollapse from "shared/ExpandCollapse/ExpandCollapse";
import HotelRoomRateRow from "./HotelRoomRateRow";
import AddEditHotelRoomRate from "./AddEditHotelRoomRate";
import { fetcher } from "hooks/useFetch";
import environment from "config/environment";
import toast from "react-hot-toast";

type HotelRoomRateWithMealPlanProps = {
  isLoading: boolean;
  supplierService?: SupplierServiceWithId;
  room: HotelRoomWithId;
  rates?: HotelRoomRateWithId[];
  dateRange: Moment[];
  onChangeHotelRoomRate: (hotelRoomRate?: HotelRoomRateWithId) => void;
};

const HotelRoomRateWithMealPlan: React.FC<HotelRoomRateWithMealPlanProps> = ({
  isLoading,
  supplierService,
  room,
  rates = [],
  dateRange,
  onChangeHotelRoomRate,
}) => {
  const [expanded, setExpanded] = useState(false);
  const debouncedSubmit = useRef(
    debounce(async (rateId: string, availableQty: number) => {
      try {
        const result = await fetcher({
          url: `${environment.apiUrl}/hotel-room-rate/${rateId}`,
          method: "PUT",
          body: JSON.stringify({
            availableQty,
          }),
        });

        if (result.errors) {
          toast.error("Failed to update availability");
        } else {
          toast.success("The availability is updated successfully!");
        }
      } catch (error) {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }, 1000)
  ).current;

  const handleChange = (rateId: string, availableQty: number) => {
    onChangeHotelRoomRate({
      id: rateId,
      availableQty,
    } as HotelRoomRateWithId);

    debouncedSubmit(rateId, availableQty);
  };

  const ratesByDate = keyBy(rates, "date");

  return (
    <>
      <HotelRoomRateRow
        left={<Flex className="font-semibold text-lg ml-3">{room.name}</Flex>}
        right={<ExpandCollapse expanded={expanded} onToggle={setExpanded} />}
        className="mb-2"
      >
        {dateRange.map((date) => {
          const formattedDate = date.format("YYYY-MM-DD");
          const rateByDate = ratesByDate[formattedDate];
          const isRateAdded = !!rateByDate;
          const availableQty = rateByDate?.availableQty;
          return (
            <Fragment key={formattedDate}>
              <BeatLoader size={5} loading={isLoading} />
              <Input
                show={!isLoading && isRateAdded}
                type="number"
                min={0}
                value={availableQty}
                onChange={(e) => handleChange(rateByDate.id, +e.target.value)}
                className={`font-bold text-center text-white cursor-default z-0 focus:z-10 border-none rounded-none ${
                  availableQty > 0 ? "bg-emerald-500" : "bg-rose-500"
                }`}
              />
              <AddEditHotelRoomRate
                buttonType={isRateAdded ? "edit" : "add"}
                showButton={!isLoading && !isRateAdded}
                supplierService={supplierService}
                rooms={[room]}
                hotelRoomRate={{
                  ...rateByDate,
                  date: formattedDate,
                  roomId: room.id,
                }}
                onChangeHotelRoomRate={onChangeHotelRoomRate}
              />
            </Fragment>
          );
        })}
      </HotelRoomRateRow>
      {expanded &&
        mealPlans.map((mealPlan) => (
          <HotelRoomRateRow
            key={mealPlan.key}
            left={
              <Flex className="w-full ml-8 font-light text-sm" direction="row">
                {mealPlan.key} - {mealPlan.value}
              </Flex>
            }
          >
            {dateRange.map((date) => {
              const formattedDate = date.format("YYYY-MM-DD");
              const rateByDate = ratesByDate[formattedDate];
              const currency = rateByDate?.currency;
              const buyPrices = rateByDate?.buyPrices || [];
              const buyPrice = buyPrices.find(
                (buyPrice) => buyPrice.mealPlan === mealPlan.key
              );
              const isMealPlanAdded = !!buyPrice;

              return (
                <Fragment key={formattedDate}>
                  <BeatLoader size={5} loading={isLoading} />
                  <Flex show={!isLoading && isMealPlanAdded} className="mr-2">
                    {currency} {buyPrice?.base}
                  </Flex>
                  <AddEditHotelRoomRate
                    buttonType={isMealPlanAdded ? "edit" : "add"}
                    showButton={!isLoading}
                    supplierService={supplierService}
                    rooms={[room]}
                    hotelRoomRate={{
                      ...rateByDate,
                      buyPrices: isMealPlanAdded
                        ? buyPrices
                        : [
                            ...buyPrices,
                            {
                              mealPlan: mealPlan.key,
                              base: 0,
                              extraAdult: 0,
                              extraChild: 0,
                              extraInfant: 0,
                            },
                          ],
                      date: formattedDate,
                      roomId: room.id,
                    }}
                    onChangeHotelRoomRate={onChangeHotelRoomRate}
                  />
                </Fragment>
              );
            })}
          </HotelRoomRateRow>
        ))}
    </>
  );
};

export default HotelRoomRateWithMealPlan;
