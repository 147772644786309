import React, { FC, useEffect } from "react";
import { FieldError, UseFormRegister, UseFormWatch } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import { PlaceWithId } from "domain/place.type";
import Label from "shared/Label/Label";
import Select from "shared/Select/Select";
import environment from "config/environment";
import useFetch from "hooks/useFetch";

export interface HotelPlaceProps {
  register: UseFormRegister<HotelWithId>;
  error?: FieldError;
  className?: string;
  watch: UseFormWatch<HotelWithId>;
  onChange?: (value?: PlaceWithId) => void;
}

const HotelPlace: FC<HotelPlaceProps> = ({
  watch,
  register,
  error,
  className,
  onChange = () => {},
}) => {
  const placeUrl = `${environment.apiUrl}/place`;
  const { isLoading: isPlacesLoading, data: places = [] } = useFetch<
    PlaceWithId[]
  >({ url: placeUrl }, 1000);
  const placeId = watch("placeId");

  useEffect(() => {
    if (placeId) {
      const place = places.find((place) => place.id === placeId);
      return onChange(place);
    }
    return;
  }, [placeId, isPlacesLoading]);

  return (
    <div className={className}>
      <Label>Place</Label>
      <Select
        className="mt-1"
        isLoading={isPlacesLoading}
        {...register("placeId", { required: true })}
      >
        <option value="">Select Place</option>
        {places.map((place) => {
          return (
            <option key={place.id} value={place.id}>
              {[place.name, place.country.name].join(", ")}
            </option>
          );
        })}
      </Select>
      {error && (
        <span className="text-sm text-red-500">Please select the place</span>
      )}
    </div>
  );
};

export default HotelPlace;
