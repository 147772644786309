import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export const saveTokenInCookie = (key: string, token: any) => {
  const { exp = 0 } = jwtDecode<any>(token);
  const expires = new Date(0);
  expires.setUTCSeconds(exp);

  Cookies.set(key, token, { expires });
};
