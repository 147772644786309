import React, { InputHTMLAttributes } from "react";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  subLabel?: string;
  className?: string;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, label = "", subLabel = "", className = "", ...args }, ref) => {
    return (
      <div className={`flex text-sm sm:text-base ${className}`}>
        <input
          ref={ref}
          id={name}
          name={name}
          type="checkbox"
          className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
          {...args}
        />
        {label && (
          <label
            htmlFor={name}
            className="ml-3.5 flex flex-col flex-1 justify-center"
          >
            <span className=" text-neutral-900 dark:text-neutral-100">
              {label}
            </span>
            {subLabel && (
              <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">
                {subLabel}
              </p>
            )}
          </label>
        )}
      </div>
    );
  }
);

export default Checkbox;
