import {
  HomeIcon,
  MapIcon,
  UsersIcon,
  GlobeAsiaAustraliaIcon,
  BuildingOfficeIcon,
  QueueListIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";

import SiderLayout, {
  MenuListConfig,
} from "components/SiderLayout/SiderLayout";
import Dashboard from "./Dashboard";
import Users from "./Users";
import Hotels from "./Hotels";
import Places from "./Places";
import Tours from "./Tours/Tour";
import ToursListing from "./ToursListing";
import Suppliers from "./Suppliers";

const AdminPanel = () => {
  const menu: MenuListConfig = [
    {
      key: "admin/dashboard",
      path: "",
      text: "Dashboard",
      element: <Dashboard />,
      icon: <HomeIcon />,
      auth: true,
    },
    {
      key: "admin/users",
      path: "users",
      text: "Users",
      element: <Users />,
      icon: <UsersIcon />,
      auth: true,
    },
    {
      key: "admin/places",
      path: "places",
      text: "Places",
      element: <Places />,
      icon: <MapIcon />,
      auth: true,
    },
    {
      key: "admin/suppliers",
      path: "suppliers",
      text: "Suppliers",
      element: <Suppliers />,
      icon: <WrenchScrewdriverIcon />,
      auth: true,
    },
    {
      key: "admin/hotels",
      path: "hotels",
      text: "Hotels",
      element: <Hotels />,
      icon: <BuildingOfficeIcon />,
      auth: true,
    },
    {
      key: "admin/tours",
      path: "tours",
      text: "Tours",
      element: <Tours />,
      icon: <GlobeAsiaAustraliaIcon />,
      auth: true,
    },
    {
      key: "admin/tours-listings",
      path: "tours-listings",
      text: "Tours Listings",
      element: <ToursListing />,
      icon: <QueueListIcon />,
      auth: true,
    },
  ];

  return <SiderLayout menu={menu} />;
};

export default AdminPanel;
