import React from "react";
import { SupplierWithId } from "domain/supplier.type";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import SupplierForm from "./SupplierForm";

interface CreateSupplierProps {
  onAddSupplier: (supplier: SupplierWithId) => void;
}

const CreateSupplier = ({ onAddSupplier }: CreateSupplierProps) => {
  return (
    <Modal
      modalTitle="Add a supplier"
      fullScreen
      renderTrigger={({ openModal }) => (
        <ButtonPrimary onClick={openModal}>Add a supplier</ButtonPrimary>
      )}
      renderContent={({ closeModal }) => {
        return (
          <SupplierForm
            onSuccess={(supplier) => {
              onAddSupplier(supplier);
              closeModal();
            }} 
          />
        );
      }}
    />
  );
};

export default CreateSupplier;
