import React from "react";
import Heading from "shared/Heading/Heading";

const SectionContent = () => {
  return (
    <div className={`app-SectionContent relative`} data-app-id="SectionContent">
      <Heading desc="Below are the valid account details of Diskounto Holidays">
        Bank Account
      </Heading>
      <div><b>Account Holder:</b> Diskounto Holidays Private Limited</div>
      <div><b>Account No:</b> 1447791612</div>
      <div><b>IFSC:</b> KKBK0000876</div>
      <div><b>Branch:</b> 1st Floor, Shanti Point, Surat, Gujarat - 395002</div>
    </div>
  );
};

export default SectionContent;
