import React, { FC } from "react";
import BackgroundSection from "components/BackgroundSection";
import Modal from "shared/Modal/Modal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import TourRequestDetails from "components/TourDetails/TourRequestDetails";
import SectionHeading from "components/SectionHeading";

export interface ContactUsToPlanProps {
  className?: string;
}

const ContactUsToPlan: FC<ContactUsToPlanProps> = ({ className = "" }) => {
  return (
    <div className={`container ${className}`}>
      <div className="relative py-16">
        <BackgroundSection className="bg-primary-100 dark:bg-black dark:bg-opacity-20 " />
        <div>
          <SectionHeading
            desc="Need help finalizing your travel plans? Contact us today, and our expert team will assist you in making the perfect arrangements!"
            className="my-6"
            isCenter
          >
            Need Assistance?
          </SectionHeading>
          <div className="flex items-center justify-center">
            <Modal
              modalTitle="Request a callback"
              className="w-screen sm:w-96"
              renderTrigger={({ openModal }) => (
                <ButtonPrimary onClick={openModal} className="mt-2">
                  Request a callback
                </ButtonPrimary>
              )}
              renderContent={() => {
                return <TourRequestDetails btnText="Request Callback" />;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsToPlan;
