import React, { FC, ReactNode } from "react";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";

import { HotelWithId } from "domain/hotel.type";
import SiderContentHeading from "components/SiderContentHeading";

export interface HotelHeadingProps {
  hotel?: HotelWithId;
  actionsComponent?: ReactNode;
  errorsComponent?: ReactNode;
}

const HotelHeading: FC<HotelHeadingProps> = ({
  hotel,
  actionsComponent,
  errorsComponent,
}) => {
  return (
    <SiderContentHeading
      icon={<BuildingOfficeIcon className="w-6 mr-3" />}
      heading={[hotel?.name, hotel?.place?.name, hotel?.place?.country.name]
        .filter(Boolean)
        .join(", ")}
      actionsComponent={actionsComponent}
      errorsComponent={errorsComponent}
    />
  );
};

export default HotelHeading;
