import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { SupplierWithId } from "domain/supplier.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface SupplierBankAccountNumberProps {
  register: UseFormRegister<SupplierWithId>;
  error?: FieldError;
  className?: string;
}

const SupplierBankAccountNumber: FC<SupplierBankAccountNumberProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label required>Bank account number</Label>
      <Input
        className="mt-1"
        placeholder="Bank account number"
        {...register("bankAccount.accountNo", { required: true })}
      />
      {error && (
        <span className="text-sm text-red-500">
          Please enter bank account number
        </span>
      )}
    </div>
  );
};

export default SupplierBankAccountNumber;
