import React, { FC, useEffect, useMemo } from "react";
import Glide from "@glidejs/glide";
import { Link } from "react-router-dom";
import useUniqueId from "hooks/useUniqueId";
import LazyImage from "shared/LazyImage/LazyImage";
import NextPrev from "shared/NextPrev/NextPrev";

export interface GallerySliderProps {
  uniqueID: string;
  images: string[];
  href?: string;
  className?: string;
  ratioClass?: string;
  glideOptions?: Partial<Glide.Options>;
}

const GallerySlider: FC<GallerySliderProps> = ({
  uniqueID = "UNIQUE_ID_IS_MANDATORY",
  images,
  href,
  className = "",
  ratioClass = "aspect-w-4 aspect-h-3",
  glideOptions,
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}_${useUniqueId()}`;
  const MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      gap: 0,
      keyboard: false,
      ...glideOptions,
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    if (images.length) {
      MY_GLIDEJS.mount();
      MY_GLIDEJS.mount();

    }
    return () => {
      MY_GLIDEJS.destroy();
    };
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  return (
    <div
      className={`app-GallerySlider ${className}`}
      data-app-id="GallerySlider"
    >
      <div className={`${UNIQUE_CLASS} relative group overflow-hidden`}>
        {/* Images */}
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {images.map((item, index) => (
              <li key={index} className="glide__slide">
                {href ? (
                  <Link to={href} className={`block`}>
                    <LazyImage src={item} containerClassName={`${ratioClass}`}/>
                  </Link>
                ) : (
                  <div className={`block`}>
                    <LazyImage src={item} containerClassName={`${ratioClass}`} />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* DOTS */}
        <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
        <div
          className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
          data-glide-el="controls[nav]"
        >
          {images.map((_, i) => {
            if (images.length === 1) {
              return null;
            }
            return (
              <button
                className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
                key={i}
                data-glide-dir={`=${i}`}
              />
            );
          })}
        </div>

        {/* NAV */}
        {images.length > 1 && (
          <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
            <NextPrev
              className="w-full justify-between"
              btnClassName="w-8 h-8"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GallerySlider;
