import React from 'react'
import { HotelWithId } from 'domain/hotel.type'

type HotelThingsToKnowProps = {
    hotel: HotelWithId
}

const HotelThingsToKnow = ({ hotel }: HotelThingsToKnowProps) => {
  return (
    <div className="w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8">
    {/* HEADING */}
    {/* <h2 className="text-2xl font-semibold">Things to know</h2>
    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

    {/* CONTENT */}
    <div>
      <h4 className="text-lg font-semibold">Check-in time</h4>
      <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
        <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
          <span>Check-in</span>
          <span>{hotel.details.checkInTime ? `${hotel.details.checkInTime} hours` : 'Contact Property for details'}</span>
        </div>
        <div className="flex space-x-10 justify-between p-3">
          <span>Check-out</span>
          <span>{hotel.details.checkOutTime ? `${hotel.details.checkOutTime} hours` : 'Contact Property for details'}</span>
        </div>
      </div>
    </div>
    {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

    {/* CONTENT */}
    {/* <div>
      <h4 className="text-lg font-semibold">Other Notes</h4>
      <div className="prose sm:prose">
        <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
          <li>
            Ban and I will work together to keep the landscape and
            environment green and clean by not littering, not using
            stimulants and respecting people around.
          </li>
          <li>Do not sing karaoke past 11:30</li>
        </ul>
      </div>
    </div> */}
  </div>
  )
}

export default HotelThingsToKnow