import React, { FC } from "react";
import { Control, Controller, FormState } from "react-hook-form";
import { get } from "lodash";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import DragUploader from "components/DragUploader/DragUploader";

export interface HotelThumbnailProps {
  defaultValue?: HotelWithId["thumbnail"];
  control: Control<HotelWithId>;
  error?: FormState<HotelWithId>["errors"]["thumbnail"];
  className?: string;
}

const HotelThumbnail: FC<HotelThumbnailProps> = ({
  defaultValue,
  control,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label>Thumbnail</Label>
      <p className="text-xs text-neutral-500">
        Upload a thumbnail which will be displayed as card image. (.webp, .jpg,
        .jpeg, .png & .gif). Prefer aspect ratio of 4:3(W:H).
      </p>
      <Controller
        name="thumbnail"
        control={control}
        rules={{
          validate: (file) => {
            return file?.status === "done";
          },
        }}
        render={({ field: { onChange } }) => (
          <DragUploader
            mode="hotel"
            className="mt-1"
            maxFiles={1}
            defaultValue={defaultValue ? [defaultValue] : []}
            onChange={(files) => {
              onChange(get(files, "0"));
            }}
          />
        )}
      />
      {error && (
        <span className="text-sm text-red-500">Please upload a thumbnail</span>
      )}
    </div>
  );
};

export default HotelThumbnail;
