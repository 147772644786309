import React, { FC } from "react";
import { Control, Controller, FormState } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import DragUploader from "components/DragUploader/DragUploader";

export interface HotelPhotosProps {
  defaultValue?: HotelWithId["photos"];
  control: Control<HotelWithId>;
  error?: FormState<HotelWithId>["errors"]["photos"];
  className?: string;
}

const HotelPhotos: FC<HotelPhotosProps> = ({
  defaultValue,
  control,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label>Photos</Label>
      <p className="text-xs text-neutral-500">
        Upload multiple photos of the hotel (.webp, .jpg, .jpeg, .png & .gif).
        Prefer aspect ratio of 4:3(W:H).
      </p>
      <Controller
        name="photos"
        control={control}
        rules={{
          validate: (files) => {
            return !!files.length;
          },
        }}
        render={({ field: { onChange } }) => (
          <DragUploader
            mode="hotel"
            className="mt-1"
            maxFiles={100}
            defaultValue={defaultValue || []}
            onChange={onChange}
          />
        )}
      />
      {error && (
        <span className="text-sm text-red-500">Please upload hotel photos</span>
      )}
    </div>
  );
};

export default HotelPhotos;
