import React, { Fragment } from "react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";

import { HotelWithId } from "domain/hotel.type";
import { handlePlural } from "utils/string";
import Flex from "shared/Flex/Flex";
import List from "shared/List/List";
import LazyImage from "shared/LazyImage/LazyImage";
import environment from "config/environment";

interface EditHotelProps {
  hotel: HotelWithId;
}

const HotelRooms = ({ hotel }: EditHotelProps) => {
  return (
    <Popover className="relative">
      {() => (
        <>
          <PopoverButton
            className={`inline-flex items-center justify-center border border-primary-500 p-1 w-24 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            {handlePlural(hotel.rooms?.length, "room", "s")}
          </PopoverButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className=" absolute z-10 w-screen max-w-[400px] px-4 -right-10 sm:right-0 sm:px-0">
              <div className="bg-white dark:bg-neutral-800 p-4 overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                <Flex direction="row" justify="between" align="center">
                  <div className="relative font-bold line-clamp-1">
                    {hotel.name}
                  </div>
                </Flex>
                {/* <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" /> */}
                <List
                  bordered
                  items={hotel.rooms || []}
                  rowKey="id"
                  className="mt-2"
                  emptyMessage="No room available"
                  renderItem={({ item }) => (
                    <div className="p-2">
                      <Flex direction="row" align="center">
                        <LazyImage
                          src={`${environment.assetsUrl}/${item.thumbnail?.path}`}
                          className="w-20 h-20 border border-primary-500 rounded-sm"
                          containerClassName="w-auto"
                        />
                        <Flex className="flex-1 ml-2">
                          <div className="text-base">{item.name}</div>
                          <div className="text-sm text-neutral-400">
                            {item.status}
                          </div>
                          <div className="text-sm text-neutral-400 line-clamp-2">
                            {item.description}
                          </div>
                        </Flex>
                      </Flex>
                    </div>
                  )}
                />
              </div>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default HotelRooms;
