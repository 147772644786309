import React, { FC, useEffect } from "react";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
import { useParams, useSearchParams } from "react-router-dom";
import ReactFacebookPixel from "library/react-facebook-pixel";

import { ToursListingWithId } from "domain/tours-listing.type";
import { PlaceWithId } from "domain/place.type";
import environment from "config/environment";
import useFetch from "hooks/useFetch";
import Loader from "shared/Loader/Loader";
import Flex from "shared/Flex/Flex";
import TourListingSection from "components/TourListingSection";
import ContactUsToPlan from "components/ContactUsToPlan";

export interface TourListingProps {
  className?: string;
}

export interface TourListingBodyProps extends TourListingProps {
  className?: string;
  toursListing?: ToursListingWithId;
}

const TourListing: FC<TourListingProps> = ({ ...props }) => {
  const { slug } = useParams();
  const [search] = useSearchParams();
  const listingId = search.get("id");

  const {
    isLoading,
    data: toursListings,
    reFetch: reFetchListings,
  } = useFetch<ToursListingWithId[]>(
    {
      url: listingId
        ? `${environment.apiUrl}/tours-listing/${listingId}?status=ACTIVE`
        : `${environment.apiUrl}/tours-listing?slug=${slug}&status=ACTIVE`,
    },
    1
  );

  useEffect(() => {
    if (listingId) {
      reFetchListings({
        url: `${environment.apiUrl}/tours-listing/${listingId}?status=ACTIVE`,
        reset: true,
      });
    }
  }, [listingId]);

  if (isLoading) {
    return <Loader />;
  }
  const toursListing = toursListings ? toursListings[0] : undefined;
  return <TourListingBody toursListing={toursListing} {...props} />;
};

export const TourListingForPlace: FC<TourListingProps> = ({ ...props }) => {
  const [search] = useSearchParams();
  const placeId = search.get("id");

  const {
    isLoading,
    data: places,
    reFetch: reFetchPlaces,
  } = useFetch<PlaceWithId[]>(
    {
      url: `${environment.apiUrl}/place/${placeId}`,
    },
    1,
    !placeId
  );
  useEffect(() => {
    if (placeId) {
      reFetchPlaces({
        url: `${environment.apiUrl}/place/${placeId}`,
        reset: true,
      });
    }
  }, [placeId]);

  if (isLoading) {
    return <Loader />;
  }

  const place = places ? places[0] : undefined;
  if (place) {
    const toursListing: ToursListingWithId = {
      id: place.id,
      slug: "",
      title: `${place.name} Tour Packages`,
      listingType: "PLACE",
      placeIds: [place.id],
      tourIds: [],
      coverPhotos: place.coverPhotos,
      marketingPhoto: place.marketingPhoto,
      status: "ACTIVE",
    };
    return <TourListingBody toursListing={toursListing} {...props} />;
  }

  return <TourListingBody {...props} />;
};

const TourListingBody: FC<TourListingBodyProps> = ({
  toursListing,
  className = "",
}) => {
  useEffect(() => {
    if (toursListing) {
      ReactFacebookPixel.trackCustom("HolidayPackagesListingViewed", {
        id: toursListing.id,
        name: toursListing.title,
      });
    }
  }, []);

  if (!toursListing) {
    return (
      <div className={`page-tour-package ${className}`}>
        <Flex align="center" justify="center" className="my-5 h-96 text-xl">
          <span>
            <FaceFrownIcon className="w-20 h-20 text-yellow-600" />
          </span>
          <span>The holiday package has been stopped recently</span>
          <span>
            Please contact us if you are looking for any travel plans.
          </span>
        </Flex>
      </div>
    );
  }

  return (
    <div className={`page-tour-listing ${className}`}>
      <Helmet>
        <title>
          {environment.appName} | {toursListing.title}
        </title>
      </Helmet>
      <TourListingSection toursListing={toursListing} />
      <ContactUsToPlan className="mt-5" />
      <div className="my-5" />
    </div>
  );
};

export default TourListing;
