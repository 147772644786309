import React from "react";

import { DestinationCardOutlined } from "components/DestinationCard";
import SectionHeading from "components/SectionHeading";
import useFetch from "hooks/useFetch";
import { PlaceWithId } from "domain/place.type";
import environment from "config/environment";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Flex from "shared/Flex/Flex";

export interface SectionAllDestinationsProps {
  headingCenter?: boolean;
  className?: string;
  gridClassName?: string;
}

const SectionAllDestinations: React.FC<SectionAllDestinationsProps> = ({
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  const { data = [] } = useFetch<PlaceWithId[]>({
    url: `${environment.apiUrl}/place?sortBy=noOfTours&sortOrder=DESC`,
  }, 12);
  return (
    <div className={`app-SectionAllDestinations relative ${className}`}>
      <SectionHeading
        desc="Explore a wide range of destinations with exclusive packages at unbeatable prices"
        isCenter={headingCenter}
      >
        All destinations
      </SectionHeading>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {data.map((item, i) => (
          <DestinationCardOutlined
            key={item.id}
            destination={item}
          />
        ))}
      </div>

      <Flex direction="row" justify="center" className="w-full mt-8">
        <ButtonSecondary href="/places" >
          show more destinations
        </ButtonSecondary>
      </Flex>
    </div>
  );
};

export default SectionAllDestinations;
