import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Select from "shared/Select/Select";
import { hotelTypes } from "utils/hotelUtils";

export interface HotelTypeProps {
  register: UseFormRegister<HotelWithId>
  error?: FieldError
  className?: string
}

const HotelType: FC<HotelTypeProps> = ({
  register,
  error,
  className
}) => {

  return (
    <div className={className}>
      <Label>Hotel type</Label>
      <Select className="mt-1" {...register("type", { required: true })}>
        <option value="">Select hotel type</option>
        {hotelTypes.map((hotelType) => (
          <option key={hotelType.key} value={hotelType.key}>
            {hotelType.value}
          </option>
        ))}
      </Select>
      {error && (
        <span className="text-sm text-red-500">Please enter hotel type</span>
      )}
    </div>
  );
};

export default HotelType;
