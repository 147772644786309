import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { HotelWithId } from "domain/hotel.type";
import { CustomError } from "domain/custom-error.type";
import { fetcher } from "hooks/useFetch";
import environment from "config/environment";
import Label from "shared/Label/Label";
import Flex from "shared/Flex/Flex";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HotelType from "components/HotelForm/Inputs/HotelType";
import HotelCode from "components/HotelForm/Inputs/HotelCode";
import HotelName from "components/HotelForm/Inputs/HotelName";
import HotelDescription from "components/HotelForm/Inputs/HotelDescription";
import HotelCheckOutTime from "components/HotelForm/Inputs/HotelCheckOutTime";
import HotelCheckInTime from "components/HotelForm/Inputs/HotelCheckInTime";
import HotelStarRating from "components/HotelForm/Inputs/HotelStarRating";
import HotelNoOfRooms from "components/HotelForm/Inputs/HotelNoOfRooms";
import HotelPrimeAmenities from "components/HotelForm/Inputs/HotelPrimeAmenities";
import HotelPlace from "components/HotelForm/Inputs/HotelPlace";
import HotelZipCode from "components/HotelForm/Inputs/HotelZipCode";
import HotelStreetAddress from "components/HotelForm/Inputs/HotelStreetAddress";
import HotelGooglePlaceId from "components/HotelForm/Inputs/HotelGooglePlaceId";
import HotelCoordinates from "components/HotelForm/Inputs/HotelCoordinates";
import HotelAmenities from "components/HotelForm/Inputs/HotelAmenities";
import HotelThumbnail from "components/HotelForm/Inputs/HoteThumbnail";
import HotelPhotos from "components/HotelForm/Inputs/HotelPhotos";
import HotelAgePolicy from "components/HotelForm/Inputs/HotelAgePolicy";
import HotelHeading from "../HotelHeading";

export interface HotelInformationProps {
  hotel?: HotelWithId;
  onUpdateHotel: (hotel: HotelWithId) => void;
}

const HotelInformation: FC<HotelInformationProps> = ({
  hotel,
  onUpdateHotel,
}) => {
  const [renderId, setRenderId] = useState(1);
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);
  const {
    control,
    watch,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm<HotelWithId>({
    defaultValues: hotel,
  });

  const place = watch("place", hotel?.place);
  const street = watch("address.street");
  const zip = watch("address.zip");
  const isDirty = Object.keys(dirtyFields).length;

  const onReset = () => {
    reset(hotel);
    setServerErrors([]);
    setRenderId((renderId) => renderId + 1);
  };

  const onSubmit = async (data: HotelWithId) => {
    setServerErrors([]);
    const path = `/hotel/${data?.id}`;
    const method = "PUT";
    try {
      const result = await fetcher({
        url: `${environment.apiUrl}${path}`,
        method,
        body: JSON.stringify(data),
      });
      if (result.errors) {
        setServerErrors(result.errors);
        toast.error("Failed to update hotel. Please check the errors.");
      } else {
        toast.success("Hotel updated successfully!");
        const updatedHotel = { ...data, ...result.hotel } as HotelWithId;
        onUpdateHotel(updatedHotel);
        reset(updatedHotel);
      }
    } catch (e) {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const scrollIntoView = (id: string) => {
    document
      .getElementById(id)
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <Flex className="w-full">
      <HotelHeading
        hotel={hotel}
        actionsComponent={
          <>
            <ButtonSecondary
              onClick={onReset}
              disabled={!isDirty}
              className="w-32"
            >
              Reset
            </ButtonSecondary>
            <ButtonPrimary
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty}
              loading={isSubmitting}
              className="w-32"
            >
              Update
            </ButtonPrimary>
          </>
        }
        errorsComponent={
          <div className={serverErrors.length < 1 ? "hidden" : ""}>
            <Flex direction="col" className="bg-red-100 w-full px-5 py-2">
              {serverErrors.map((error) => (
                <li key={error.code} className="text-red-500 text-sm">
                  {error.userMessage}
                </li>
              ))}
            </Flex>
          </div>
        }
      />

      <Flex direction="row" className="w-full">
        <Flex
          key={renderId}
          direction="col"
          className="p-5 w-full md:max-w-screen-sm lg:max-w-screen-lg space-y-8"
        >
          <Flex id="basic-details" direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">Basic Details</h2>

            <div className="w-full grid grid-cols-2 gap-6 bg-white p-5">
              <HotelType register={register} error={errors.type} />
              <HotelCode register={register} error={errors.code} />
              <HotelName
                register={register}
                error={errors.name}
                className="col-span-2"
              />
              <HotelDescription
                register={register}
                error={errors.description}
                className="col-span-2"
              />

              <HotelStarRating
                register={register}
                error={errors.details?.starRating}
              />

              <HotelNoOfRooms
                register={register}
                error={errors.details?.noOfRooms}
              />

              <HotelCheckOutTime
                register={register}
                error={errors.details?.checkOutTime}
              />

              <HotelCheckInTime
                register={register}
                error={errors.details?.checkInTime}
              />
            </div>
          </Flex>

          <Flex id="hotel-location" direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">Hotel Location</h2>

            <div className="w-full grid grid-cols-2 gap-6 bg-white p-5">
              <HotelPlace
                register={register}
                watch={watch}
                error={errors.placeId}
                onChange={(place) => {
                  setValue("place", place);
                }}
              />

              <HotelZipCode register={register} error={errors.address?.zip} />

              <HotelStreetAddress
                register={register}
                error={errors.address?.street}
                className="col-span-2"
              />

              <div className="col-span-2">
                <Label>Detailed address</Label>
                <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
                  {[street, place?.name, place?.country.name, zip]
                    .filter(Boolean)
                    .join(", ")}
                </span>
              </div>

              <HotelGooglePlaceId
                register={register}
                error={errors.address?.googlePlaceId}
              />

              <HotelCoordinates
                register={register}
                error={
                  errors.address?.coordinates?.[0] ||
                  errors.address?.coordinates?.[1]
                }
              />
            </div>
          </Flex>

          <Flex id="hotel-photos" direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">Hotel Photos</h2>

            <div className="w-full grid grid-cols-2 gap-6 bg-white p-5">
              <HotelThumbnail
                control={control}
                error={errors.thumbnail}
                defaultValue={hotel?.thumbnail}
                className="col-span-2"
              />
              <HotelPhotos
                control={control}
                error={errors.photos}
                defaultValue={hotel?.photos}
                className="col-span-2"
              />
            </div>
          </Flex>

          <Flex id="hotel-age-policy" direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">Age Policy</h2>

            <div className="w-full grid grid-cols-3 gap-10 bg-white p-5">
              <HotelAgePolicy
                field="infant"
                register={register}
                error={
                  errors.agePolicy?.infant?.min || errors.agePolicy?.infant?.max
                }
              />
              <HotelAgePolicy
                field="child"
                register={register}
                error={
                  errors.agePolicy?.child?.min || errors.agePolicy?.child?.max
                }
              />
              <HotelAgePolicy
                field="adult"
                register={register}
                error={
                  errors.agePolicy?.adult?.min || errors.agePolicy?.adult?.max
                }
              />
            </div>
          </Flex>

          <Flex id="hotel-amenities" direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">Prime Amenities</h2>

            <div className="w-full grid grid-cols-2 gap-6 bg-white p-5">
              <HotelPrimeAmenities
                control={control}
                className="col-span-2 space-y-2"
              />
            </div>
          </Flex>

          <Flex direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">Other Amenities </h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 " />
            <HotelAmenities
              register={register}
              className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 bg-white p-5"
            />
          </Flex>
        </Flex>
        <Flex justify="center" className="sticky top-32 w-48">
          <ul className="space-y-3 underline underline-offset-2 decoration-thin text-primary-500">
            {[
              { text: "Basic Details", id: "basic-details" },
              { text: "Location", id: "hotel-location" },
              { text: "Photos", id: "hotel-photos" },
              { text: "Age Policy", id: "hotel-age-policy" },
              { text: "Amenities", id: "hotel-amenities" },
            ].map((item) => (
              <li
                key={item.id}
                className="cursor-pointer"
                onClick={() => scrollIntoView(item.id)}
              >
                {item.text}
              </li>
            ))}
          </ul>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HotelInformation;
