import React, { FC } from "react";
import { Helmet } from "react-helmet";

import BgGlassmorphism from "components/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection";
import rightImg from "images/about-hero-right.png";

import SectionHero from "./SectionHero";
import SectionStatistic from "./SectionStatistic";
import SectionContent from "./SectionContent";
import environment from "config/environment";

export interface AboutUsProps {
  className?: string;
}

const AboutUs: FC<AboutUsProps> = ({ className = "" }) => {
  return (
    <div
      className={`app-AboutUs overflow-hidden relative ${className}`}
      data-app-id="AboutUs"
    >
      <Helmet>
        <title>About us | {environment.appName}</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="Nurtured from the seed of a single great idea to empower the traveler Diskounto Holidays is a pioneer in India's online travel industry. Founded in the year 2023 by Akash Kumar Sinha and Yasoda Sinha, Diskounto Holidays Private Limited came with an idea of Diskounto Holidays to life to empower Indian travelers with instant bookings and comprehensive choices."
        />

        <SectionStatistic />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionContent />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
