import React from "react";
import BgGlassmorphism from "components/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionHowItWork from "./SectionHowItWork";
import SectionAllDestinations from "./SectionAllDestinations";
import SectionBecomeMember from "./SectionBecomeMember";
import SectionClientSay from "./SectionClientSay";
import SectionPopularDestinations from "./SectionPopularDestinations";
import { useAuth } from "context/AuthProvider";

function Home() {
  const { isAuthenticated } = useAuth();
  return (
    <div className="app-PageHome relative overflow-hidden">
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-0 lg:space-y-28 lg:mb-28">
        <SectionHero className="pt-3 lg:pt-4 lg:pb-16" />

        <SectionPopularDestinations scope="domestic" />

        {/* <SectionPopularDestinations scope='international'/> */}

        <div className="relative py-16">
          <BackgroundSection className="bg-primary-100 dark:bg-black dark:bg-opacity-20 " />
          <SectionHowItWork />
        </div>

        <SectionAllDestinations />

        {!isAuthenticated && <SectionBecomeMember />}
        
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
      </div>
    </div>
  );
}

export default Home;
