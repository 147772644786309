import React from "react";
import Modal from "shared/Modal/Modal";
import Button from "shared/Button/Button";
import Badge from "shared/Badge/Badge";

import { UserWithId } from "domain/user.type";

interface ViewUserProps {
  user: UserWithId;
}

const ViewUser = ({ user }: ViewUserProps) => {
  return (
    <Modal
      modalTitle={`${user.firstName} ${user.lastName}`}
      className="w-1/2"
      renderTrigger={({ openModal }) => (
        <Button sizeClass="px-5 py-1" onClick={openModal}>
          View
        </Button>
      )}
      renderContent={({ closeModal }) => {
        return (
          <main className="container mt-6 mb-6">
            <div className="max-w-4xl mx-auto">
              <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                {/* ------------------------ */}
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold">User details</h3>
                  <div className="flex flex-col space-y-4">
                    <div className="flex text-neutral-600 dark:text-neutral-300">
                      <span className="flex-1">Name</span>
                      <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                        {`${user.firstName} ${user.lastName}`}
                      </span>
                    </div>
                    <div className="flex text-neutral-600 dark:text-neutral-300">
                      <span className="flex-1">Email</span>
                      <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                        {user.email}
                      </span>
                    </div>

                    <div className="flex text-neutral-600 dark:text-neutral-300">
                      <span className="flex-1">Mobile</span>
                      <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                        {user.mobile}
                      </span>
                    </div>

                    <div className="flex text-neutral-600 dark:text-neutral-300">
                      <span className="flex-1">Status</span>
                      <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                        {user.roles.map((role) => (
                          <Badge name={role} color="red"/>
                        ))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        );
      }}
    />
  );
};

export default ViewUser;
