import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

import environment from "config/environment";
import { TourWithId } from "domain/tour.type";
import { CustomError } from "domain/custom-error.type";
import { fetcher } from "hooks/useFetch";
import TourDetails from "components/TourDetails";
import CommonLayout from "./CommonLayout";

export interface TourPreviewProps {
  tour?: TourWithId;
  step: number;
  onClickPrev: () => void;
  onSuccess: (data: TourWithId) => void;
}

const TourPreview: FC<TourPreviewProps> = ({
  tour,
  step,
  onClickPrev = () => {},
  onSuccess = () => {},
}) => {
  const { handleSubmit } = useForm<TourWithId>();
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);

  const onSubmit = async () => {
    const path = tour?.id ? `/tour/${tour?.id}` : "/tour";
    const method = tour?.id ? "PUT" : "POST";
    const result = await fetcher({
      url: `${environment.apiUrl}${path}`,
      method,
      body: JSON.stringify(tour),
    });
    if (result.errors) {
      setServerErrors(result.errors);
    } else {
      const tour = await fetcher({
        url: `${environment.apiUrl}/tour/${result.tour.id}`,
      });
      onSuccess({ ...result.tour, ...tour[0] } as TourWithId);
    }
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <div>
          <div className="max-w-full">
            <TourDetails
              isPreview={true}
              className="mt-8"
              tour={{ id: "new", ...tour } as TourWithId}
            />
          </div>
        </div>
        <div className="col-span-2">
          {serverErrors.map((error) => (
            <p key={error.code} className="text-red-500">
              {error.userMessage}
            </p>
          ))}
        </div>
      </>
    </CommonLayout>
  );
};

export default TourPreview;
