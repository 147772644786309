import { Helmet } from "react-helmet";
import HeaderNav from "components/HeaderNav";
import environment from "config/environment";

const Header = () => {
  return (
    <>
      <Helmet>
        <title>{environment.appName}</title>
      </Helmet>
      <div
        className={`app-Header sticky top-0 w-full left-0 right-0 z-40 app-header-bg shadow-sm dark:border-b dark:border-neutral-700`}
      >
        <HeaderNav />
      </div>
    </>
  );
};

export default Header;
