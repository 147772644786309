import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { pick } from "lodash";
import { SupplierWithId } from "domain/supplier.type";
import CommonLayout from "./CommonLayout";
import SupplierPan from "../Inputs/SupplierPan";
import SupplierGstn from "../Inputs/SupplierGstn";
import SupplierBankAccountHolder from "../Inputs/SupplierBankAccountHolder";
import SupplierBankAccountNumber from "../Inputs/SupplierBankAccountNumber";
import SupplierBankAccountIfsc from "../Inputs/SupplierBankAccountIfsc";

export interface SupplierDetailsFormProps {
  supplier?: SupplierWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<SupplierWithId>) => void;
}

const SupplierDetailsForm: FC<SupplierDetailsFormProps> = ({
  supplier,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SupplierWithId>({
    defaultValues: pick<SupplierWithId>(supplier, [
      "pan",
      "gstn",
      "bankAccount",
    ]),
  });

  const onSubmit = (data: SupplierWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Supplier Details</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-2 gap-6">
          <SupplierPan register={register} error={errors.pan} />
          <SupplierGstn register={register} error={errors.gstn} />
          <SupplierBankAccountHolder
            register={register}
            error={errors.bankAccount?.accountHolder}
            className="col-span-2"
          />
          <SupplierBankAccountNumber
            register={register}
            error={errors.bankAccount?.accountNo}
          />
          <SupplierBankAccountIfsc
            register={register}
            error={errors.bankAccount?.ifsc}
          />
        </div>
      </>
    </CommonLayout>
  );
};

export default SupplierDetailsForm;
