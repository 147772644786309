import React, { FC } from "react";

import { PlaceWithId } from "domain/place.type";
import environment from "config/environment";
import Image from "shared/LazyImage/LazyImage";
import { useNavigate } from "react-router-dom";
import { generatePathWithQuery } from "utils/url";

export interface DestinationCardProps {
  className?: string;
  size?: "small" | "normal" | "large";
  destination: PlaceWithId;
}

const DestinationCard: FC<DestinationCardProps> = ({
  size = "small",
  className = "",
  destination,
}) => {
  const navigate = useNavigate();
  const { id: placeId, slug, name, thumbnail } = destination;

  let cardSizeClasses = "aspect-w-4 aspect-h-3 sm:aspect-h-3";
  if (size === "normal") {
    cardSizeClasses = "aspect-w-5 aspect-h-4 sm:aspect-h-6";
  }
  if (size === "large") {
    cardSizeClasses = "aspect-w-5 aspect-h-4 sm:aspect-h-7";
  }

  return (
    <div
    className={`app-DestinationCard flex flex-col justify-center cursor-pointer ${className}`}
    data-app-id="DestinationCard"
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        onClick={() =>
          navigate(generatePathWithQuery("/place/:slug/holiday-packages?id=:placeId", { slug, placeId }))
        }
      >
        <div
           className={`flex-shrink-0 relative w-full rounded-2xl overflow-hidden group`}
        >
          <Image
            src={`${environment.assetsUrl}/${thumbnail.path}`}
            containerClassName={`${cardSizeClasses} rounded-2xl hover:opacity-100 inset-0`}
          />
          <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
        </div>
        <div className="mt-4 px-2 truncate text-center">
          <h2
            className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
          >
            {name}
          </h2>
        </div>
      </a>
    </div>
  );
};

export default DestinationCard;
