import React from "react";
import { TourWithId } from "domain/tour.type";
import Modal from "shared/Modal/Modal";
import Button from "shared/Button/Button";
import TourForm from "./TourForm";

interface EditTourProps {
  tour: TourWithId;
  onEditTour: (tour: TourWithId) => void;
}

const EditTour = ({ tour, onEditTour }: EditTourProps) => {
  return (
    <Modal
      modalTitle={`Edit ${tour.title}`}
      fullScreen
      renderTrigger={({ openModal }) => (
        <Button sizeClass="px-5 py-1" onClick={openModal}>
          Edit
        </Button>
      )}
      renderContent={({ closeModal }) => {
        return (
          <TourForm
            tour={tour}
            onSuccess={(tour) => {
              onEditTour(tour);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

export default EditTour;
