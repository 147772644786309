import React, { FC } from "react";

export interface LabelProps {
  show?: boolean;
  required?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Label: FC<LabelProps> = ({
  show = true,
  className = "",
  children,
  required = false,
}) => {
  if(!show) {
    return <></>
  }
  return (
    <label
      className={`app-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 ${className}`}
      data-app-id="Label"
    >
      {children}
      {required && <span className="text-red-500 ml-[1px]">*</span>}
    </label>
  );
};

export default Label;
