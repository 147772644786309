import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { SupplierWithId } from "domain/supplier.type";
import { CustomError } from "domain/custom-error.type";
import environment from "config/environment";
import { fetcher } from "hooks/useFetch";
import Flex from "shared/Flex/Flex";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SupplierHeading from "../SupplierHeading";
import SupplierName from "components/SupplierForm/Inputs/SupplierName";
import SupplierTradeName from "components/SupplierForm/Inputs/SupplierTradeName";
import SupplierAddress from "components/SupplierForm/Inputs/SupplierAddress";
import SupplierMobile from "components/SupplierForm/Inputs/SupplierMobile";
import SupplierEmail from "components/SupplierForm/Inputs/SupplierEmail";
import SupplierPan from "components/SupplierForm/Inputs/SupplierPan";
import SupplierGstn from "components/SupplierForm/Inputs/SupplierGstn";
import SupplierBankAccountHolder from "components/SupplierForm/Inputs/SupplierBankAccountHolder";
import SupplierBankAccountNumber from "components/SupplierForm/Inputs/SupplierBankAccountNumber";
import SupplierBankAccountIfsc from "components/SupplierForm/Inputs/SupplierBankAccountIfsc";
import SupplierMarginB2B from "components/SupplierForm/Inputs/SupplierMarginB2B";
import SupplierMarginB2C from "components/SupplierForm/Inputs/SupplierMarginB2C";
import SupplierAdvancePaymentValue from "components/SupplierForm/Inputs/SupplierAdvancePaymentValue";
import SupplierBalanceDueDays from "components/SupplierForm/Inputs/SupplierBalanceDueDays";
import SupplierInternalNotes from "components/SupplierForm/Inputs/SupplierInternalNotes";
import SupplierCountry from "components/SupplierForm/Inputs/SupplierCountry";

export interface SupplierInformationProps {
  supplier?: SupplierWithId;
  onUpdateSupplier: (supplier: SupplierWithId) => void;
}

const SupplierInformation: FC<SupplierInformationProps> = ({
  supplier,
  onUpdateSupplier,
}) => {
  const [renderId, setRenderId] = useState(1);
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm<SupplierWithId>({
    defaultValues: supplier,
  });

  const isDirty = Object.keys(dirtyFields).length;

  const onReset = () => {
    reset(supplier);
    setServerErrors([]);
    setRenderId((renderId) => renderId + 1);
  };

  const onSubmit = async (data: SupplierWithId) => {
    setServerErrors([]);
    const path = `/supplier/${data?.id}`;
    const method = "PUT";
    try {
      const result = await fetcher({
        url: `${environment.apiUrl}${path}`,
        method,
        body: JSON.stringify(data),
      });
      if (result.errors) {
        setServerErrors(result.errors);
        toast.error("Failed to update supplier. Please check the errors.");
      } else {
        toast.success("Supplier updated successfully!");
        const updatedSupplier = {
          ...data,
          ...result.supplier,
        } as SupplierWithId;
        onUpdateSupplier(updatedSupplier);
        reset(updatedSupplier);
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const scrollIntoView = (id: string) => {
    document
      .getElementById(id)
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <Flex className="w-full">
      <SupplierHeading
        supplier={supplier}
        actionsComponent={
          <>
            <ButtonSecondary
              onClick={onReset}
              disabled={!isDirty}
              className="w-32"
            >
              Reset
            </ButtonSecondary>
            <ButtonPrimary
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty}
              loading={isSubmitting}
              className="w-32"
            >
              Update
            </ButtonPrimary>
          </>
        }
        errorsComponent={
          <div className={serverErrors.length < 1 ? "hidden" : ""}>
            <Flex direction="col" className="bg-red-100 w-full px-5 py-2">
              {serverErrors.map((error) => (
                <li key={error.code} className="text-red-500 text-sm">
                  {error.userMessage}
                </li>
              ))}
            </Flex>
          </div>
        }
      />

      <Flex direction="row" className="w-full">
        <Flex
          key={renderId}
          direction="col"
          className="p-5 w-full md:max-w-screen-sm lg:max-w-screen-lg space-y-8"
        >
          <Flex id="supplier-basic-details" direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">Basic Details</h2>

            <div className="w-full grid grid-cols-2 gap-6 bg-white p-5">
              <div className="col-span-1">Name</div>
              <div className="col-span-1">{supplier?.name}</div>
              <SupplierName register={register} error={errors.name} />
              <SupplierCountry
                register={register}
                error={errors.country?.code}
              />

              <SupplierAddress
                register={register}
                error={errors.address}
                className="col-span-2"
              />
              <SupplierMobile register={register} error={errors.mobile} />
              <SupplierEmail register={register} error={errors.email} />
            </div>
          </Flex>

          <Flex id="supplier-licenses" direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">License</h2>

            <div className="w-full grid grid-cols-2 gap-6 bg-white p-5">
              <SupplierTradeName
                register={register}
                error={errors.tradeName}
                className="col-span-2"
              />
              <SupplierPan register={register} error={errors.pan} />
              <SupplierGstn register={register} error={errors.gstn} />
            </div>
          </Flex>

          <Flex id="supplier-margin" direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">Margin</h2>

            <div className="w-full grid grid-cols-2 gap-6 bg-white p-5">
              <SupplierMarginB2B
                register={register}
                error={errors.b2bMarginPercent}
              />
              <SupplierMarginB2C
                register={register}
                error={errors.b2cMarginPercent}
              />
            </div>
          </Flex>

          <Flex id="supplier-bank-details" direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">Bank Account</h2>

            <div className="w-full grid grid-cols-2 gap-6 bg-white p-5">
              <SupplierBankAccountHolder
                register={register}
                error={errors.bankAccount?.accountHolder}
                className="col-span-2"
              />
              <SupplierBankAccountNumber
                register={register}
                error={errors.bankAccount?.accountNo}
              />
              <SupplierBankAccountIfsc
                register={register}
                error={errors.bankAccount?.ifsc}
              />
            </div>
          </Flex>

          <Flex id="supplier-payment-policy" direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">Payment Terms</h2>

            <div className="w-full grid grid-cols-2 gap-6 bg-white p-5">
              <SupplierAdvancePaymentValue
                register={register}
                error={errors.advancePercent}
              />
              <SupplierBalanceDueDays
                register={register}
                error={errors.balanceDueDays}
              />
            </div>
          </Flex>

          <Flex id="supplier-notes" direction="col" className="w-full">
            <h2 className="text-sm font-semibold mb-3">Notes</h2>

            <div className="w-full grid grid-cols-2 gap-6 bg-white p-5">
              <SupplierInternalNotes
                register={register}
                error={errors.internalNotes}
                className="col-span-2"
              />
            </div>
          </Flex>
        </Flex>
        <Flex justify="center" className="sticky top-32 w-48">
          <ul className="space-y-3 underline underline-offset-2 decoration-thin text-primary-500">
            {[
              { text: "Basic Details", id: "supplier-basic-details" },
              { text: "License", id: "supplier-licenses" },
              { text: "Margin", id: "supplier-margin" },
              { text: "Bank Account", id: "supplier-bank-details" },
              { text: "Payment Terms", id: "supplier-payment-policy" },
              { text: "Notes", id: "supplier-notes" },
            ].map((item) => (
              <li
                key={item.id}
                className="cursor-pointer"
                onClick={() => scrollIntoView(item.id)}
              >
                {item.text}
              </li>
            ))}
          </ul>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SupplierInformation;
