import React, { FC, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
import ReactFacebookPixel from "library/react-facebook-pixel";

import environment from "config/environment";
import useFetch from "hooks/useFetch";
import Loader from "shared/Loader/Loader";
import { TourWithId } from "domain/tour.type";
import TourDetails from "components/TourDetails";
import Flex from "shared/Flex/Flex";

export interface TourItineraryProps {
  className?: string;
}

interface TourItineraryBodyProps extends TourItineraryProps {
  tour: TourWithId;
}

const TourItinerary: FC<TourItineraryProps> = ({ className = "" }) => {
  const { slug } = useParams();
  const [search] = useSearchParams();
  const tourId = search.get("id");

  const { isLoading, data: tours } = useFetch<TourWithId[]>({
    url: tourId
      ? `${environment.apiUrl}/tour/${tourId}?status=ACTIVE`
      : `${environment.apiUrl}/tour?slug=${slug}&status=ACTIVE`,
  });

  if (isLoading) {
    return <Loader />;
  }
  if (!tours?.length) {
    return (
      <div className={`page-holiday-package ${className}`}>
        <Flex align="center" justify="center" className="my-5 h-96 text-xl">
          <span>
            <FaceFrownIcon className="w-20 h-20 text-yellow-600" />
          </span>
          <span>This pakage has been stopped recently.</span>
          <span>
            Please contact us if you are looking for any travel plans.
          </span>
        </Flex>
      </div>
    );
  }

  return <TourItineraryBody tour={tours[0]} className={className} />;
};

const TourItineraryBody: FC<TourItineraryBodyProps> = ({
  tour,
  className = "",
}) => {
  useEffect(() => {
    ReactFacebookPixel.trackCustom("HolidayPackageItineraryViewed", {
      id: tour.id,
      name: tour.title,
    });
  }, []);

  return (
    <div className={`page-holiday-package ${className}`}>
      <Helmet>
        <title>
          {environment.appName} | {tour.title}
        </title>
      </Helmet>
      <TourDetails tour={tour} />
      <div className="my-5" />
    </div>
  );
};

export default TourItinerary;
