import React from "react";

import { PlaceWithId } from "domain/place.type";
import { DestinationCardOutlined } from "components/DestinationCard";
import SectionHeading from "components/SectionHeading";
import useFetch from "hooks/useFetch";
import environment from "config/environment";

export interface DestinationsProps {
  className?: string;
}

const Destinations: React.FC<DestinationsProps> = ({
  className = "",
}) => {
  const { data = [] } = useFetch<PlaceWithId[]>({
    url: `${environment.apiUrl}/place?sortBy=noOfTours&sortOrder=DESC`,
  }, 1000);
  return (
    <div className={`app-Destinations container relative py-8 ${className}`}>
      <SectionHeading
        desc="Explore a wide range of destinations with exclusive packages at unbeatable prices"
        isCenter={true}
      >
        Choose your destination
      </SectionHeading>
      <div className={`grid  "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 sm:gap-6 md:gap-8`}>
        {data.map((item, i) => (
          <DestinationCardOutlined key={item.id} destination={item} showHotels={true} />
        ))}
      </div>
    </div>
  );
};

export default Destinations;
