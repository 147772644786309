import React from "react";
import { TourWithId } from "domain/tour.type";
import Modal from "shared/Modal/Modal";
import Button from "shared/Button/Button";
import TourForm from "./TourForm";

interface CloneTourProps {
  tour: TourWithId;
  onAddTour: (tour: TourWithId) => void;
}

const CloneTour = ({ tour, onAddTour }: CloneTourProps) => {
  return (
    <Modal
      modalTitle={`Clone ${tour.title}`}
      fullScreen
      renderTrigger={({ openModal }) => (
        <Button sizeClass="px-5 py-1" onClick={openModal}>
          Clone
        </Button>
      )}
      renderContent={({ closeModal }) => {
        return (
          <TourForm
            tour={{ ...tour, id: "" }}
            onSuccess={(tour) => {
              onAddTour(tour);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

export default CloneTour;
