import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { pick } from "lodash";
import { HotelWithId } from "domain/hotel.type";
import CommonLayout from "./CommonLayout";
import HotelAmenities from "../Inputs/HotelAmenities";

export interface HotelAmenitiesFormProps {
  hotel?: HotelWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<HotelWithId>) => void;
}

const HotelAmenitiesForm: FC<HotelAmenitiesFormProps> = ({
  hotel,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const { register, handleSubmit } = useForm<HotelWithId>({
    defaultValues: pick<HotelWithId>(hotel, ["amenities"]),
  });

  const onSubmit = (data: HotelWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Hotel Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Many customers have searched for accommodation based on amenities
            criteria
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <HotelAmenities
          register={register}
          className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5"
        />
      </>
    </CommonLayout>
  );
};

export default HotelAmenitiesForm;
