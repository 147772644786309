import React, { FC } from "react";
import { Link } from "react-router-dom";
import ReactFacebookPixel from "library/react-facebook-pixel";

import Badge, { BadgeProps } from "shared/Badge/Badge";
import { TourWithId } from "domain/tour.type";
import environment from "config/environment";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import LazyImage from "shared/LazyImage/LazyImage";
import { handlePlural } from "utils/string";
import { getTourNoOfNights } from "utils/tourUtils";
import TourRequestDetails from "components/TourDetails/TourRequestDetails";
import { generatePathWithQuery } from "utils/url";

export interface TourPackageCardProps {
  className?: string;
  tour: TourWithId;
}

const TourPackageCard: FC<TourPackageCardProps> = ({
  className = "",
  tour,
}) => {
  return (
    <div
      className={`app-TourPackageCard group relative bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-800 rounded-3xl overflow-hidden drop-shadow-xl hover:shadow-xl transition-shadow ${className}`}
    >
      {/* <Link to={`/holiday-package/${tour.slug}`} className="absolute inset-0" /> */}
      <div className="h-full w-full flex flex-col lg:flex-row  items-center justify-between">
        <div className="h-full w-full flex flex-col sm:flex-row sm:items-center pl-0 lg:pl-4">
          <TourPackageGallery tour={tour} />
          <TourPackageContent tour={tour} />
        </div>
        <TourPackagePriceAndEnquiry tour={tour} />
      </div>
    </div>
  );
};
const TourPackageGallery = ({ tour }: { tour: TourWithId }) => {
  const photo = tour.thumbnail || tour.marketingPhoto;
  return (
    <div className="w-full sm:w-64 p-0 sm:p-2 lg:p-0">
      <LazyImage
        src={`${environment.assetsUrl}/${photo?.path}`}
        meta={photo?.meta}
        containerClassName="aspect-h-3 aspect-w-4 rounded-2xl"
      />
    </div>
  );
};

const TourPackageContent = ({ tour }: { tour: TourWithId }) => {
  const tripIdealFor = [
    { key: "couple", name: "Couple", icon: "las la-share-alt", color: "red" },
    { key: "friends", name: "Friends", icon: "las la-users", color: "blue" },
    {
      key: "family",
      name: "Family",
      icon: "las la-user-friends",
      color: "green",
    },
  ] as {
    key: keyof TourWithId["idealFor"];
    name: string;
    icon: string;
    color: BadgeProps["color"];
  }[];
  const tripComponents = [
    { key: "hotels", name: "Hotels", icon: "las la-bed" },
    { key: "bus", name: "Bus", icon: "las la-bus" },
    { key: "trains", name: "Trains", icon: "las la-train" },
    { key: "flights", name: "Flights", icon: "las la-plane" },
    { key: "transfers", name: "Transfers", icon: "las la-car" },
    { key: "sightseeing", name: "Sightseeing", icon: "las la-binoculars" },
  ] as {
    key: keyof TourWithId["components"];
    name: string;
    icon: string;
  }[];

  const noOfNights = getTourNoOfNights(tour);
  const nights = handlePlural(noOfNights, "Night", "s");
  const days = handlePlural(noOfNights + 1, "Day", "s");

  return (
    <div className="flex-1 p-3 sm:pr-6 flex flex-col items-start">
      <div className="space-y-1 w-full">
        {Object.values(tour.idealFor).includes(true) && (
          <div className="inline-flex space-x-3">
            {tripIdealFor.map((idealFor) => {
              if (!tour.idealFor[idealFor.key]) return null;
              return (
                <span key={idealFor.key}>
                  <Badge
                    name={
                      <div className="flex items-center">
                        <i className={`text-sm ${idealFor.icon}`}></i>
                        <span className="ml-1">{idealFor.name}</span>
                      </div>
                    }
                    color={idealFor.color}
                  />
                </span>
              );
            })}
          </div>
        )}

        <div className="flex items-center space-x-2">
          {/* {isAds && <Badge name="ADS" color="green" />} */}
          <h2 className="text-lg font-medium capitalize">
            <span className="line-clamp-2">{tour.title}</span>
          </h2>
        </div>

        <div className="flex flex-row flex-wrap items-center">
          <span className="text-xs font-thin">
            {nights} / {days}
          </span>
          {tour.placesWithOptions.length > 0 && <span className="mx-2">•</span>}
          <span className="text-xs font-thin">
            {tour.placesWithOptions
              .map((item) => `${item.place?.name}(${item.noOfNights}N)`)
              .join(" → ")}
          </span>
        </div>

        <div className="pt-2 sm:pt-4">
          <span className="text-xs font-semibold ">Inclusions</span>
          <div className="flex flex-row space-x-5">
            {tripComponents.map((component) => {
              if (!tour.components[component.key]) return null;
              return (
                <div
                  key={component.key}
                  className={`flex items-center space-x-2`}
                >
                  <span className="inline-block">
                    <i className={component.icon}></i>
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400">
                    {component.name}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const TourPackagePriceAndEnquiry = ({ tour }: { tour: TourWithId }) => {
  return (
    <div className="flex w-full lg:w-96 h-full py-4 sm:py-2 lg:py-8 px-0 sm:px-6 lg:px-0 flex-col sm:flex-row lg:flex-col justify-center sm:justify-between lg:justify-center items-center bg-primary-100 rounded-b-3xl lg:rounded-3xl">
      <div className="flex flex-col justify-center items-center sm:items-start lg:items-center">
        <span className="text-xs font-thin text-green-600">PRICE STARTS</span>
        <span className="text-xl font-semibold">
          <s
            className={
              tour.maxPricePerPax === tour.pricePerPax ? "hidden" : "mr-3"
            }
          >
            ₹ {tour.maxPricePerPax}
          </s>
          <span>₹ {tour.pricePerPax}</span>
          <span className="font-thin text-xs"> + GST</span>
        </span>
        <span className="text-xs font-thin">per person on twin sharing</span>
      </div>
      <div className="flex flex-col justify-center items-center">
        <Modal
          modalTitle={tour.title}
          className="w-screen sm:w-96"
          renderTrigger={({ openModal }) => (
            <ButtonPrimary onClick={openModal} className="mt-2">
              ENQUIRE NOW
            </ButtonPrimary>
          )}
          renderContent={() => {
            return <TourRequestDetails tour={tour} />;
          }}
        />
        <span className="text-xs font-thin mt-2">- or -</span>
        <Link
          to={generatePathWithQuery(`/holiday-package/:slug?id=:tourId`, {
            slug: tour.slug,
            tourId: tour.id,
          })}
          className="text-xs font-thin underline mb-2"
          onClick={() => {
            ReactFacebookPixel.trackCustom("ViewItineraryClicked", {
              id: tour.id,
              name: tour.title,
            });
          }}
        >
          view itinerary
        </Link>
      </div>
    </div>
  );
};

export default TourPackageCard;
