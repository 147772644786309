import { FC } from "react";
import { useForm } from "react-hook-form";
import { get, pick } from "lodash";
import { TrashIcon } from "@heroicons/react/24/outline";

import { ToursListingWithId } from "domain/tours-listing.type";
import { PlaceWithId } from "domain/place.type";
import { TourWithId } from "domain/tour.type";
import environment from "config/environment";
import useFetch from "hooks/useFetch";
import Flex from "shared/Flex/Flex";
import Select from "shared/Select/Select";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { getTourNoOfNights } from "utils/tourUtils";

export interface ListingPlacesOrToursProps {
  toursListing?: ToursListingWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<ToursListingWithId>) => void;
}

const ListingPlacesOrTours: FC<ListingPlacesOrToursProps> = ({
  toursListing,
  ...props
}) => {
  if (toursListing?.listingType === "PLACE") {
    return <ListingPlaces toursListing={toursListing} {...props} />;
  }
  if (toursListing?.listingType === "TOUR") {
    return <ListingTours toursListing={toursListing} {...props} />;
  }
  return <>Please select a listing type to proceed</>;
};

const ListingPlaces: FC<ListingPlacesOrToursProps> = ({
  toursListing,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const placeUrl = `${environment.apiUrl}/place`;
  const { isLoading: isPlacesLoading, data: places = [] } = useFetch<
    PlaceWithId[]
  >({ url: placeUrl }, 1000);

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ToursListingWithId>({
    defaultValues: pick<ToursListingWithId>(toursListing, ["placeIds"]),
  });

  const placeIds = watch("placeIds");

  const onSubmit = (data: ToursListingWithId) => {
    console.log({ data });
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <div className="grid grid-cols-1 gap-3">
          <div>
            <h2 className="text-2xl font-semibold">Places to include</h2>
          </div>
          {placeIds.map((_, index) => {
            return (
              <div key={index}>
                <Flex direction="row" align="center">
                  <Select
                    className="mt-1"
                    isLoading={isPlacesLoading}
                    {...register(`placeIds.${index}`, { required: true })}
                  >
                    <option value="">Select Place</option>
                    {places.map((place) => {
                      return (
                        <option key={place.id} value={place.id}>
                          {[place.name, place.country.name].join(", ")}
                        </option>
                      );
                    })}
                  </Select>
                  <TrashIcon
                    className="ml-2 w-5 h-5 hover:text-red-800 cursor-pointer"
                    onClick={() => {
                      const newplaceIds = [...placeIds];
                      newplaceIds.splice(index, 1);
                      setValue("placeIds", newplaceIds);
                    }}
                  />
                </Flex>
                {get(errors, `placeIds.${index}`) && (
                  <span className="text-sm text-red-500">
                    Please select the place
                  </span>
                )}
              </div>
            );
          })}
          <ButtonSecondary
            className="mb-6 font-thin text-sm border-dotted border-neutral-400 hover:border-primary-400 rounded-2xl dark:border-neutral-700"
            onClick={() => setValue("placeIds", [...placeIds, ""])}
          >
            + Add more place
          </ButtonSecondary>
        </div>
      </>
    </CommonLayout>
  );
};

const ListingTours: FC<ListingPlacesOrToursProps> = ({
  toursListing,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const tourUrl = `${environment.apiUrl}/tour`;
  const { isLoading: isToursLoading, data: tours = [] } = useFetch<
    TourWithId[]
  >({ url: tourUrl }, 1000);

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ToursListingWithId>({
    defaultValues: pick<ToursListingWithId>(toursListing, ["tourIds"]),
  });

  const tourIds = watch("tourIds");

  const onSubmit = (data: ToursListingWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <div className="grid grid-cols-1 gap-3">
          <div>
            <h2 className="text-2xl font-semibold">Tours to include</h2>
          </div>
          {tourIds.map((_, index) => {
            return (
              <div key={index}>
                <Flex direction="row" align="center">
                  <Select
                    className="mt-1"
                    isLoading={isToursLoading}
                    {...register(`tourIds.${index}`, { required: true })}
                  >
                    <option value="">Select Tour</option>
                    {tours.map((tour) => {
                      const noOfNights = getTourNoOfNights(tour);
                      return (
                        <option key={tour.id} value={tour.id}>
                          {[
                            tour.title,
                            `${noOfNights}N ${noOfNights + 1}D`,
                          ].join(" - ")}
                        </option>
                      );
                    })}
                  </Select>
                  <TrashIcon
                    className="ml-2 w-5 h-5 hover:text-red-800 cursor-pointer"
                    onClick={() => {
                      const newtourIds = [...tourIds];
                      newtourIds.splice(index, 1);
                      setValue("tourIds", newtourIds);
                    }}
                  />
                </Flex>
                {get(errors, `tourIds.${index}`) && (
                  <span className="text-sm text-red-500">
                    Please select the tour
                  </span>
                )}
              </div>
            );
          })}
          <ButtonSecondary
            className="mb-6 font-thin text-sm border-dotted border-neutral-400 hover:border-primary-400 rounded-2xl dark:border-neutral-700"
            onClick={() => setValue("tourIds", [...tourIds, ""])}
          >
            + Add more tour
          </ButtonSecondary>
        </div>
      </>
    </CommonLayout>
  );
};

export default ListingPlacesOrTours;
