import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface HotelZipCodeProps {
  register: UseFormRegister<HotelWithId>;
  error?: FieldError;
  className?: string;
}

const HotelZipCode: FC<HotelZipCodeProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label>ZIP code</Label>
      <Input
        className="mt-1"
        placeholder="ZIP code"
        {...register("address.zip", { required: true })}
      />
      {error && (
        <span className="text-sm text-red-500">Please enter zip code</span>
      )}
    </div>
  );
};

export default HotelZipCode;
