import React from "react";

const SectionContent = () => {
  return (
    <div className={`app-SectionContent relative`} data-app-id="SectionContent">
      <p className="mb-6">
        Diskounto Holidays began its journey by serving the Asia-India travel
        market, offering a range of best-value products and services powered by
        technology and round-the-clock customer support. Our commitment to
        reliability and transparency has earned us the trust of over 8,000
        satisfied customers.
      </p>

      <p className="mb-6">
        In 2023, Diskounto Holidays Private Limited expanded its operations into
        India, making it easier for travelers to book their journeys online with
        just a few clicks. Our success is driven by the vision and spirit of our
        dedicated team, who believe that no idea is too big and no problem is
        too challenging. This determination has enabled us to diversify our
        offerings, adding a variety of tour packages and customized holidays to
        meet the evolving needs of our customers.
      </p>

      <p className="mb-6">
        With operations in 28 cities across India and a team of over 50
        professionals, Diskounto Holidays has established itself as a leader in
        the online travel industry. We are committed to providing our guests
        with the best possible experience, offering a wide range of destinations
        and a hassle-free booking process, all with a personalized touch that
        sets us apart from other brands.
      </p>

      <p className="mb-6">
        At the heart of Diskounto Holidays is a simple philosophy: to make every
        moment magical. We strive to bring joy and create lasting memories for
        our members, colleagues, partners, and every life we touch. Whether it’s
        lounging on a beach, exploring the wilderness, or enjoying a family
        resort, our goal is to make every holiday unforgettable.
      </p>

      <p className="mb-6">
        Our family-friendly resorts offer a unique blend of relaxation and
        adventure, with activities designed for guests of all ages. From aqua
        zorbing to puppet-making, we provide experiences that parents and
        children can enjoy together. Our kid-friendly resorts are more than just
        a place to stay—they are a platform for learning, creativity, and fun.
        Here, kids can try their hand at cooking, crafting, and even adventurous
        activities, creating memories that will last a lifetime.
      </p>

      <p className="mb-6">
        At Diskounto Holidays, family vacations are about more than just
        recreation and sightseeing—they are about creating extraordinary
        memories that will be cherished for years to come. We believe that every
        moment has the potential to be magical, and it is our mission to help
        you capture those moments and make them your own.
      </p>

      <p className="mb-6">
        Because at Diskounto Holidays, where the culture is simply magical,
        memories begin here—with us.
      </p>
    </div>
  );
};

export default SectionContent;
