import fetchIntercept from "fetch-intercept";
import Cookies from "js-cookie";
import { saveTokenInCookie } from "utils/cookie";

interface DataRequest {
  [key: string]: Request;
}

interface InstanceOptions {
  refreshTokenUrl: string;
  refreshTokenCookieName: string;
  accessTokenCookieName: string;
  signInPath: string;
  allowedHosts?: string[];
  skipUrls?: string[];
}

export const FetchInterceptor = ({
  refreshTokenUrl,
  refreshTokenCookieName = "refresh_token",
  accessTokenCookieName = "access_token",
  signInPath = "/sign-in",
  allowedHosts = [],
  skipUrls = [],
}: InstanceOptions): { registerInterceptors: () => void } => {
  let isRefreshing = false;
  let refreshSubscribers: any[] = [];
  let dataRequests = {} as DataRequest;

  const subscribeTokenRefresh = (callback: () => void) => {
    refreshSubscribers.push(callback);
  };

  const onRefreshed = () => {
    refreshSubscribers.map((callback) => callback());
    refreshSubscribers = [];
  };

  const removeDataRequest = (requestKey: string) => {
    const { [requestKey]: _omit, ...remaining } = dataRequests;
    dataRequests = remaining;
  };

  return {
    registerInterceptors: () => {
      fetchIntercept.register({
        request(url, config) {
          if (!allowedHosts.includes(new URL(url).host)) {
            return [url, config];
          }
          if ([refreshTokenUrl, ...skipUrls].includes(url)) {
            return [url, config];
          }
          const method = config?.method || "GET";
          const accessToken = Cookies.get(accessTokenCookieName) || "";

          if (config) {
            dataRequests = {
              ...dataRequests,
              [`${url}_${method}`]: config,
            };
          }
          return [
            url,
            {
              ...config,
              headers: {
                ...config?.headers,
                Authorization: `Bearer ${accessToken}`,
              },
            },
          ];
        },

        response(response) {
          if (!allowedHosts.includes(new URL(response.url).host)) {
            return response;
          }
          if ([refreshTokenUrl, ...skipUrls].includes(response.url)) {
            return response;
          }
          const requestKey = `${response.url}_${response.request.method}`;
          if (response.status === 401) {
            if (!isRefreshing) {
              isRefreshing = true;

              const refreshToken = Cookies.get(refreshTokenCookieName) || "";
              fetch(refreshTokenUrl, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: `Bearer ${refreshToken}`,
                },
              })
                .then((res) => {
                  return res.json();
                })
                .then(({ errors = [], accessToken }) => {
                  if (errors.length) {
                    throw Error(errors[0].userMessage);
                  }
                  saveTokenInCookie(accessTokenCookieName, accessToken);
                  isRefreshing = false;
                  onRefreshed();
                })
                .catch((err: any) => {
                  console.error(err)
                  Cookies.remove(accessTokenCookieName);
                  Cookies.remove(refreshTokenCookieName);
                  if(window.location.pathname !== signInPath){
                    window.location.href = `${window.location.origin}${signInPath}?redirect=${window.location.pathname}`;
                  }
                });
            }
            const retryOrigReq: any = new Promise((resolve, reject) => {
              subscribeTokenRefresh(() => {
                fetch(response.url, {
                  ...dataRequests[requestKey],
                })
                  .then((retryResponse) => {
                    resolve(retryResponse);
                    removeDataRequest(requestKey);
                  })
                  .catch((err) => {
                    console.log(err);
                    reject(err);
                  });
              });
            });
            return retryOrigReq;
          }
          removeDataRequest(requestKey);
          return response;
        },
      });
    },
  };
};
