import React, { FC } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Flex from "shared/Flex/Flex";
import RadioGroup from "shared/RadioGroup/RadioGroup";

export interface HotelPrimeAmenitiesProps {
  control: Control<HotelWithId>;
  error?: FieldError;
  className?: string;
}

const HotelPrimeAmenities: FC<HotelPrimeAmenitiesProps> = ({
  control,
  className,
}) => {
  return (
    <div className={className}>
      <Flex direction="row" justify="between">
        <Label>Swimming pool</Label>
        <Controller
          name="details.hasSwimmingPool"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <RadioGroup
                value={value}
                onChange={onChange}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            );
          }}
        />
      </Flex>

      <Flex direction="row" justify="between">
        <Label>Parking</Label>
        <Controller
          name="details.hasParking"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <RadioGroup
                value={value}
                onChange={onChange}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            );
          }}
        />
      </Flex>

      <Flex direction="row" justify="between">
        <Label>Restaurant</Label>
        <Controller
          name="details.hasRestaurant"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <RadioGroup
                value={value}
                onChange={onChange}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            );
          }}
        />
      </Flex>

      <Flex direction="row" justify="between">
        <Label>Fitness Center</Label>
        <Controller
          name="details.hasGym"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <RadioGroup
                value={value}
                onChange={onChange}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            );
          }}
        />
      </Flex>

      <Flex direction="row" justify="between">
        <Label>Spa</Label>
        <Controller
          name="details.hasSpa"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <RadioGroup
                value={value}
                onChange={onChange}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            );
          }}
        />
      </Flex>
    </div>
  );
};

export default HotelPrimeAmenities;
