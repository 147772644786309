import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Select from "shared/Select/Select";

export interface HotelStarRatingProps {
  register: UseFormRegister<HotelWithId>;
  error?: FieldError;
  className?: string;
}

const HotelStarRating: FC<HotelStarRatingProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label>Star rating</Label>
      <Select
        className="mt-1"
        {...register("details.starRating", { required: true })}
      >
        <option value="">Select star rating</option>
        <option value="1">1 star</option>
        <option value="2">2 star</option>
        <option value="3">3 star</option>
        <option value="4">4 star</option>
        <option value="5">5 star</option>
      </Select>
      {error && (
        <span className="text-sm text-red-500">
          Please enter hotel star rating
        </span>
      )}
    </div>
  );
};

export default HotelStarRating;
