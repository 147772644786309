import React from "react";
import { HotelRoomWithId } from "domain/hotel-room.type";
import Modal from "shared/Modal/Modal";
import HotelRoomForm from "./HotelRoomForm";

interface AddEditHotelRoomProps {
  isOpen: boolean;
  onCloseModal?: () => void;
  hotelId?: string;
  room?: HotelRoomWithId;
  onAddEditHotelRoom: (room: HotelRoomWithId) => void;
}

const AddEditHotelRoom = ({
  isOpen,
  onCloseModal,
  hotelId,
  room,
  onAddEditHotelRoom,
}: AddEditHotelRoomProps) => {
  return (
    <Modal
      modalTitle={room ? ` Edit ${room.name}` : `Add room`}
      className="w-1/2"
      isOpenProp={isOpen}
      onCloseModal={onCloseModal}
      renderContent={({ closeModal }) => {
        return (
          <HotelRoomForm
            hotelId={hotelId}
            room={room}
            onSuccess={(room) => {
              onAddEditHotelRoom(room);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

export default AddEditHotelRoom;
