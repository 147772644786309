import React, { Children } from "react";
import Flex from "shared/Flex/Flex";

type HotelRoomRateRowProps = {
  left?: React.ReactNode;
  children: React.ReactNode;
  right?: React.ReactNode;
  className?: string;
};

const HotelRoomRateRow: React.FC<HotelRoomRateRowProps> = ({
  left = <></>,
  children,
  right = <></>,
  className = "",
}) => {
  const noOfChilds = Children.count(children);
  return (
    <Flex direction="row" align="center" className={`w-full ${className}`}>
      <Flex direction="row" align="center" className="w-64">
        {left}
      </Flex>

      <Flex
        direction="row"
        align="center"
        justify="center"
        className={`flex-1 grid text-xs xl:text-sm min-h-[46px]`}
        style={{ gridTemplateColumns: `repeat(${noOfChilds}, minmax(0, 1fr))` }}
      >
        {Children.map(children, (child, index) => (
          <Flex
            key={index}
            direction="row"
            align="center"
            justify="center"
            className="group h-full col-span-1 border bg-white text-center hover:bg-slate-100 hover:transition-all"
          >
            {child}
          </Flex>
        ))}
      </Flex>

      <Flex direction="row" align="center" className="w-20">
        {right}
      </Flex>
    </Flex>
  );
};

export default HotelRoomRateRow;
