import copyToClipboard from "copy-to-clipboard";
import { ArrowUpOnSquareStackIcon, MapIcon } from "@heroicons/react/24/outline";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { PlaceWithId } from "domain/place.type";
import { handlePlural } from "utils/string";
import { generatePathWithQuery } from "utils/url";
import environment from "config/environment";
import useFetch from "hooks/useFetch";
import List from "shared/List/List";
import LazyImage from "shared/LazyImage/LazyImage";
import Flex from "shared/Flex/Flex";
import Button from "shared/Button/Button";
import ListLoader from "shared/Loader/ListLoader";
import SiderContentHeading from "components/SiderContentHeading";
import CreatePlace from "./CreatePlace";
import EditPlace from "./EditPlace";
import DeletePlace from "./DeletePlace";

const Places = () => {
  const {
    isLoading,
    data = [],
    setData,
    showLoadMore,
    loadMoreData,
  } = useFetch<PlaceWithId[]>({ url: `${environment.apiUrl}/place` }, 50);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: showLoadMore,
    onLoadMore: loadMoreData,
    disabled: false,
    rootMargin: "0px 0px 10px 0px",
  });

  return (
    <Flex className="w-full">
      <SiderContentHeading
        icon={<MapIcon className="w-6 mr-3" />}
        heading={"Places"}
        actionsComponent={
          <CreatePlace onAddPlace={(place) => setData([...data, place])} />
        }
      />
      <div className="p-5 w-full">
        <List
          loading={isLoading}
          items={data}
          rowKey="id"
          renderItem={({ item }) => (
            <div className="grid grid-cols-12 p-2 bg-white">
              <Flex direction="row" align="center" className="col-span-5">
                <ArrowUpOnSquareStackIcon
                  className="text-base cursor-pointer h-6 mr-2 hover:text-primary-700"
                  onClick={() =>
                    copyToClipboard(
                      `${environment.apiUrl}/view/place/${item.id}/holiday-packages`
                    )
                  }
                />
                <LazyImage
                  src={`${environment.assetsUrl}/${item.thumbnail.path}`}
                  className="w-10 h-10 border border-primary-500 rounded-full"
                  containerClassName="w-auto"
                />
                <Flex className="ml-2">
                  <Flex direction="row" align="center" className="text-base">
                    <span>{item.name}</span>
                  </Flex>
                  <div className="text-sm text-neutral-400">
                    {[item.state, item.country.name].filter(Boolean).join(", ")}
                  </div>
                </Flex>
              </Flex>
              <Flex className="col-span-3 inline-flex items-center justify-center border border-primary-500 p-1 w-24 h-9 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                {handlePlural(item.noOfHotels, "hotel", "s")}
              </Flex>
              <Flex
                direction="row"
                justify="end"
                align="center"
                className="col-span-4"
              >
                <Button
                  className="ml-2"
                  sizeClass="px-5 py-1"
                  targetBlank
                  href={generatePathWithQuery(
                    "/place/:slug/holiday-packages?id=:placeId",
                    {
                      placeId: item.id,
                      slug: item.slug,
                    }
                  )}
                >
                  View
                </Button>
                <EditPlace
                  place={item}
                  onEditPlace={(place) =>
                    setData(
                      data.map((i) =>
                        i.id === place.id ? { ...i, ...place } : i
                      )
                    )
                  }
                />
                <DeletePlace
                  place={item}
                  onDeletePlace={(place) =>
                    setData(data.filter((i) => i.id !== place.id))
                  }
                />
              </Flex>
            </div>
          )}
        />
        {(isLoading || showLoadMore) && (
          <div ref={sentryRef}>
            <ListLoader />
          </div>
        )}
      </div>
    </Flex>
  );
};

export default Places;
