import React from "react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { HotelRoomWithId } from "domain/hotel-room.type";
import { HotelRoomRateWithId } from "domain/hotel-room-rate.type";
import { SupplierServiceWithId } from "domain/supplier-service.type";
import Modal from "shared/Modal/Modal";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Flex from "shared/Flex/Flex";
import HotelRoomRateForm from "./HotelRoomRateForm";

interface AddEditHotelRoomRateProps {
  buttonType?: "primary" | "add" | "edit";
  showButton?: boolean;
  supplierService?: SupplierServiceWithId;
  rooms: HotelRoomWithId[];
  hotelRoomRate?: Partial<HotelRoomRateWithId>;
  className?: string;
  onChangeHotelRoomRate?: (hotelRoomRate?: HotelRoomRateWithId) => void;
}

const AddEditHotelRoomRate = ({
  buttonType = "primary",
  showButton = true,
  supplierService,
  rooms,
  hotelRoomRate,
  className = "",
  onChangeHotelRoomRate = () => {},
}: AddEditHotelRoomRateProps) => {
  if (!supplierService || supplierService.serviceType !== "HOTEL") {
    return <></>;
  }
  return (
    <Modal
      modalTitle={
        <Flex>
          <div className="text-lg">
            {[
              supplierService?.service?.name,
              supplierService?.service?.place?.name,
              supplierService?.service?.place?.country.name,
            ]
              .filter(Boolean)
              .join(", ")}
          </div>
          <div className="text-xs text-neutral-500 font-thin">
            Supplier : {supplierService?.supplier?.name}
          </div>
        </Flex>
      }
      className="w-[767px]"
      renderTrigger={({ openModal }) => (
        <>
          <ButtonPrimary
            show={showButton && buttonType === "primary"}
            className={className}
            onClick={openModal}
          >
            Bulk update rates
          </ButtonPrimary>
          <Button
            show={showButton && buttonType === "edit"}
            className={`text-xs text-primary-500 hover:text-primary-600 ${className}`}
            sizeClass="px-0 py-0"
            hasRing={false}
            onClick={openModal}
          >
            <PencilIcon
              width={16}
              className="hidden group-hover:block mt-1"
              onClick={openModal}
            />
          </Button>
          <Button
            show={showButton && buttonType === "add"}
            className={`text-xs text-primary-500 hover:text-primary-600 ${className}`}
            sizeClass="px-0 py-0"
            hasRing={false}
            onClick={openModal}
          >
            Add
          </Button>
        </>
      )}
      renderContent={({ closeModal }) => {
        return (
          <HotelRoomRateForm
            rooms={rooms}
            hotelRoomRate={{
              supplierId: supplierService.supplierId,
              hotelId: supplierService.serviceId,
              ...hotelRoomRate,
            }}
            onCancel={closeModal}
            onSuccess={(hotelRoomRate) => {
              onChangeHotelRoomRate(hotelRoomRate);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

export default AddEditHotelRoomRate;
