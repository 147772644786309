import { useState } from "react";
import { Switch } from "@headlessui/react";

import { SupplierServiceWithId } from "domain/supplier-service.type";
import environment from "config/environment";
import { fetcher } from "hooks/useFetch";

const SupplierServiceStatus = ({ supplierService, onSuccess }: { supplierService: SupplierServiceWithId,onSuccess: (supplierService: SupplierServiceWithId)=> void }) => {
  const [loading, setLoading] = useState(false);

  const handleChangeSwitch = async (checked: boolean) => {
    setLoading(true);
    try {
      const path = `/supplier-service/${supplierService?.id}`;
      const method ="PUT";
      const result = await fetcher({
        url: `${environment.apiUrl}${path}`,
        method,
        body: JSON.stringify({status: checked ? "ACTIVE" : "INACTIVE"}),
      });
      if (result.errors) {
        //setServerErrors(result.errors);
      } else {
        onSuccess(result.supplierService as SupplierServiceWithId);
      }
    } catch (e) {
        console.error(e)
    }
    finally{
        setLoading(false); 
    }
  };

  const enabled = supplierService.status === "ACTIVE"
  return (
    <Switch
      disabled={loading}
      checked={enabled}
      onChange={() => handleChangeSwitch(!enabled)}
      className={`${
        enabled ? "bg-green-600" : "bg-red-400"
      } relative inline-flex h-6 w-11 items-center rounded-full`}
    >
      <span className="sr-only">{enabled ? "Active" : "Inactive"}</span>
      <span
        className={`${
          enabled ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  );
};

export default SupplierServiceStatus;
