import React from "react";
import NavigationItem, {
  NavItemType,
} from "../../shared/Navigation/NavigationItem";
import { chunk, get, orderBy, partition } from "lodash";
import CountryFlag from "shared/CountryFlag/CountryFlag";
import { PlaceWithId } from "domain/place.type";
import environment from "config/environment";
import useFetch from "hooks/useFetch";
import { generatePathWithQuery } from "utils/url";

export interface DesktopNavigationProps {}

const getPlacesInChunks = (
  places: PlaceWithId[],
  property: "noOfHotels" | "noOfTours",
  hrefPath: string,
  columns = 2
) => {
  const [domestic, international] = partition(
    orderBy(
      places.filter((place) => get(place, property, 0) > 0),
      "country.code"
    ),
    (item) => item.country.code === "IN"
  );

  const placesToDisplay = [...domestic, ...international].map((place) => ({
    id: place.id,
    href: generatePathWithQuery(hrefPath, { placeId: place.id, slug: place.slug }),
    className: "mx-4",
    name: (
      <span className="flex flex-row">
        <CountryFlag countryCode={place.country.code} className="w-4 mr-2" />
        {place.name}
      </span>
    ),
  }));

  return chunk(placesToDisplay, Math.ceil(placesToDisplay.length / columns));
};

function DesktopNavigation() {
  const { data: places = [] } = useFetch<PlaceWithId[]>(
    {
      url: `${environment.apiUrl}/place`,
    },
    1000
  );

  const placesHasHotelsChunk = getPlacesInChunks(
    places,
    "noOfHotels",
    "place/:slug/hotels?id=:placeId",
    4
  );
  const placesHasToursChunk = getPlacesInChunks(
    places,
    "noOfTours",
    "/place/:slug/holiday-packages?id=:placeId",
    3
  );

  const menu: NavItemType[] = [
    {
      id: "1",
      href: "",
      name: "Packages",
      type: "megaMenu",
      megaMenu: placesHasToursChunk.map((chunk, index) => ({
        id: `tours-${index}`,
        image: "",
        title: "",
        items: chunk,
      })),
    },
    {
      id: "2",
      href: "",
      name: "Hotels",
      type: "megaMenu",
      megaMenu: placesHasHotelsChunk.map((chunk, index) => ({
        id: `hotels-${index}`,
        image: "",
        title: "",
        items: chunk,
      })),
    },
  ];

  return (
    <ul className="desktop-navigation hidden md:flex md:flex-wrap md:items-center md:space-x-1 relative">
      {menu.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default DesktopNavigation;
