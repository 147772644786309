import React from "react";
import { TourWithId } from "domain/tour.type";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import TourForm from "./TourForm";

interface CreateTourProps {
  onAddTour: (tour: TourWithId) => void;
}

const CreateTour = ({ onAddTour }: CreateTourProps) => {
  return (
    <Modal
      modalTitle="Add a tour"
      fullScreen
      renderTrigger={({ openModal }) => (
        <ButtonPrimary onClick={openModal}>Add a tour</ButtonPrimary>
      )}
      renderContent={({ closeModal }) => {
        return (
          <TourForm
            onSuccess={(tour) => {
              onAddTour(tour);
              closeModal();
            }} 
          />
        );
      }}
    />
  );
};

export default CreateTour;
