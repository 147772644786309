import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { SupplierWithId } from "domain/supplier.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface SupplierMarginB2BProps {
  register: UseFormRegister<SupplierWithId>;
  error?: FieldError;
  className?: string;
}

const SupplierMarginB2B: FC<SupplierMarginB2BProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label required>B2B Margin %</Label>
      <Input
        type="number"
        className="mt-1"
        step={0.01}
        min={0}
        placeholder="B2B Margin percent"
        {...register("b2bMarginPercent", { valueAsNumber: true, required: true, min: 0 })}
      />
      {error && (
        <span className="text-sm text-red-500">
          Please enter b2b margin percent
        </span>
      )}
    </div>
  );
};

export default SupplierMarginB2B;
