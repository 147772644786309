import React, { FC } from "react";
import { Link } from "react-router-dom";
import { StarIcon } from "@heroicons/react/24/solid";

import { generatePathWithQuery } from "utils/url";
import { HotelWithId } from "domain/hotel.type";
import environment from "config/environment";
import GallerySlider from "components/GallerySlider/GallerySlider";
import Badge from "shared/Badge/Badge";
import Flex from "shared/Flex/Flex";

export interface HotelCardProps {
  hotel: HotelWithId;
  isPromoted?: boolean;
  className?: string;
}

const HotelCard: FC<HotelCardProps> = ({
  hotel,
  isPromoted = false,
  className = "",
}) => {
  const {
    id: hotelId,
    thumbnail,
    name,
    slug,
    details: { starRating },
    place,
    address,
  } = hotel;

  return (
    <div
      className={`app-HotelCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-app-id="HotelCard"
    >
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`HotelCard_${hotelId}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          href={generatePathWithQuery(`/hotel/:slug?id=:hotelId`, {
            slug,
            hotelId,
          })}
          images={[`${environment.assetsUrl}/${thumbnail?.path}`]}
        />
        {isPromoted && (
          <Badge
            name="Popular"
            color="green"
            className="absolute left-3 top-3"
          />
        )}
      </div>
      <Link
        to={generatePathWithQuery(`/hotel/:slug?id=:hotelId`, {
          slug,
          hotelId,
        })}
      >
        <div className="p-4 space-y-4">
          <div className="space-y-2">
            <Flex
              direction="row"
              className="text-sm text-neutral-500 dark:text-neutral-400"
            >
              {new Array(Number(starRating)).fill(
                <StarIcon className="h-5 text-yellow-500" />
              )}
            </Flex>
            <div className="flex items-center space-x-2">
              <h2 className="font-medium capitalize text-lg">
                <span className="line-clamp-1">{name}</span>
              </h2>
            </div>
            <div className="flex items-start text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              {/* <MapPinIcon className="w-12" /> */}
              <span className="">
                {[address.street, place?.name, place?.country.name]
                  .filter(Boolean)
                  .join(", ")}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default HotelCard;
