import React from "react";

import { ToursListingWithId } from "domain/tours-listing.type";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import ToursListingForm from "./ToursListingForm";

interface ToursListingProps {
  onAddToursListing: (toursListing: ToursListingWithId) => void;
}

const CreateToursListing = ({ onAddToursListing }: ToursListingProps) => {
  return (
    <Modal
      modalTitle="Add new listing"
      fullScreen
      renderTrigger={({ openModal }) => (
        <ButtonPrimary onClick={openModal}>Add new listing</ButtonPrimary>
      )}
      renderContent={({ closeModal }) => {
        return (
          <ToursListingForm
            onSuccess={(toursListing) => {
              onAddToursListing(toursListing);
              closeModal();
            }} 
          />
        );
      }}
    />
  );
};

export default CreateToursListing;
