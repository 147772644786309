import React from "react";
import { StarIcon } from "@heroicons/react/24/solid";
import { HotelWithId } from "domain/hotel.type";
import { getHotelTypeText } from "utils/hotelUtils";
import Badge from "shared/Badge/Badge";

export interface HotelHeadingProps {
  hotel: HotelWithId;
}

const HotelHeading = ({ hotel }: HotelHeadingProps) => {
  const details = [
    {
      icon: "las la-door-open",
      text: `${hotel.details.noOfRooms} rooms`,
      value: hotel.details.noOfRooms,
    },
    {
      icon: "las la-utensils",
      text: "Restaurant",
      value: hotel.details.hasRestaurant,
    },
    {
      icon: "las la-swimmer",
      text: "Swimming Pool",
      value: hotel.details.hasSwimmingPool,
    },
    {
      icon: "las la-car-side",
      text: "Parking",
      value: hotel.details.hasParking,
    },
    { icon: "las la-dumbbell", text: "Gym", value: hotel.details.hasGym },
    { icon: "las la-spa", text: "Spa", value: hotel.details.hasSpa },
  ];

  return (
    <div className="w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8 !space-y-6">
      <div className="flex justify-between items-center">
        <Badge name={getHotelTypeText(hotel.type)} />
        <div />
      </div>

      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
        {hotel.name}
      </h2>

      <div className="flex items-center space-x-4">
        <div className="flex items-center">
          {new Array(Number(hotel.details.starRating)).fill("").map((item, index) => (
            <StarIcon key={index} className="h-5 text-yellow-500" />
          ))}
        </div>
        <span>·</span>
        <span>
          <i className="las la-map-marker-alt"></i>
          <span className="ml-1">
            {[hotel.place?.name, hotel.place?.country.name]
              .filter(Boolean)
              .join(", ")}
          </span>
        </span>
      </div>

      <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

      <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
        {details
          .filter((i) => Boolean(i.value))
          .slice(0, 5)
          .map((detail) => (
            <div key={detail.text} className="flex items-center space-x-2 ">
              <i className={`${detail.icon} text-2xl`}></i>
              <span className="">
                <span className="hidden sm:inline-block ml-1">
                  {detail.text}
                </span>
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default HotelHeading;
