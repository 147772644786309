import React, { useState } from "react";
import { Squares2X2Icon } from "@heroicons/react/24/outline";

import { HotelWithId } from "domain/hotel.type";
import environment from "config/environment";
import LazyImage from "shared/LazyImage/LazyImage";
import ModalPhotosGallery from "components/ModalPhotosGallery";

interface HotelPhotosProps {
  hotel: HotelWithId;
}

const HotelPhotos = ({ hotel }: HotelPhotosProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  return (
    <>
      <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={() => handleOpenModal(0)}
          >
            <LazyImage
              containerClassName="absolute inset-0 rounded-md sm:rounded-xl"
              className="w-full h-full"
              src={`${environment.assetsUrl}/${hotel.thumbnail?.path}`}
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          
          {hotel.photos.slice(0, 4).map((item, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                index >= 3 ? "hidden sm:block" : ""
              }`}
            >
              <LazyImage
                containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5 rounded-md sm:rounded-xl"
                className="w-full"
                src={`${environment.assetsUrl}/${item.path}`}
                meta={item.meta}
              />

              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={() => handleOpenModal(index + 1)}
              />
            </div>
          ))}

          <div
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
            onClick={() => handleOpenModal(0)}
          >
            <Squares2X2Icon className="h-8" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </div>
        </div>
      </header>

      {/* MODAL PHOTOS */}
      <ModalPhotosGallery
        isOpen={isOpen}
        title={hotel.name}
        imgs={[
          `${environment.assetsUrl}/${hotel.thumbnail?.path}`,
          ...hotel.photos.map(
            (item) => `${environment.assetsUrl}/${item.path}`
          ),
        ]}
        initFocus={openFocusIndex}
        onClose={handleCloseModal}
        uniqueClassName={`HotelPhotos-${hotel.id}`}
      />
    </>
  );
};

export default HotelPhotos;
