import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { pick } from "lodash";
import { HotelWithId } from "domain/hotel.type";
import CommonLayout from "./CommonLayout";
import HotelThumbnail from "../Inputs/HoteThumbnail";
import HotelPhotos from "../Inputs/HotelPhotos";

export interface HotelPhotosFormProps {
  hotel?: HotelWithId | HotelWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<HotelWithId>) => void;
}

const HotelPhotosForm: FC<HotelPhotosFormProps> = ({
  hotel,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<HotelWithId>({
    defaultValues: pick<HotelWithId>(hotel, ["thumbnail", "photos"]),
  });

  const onSubmit = (data: HotelWithId) => {
    onClickNext(data);
  };
  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Hotel Details</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-2 gap-6">
          <HotelThumbnail
            control={control}
            error={errors.thumbnail}
            defaultValue={hotel?.thumbnail}
            className="col-span-2"
          />
          <HotelPhotos
            control={control}
            error={errors.photos}
            defaultValue={hotel?.photos}
            className="col-span-2"
          />
        </div>
      </>
    </CommonLayout>
  );
};

export default HotelPhotosForm;
