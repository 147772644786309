import React, { FC } from "react";

interface FlexProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  id?: string;
  direction?: "row" | "row-reverse" | "col" | "col-reverse";
  justify?: "start" | "end" | "center" | "between" | "around" | "evenly";
  align?: "start" | "end" | "center" | "baseline" | "stretch";
  show?: boolean;
  children?: React.ReactNode;
}

const Flex: FC<FlexProps> = ({
  id,
  direction = "col",
  justify = "start",
  align = "start",
  show = true,
  className = "",
  children,
  ...props
}) => {
  if(!show) {
    return <></>;
  }
  return <div id={id} className={`flex flex-${direction} justify-${justify} items-${align} ${className}`} {...props}>{children}</div>;
};

export default Flex;
