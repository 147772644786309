import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { pick } from "lodash";
import { SupplierWithId } from "domain/supplier.type";
import CommonLayout from "./CommonLayout";
import SupplierName from "../Inputs/SupplierName";
import SupplierTradeName from "../Inputs/SupplierTradeName";
import SupplierAddress from "../Inputs/SupplierAddress";
import SupplierMobile from "../Inputs/SupplierMobile";
import SupplierEmail from "../Inputs/SupplierEmail";
import SupplierCountry from "../Inputs/SupplierCountry";

export interface BasicInformationProps {
  supplier?: SupplierWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<SupplierWithId>) => void;
}

const BasicInformation: FC<BasicInformationProps> = ({
  supplier,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SupplierWithId>({
    defaultValues: pick<SupplierWithId>(supplier, [
      "name",
      "tradeName",
      "address",
      "country"
    ]),
  });

  const onSubmit = (data: SupplierWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Basic Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-2 gap-6">
          <SupplierName register={register} error={errors.name} />
          <SupplierCountry register={register} error={errors.country?.code} />
          <SupplierTradeName
            register={register}
            error={errors.tradeName}
            className="col-span-2"
          />
          <SupplierAddress
            register={register}
            error={errors.address}
            className="col-span-2"
          />
          <SupplierMobile register={register} error={errors.mobile} />
          <SupplierEmail register={register} error={errors.email} />
        </div>
      </>
    </CommonLayout>
  );
};

export default BasicInformation;
