import React, { FC, useEffect, useState } from "react";
import {
  AnchorDirectionShape,
  DateRangePicker,
  FocusedInputShape,
} from "react-dates-17";
import moment from "moment";

import useUniqueId from "hooks/useUniqueId";
import { MinusIcon } from "@heroicons/react/24/outline";

export interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface DateRangeInputProps {
  defaultValue?: DateRange;
  defaultFocus?: FocusedInputShape | null;
  startDatePlaceholderText?: string;
  endDatePlaceholderText?: string;
  className?: string;
  numberOfMonths?: number;
  anchorDirection?: AnchorDirectionShape;
  onChange?: (data: DateRange) => void;
  onFocusChange?: (focus: FocusedInputShape | null) => void;
}

const DateRangeInput: FC<DateRangeInputProps> = ({
  defaultValue = {
    startDate: moment().add(0, "days").startOf("day"),
    endDate: moment().add(7, "days").startOf("day"),
  },
  defaultFocus = null,
  onChange,
  onFocusChange,
  startDatePlaceholderText = "Start Date",
  endDatePlaceholderText = "End Date",
  className = "",
  numberOfMonths = 2,
  anchorDirection,
}) => {
  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const [dateRange, setDateRange] = useState(defaultValue);

  const startDateId = useUniqueId();
  const endDateId = useUniqueId();

  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);

  const handleDateFocusChange = (focus: FocusedInputShape | null) => {
    setFocusedInput(focus);
    onFocusChange && onFocusChange(focus);
  };

  return (
    <div className={`react-date-range-input mt-1 ${className}`}>
      <DateRangePicker
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        focusedInput={focusedInput}
        onFocusChange={handleDateFocusChange}
        numberOfMonths={numberOfMonths}
        startDateId={startDateId}
        endDateId={endDateId}
        startDatePlaceholderText={startDatePlaceholderText}
        endDatePlaceholderText={endDatePlaceholderText}
        daySize={44}
        orientation="horizontal"
        showClearDates
        displayFormat="DD/MM/YYYY"
        hideKeyboardShortcutsPanel
        anchorDirection={anchorDirection}
        customArrowIcon={<MinusIcon width={20} />}
        reopenPickerOnClearDates
        onDatesChange={(date) => {
          setDateRange(date);
          onChange && onChange(date);
        }}
      />
    </div>
  );
};

export default DateRangeInput;
