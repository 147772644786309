import React, { useState } from "react";

import { ToursListingWithId } from "domain/tours-listing.type";
import { CustomError } from "domain/custom-error.type";
import environment from "config/environment";
import { fetcher } from "hooks/useFetch";
import Modal from "shared/Modal/Modal";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Flex from "shared/Flex/Flex";

interface DeleteToursListingProps {
  toursListing: ToursListingWithId;
  onDeleteToursListing: (toursListing: ToursListingWithId) => void;
}

const DeleteToursListing = ({ toursListing, onDeleteToursListing }: DeleteToursListingProps) => {
  return (
    <Modal
      modalTitle={`Delete ${toursListing.title}`}
      className="w-96"
      renderTrigger={({ openModal }) => (
        <Button sizeClass="px-5 py-1" onClick={openModal}>
          Delete
        </Button>
      )}
      renderContent={({ closeModal }) => {
        return (
          <DeleteToursListingForm
            toursListing={toursListing}
            onSuccess={() => {
              onDeleteToursListing(toursListing);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

interface DeleteToursListingFormProps {
  toursListing: ToursListingWithId;
  onSuccess: () => void;
}

const DeleteToursListingForm = ({
  toursListing,
  onSuccess = () => {},
}: DeleteToursListingFormProps) => {
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);

  const onSubmit = async () => {
    const result = await fetcher({
      url: `${environment.apiUrl}/toursListing/${toursListing.id}`,
      method: "DELETE",
      body: JSON.stringify({}),
    });

    if (result.errors) {
      setServerErrors(result.errors);
    } else {
      onSuccess();
    }
  };

  return (
    <div>
      <div>Do you want to delete {toursListing.title} ?</div>
      <div className="col-span-2">
        {serverErrors.map((error) => (
          <p key={error.code} className="text-red-500">
            {error.userMessage}
          </p>
        ))}
      </div>
      <Flex direction="row" justify="end" className="mt-4">
        <ButtonPrimary onClick={onSubmit}>Delete</ButtonPrimary>
      </Flex>
    </div>
  );
};

export default DeleteToursListing;
