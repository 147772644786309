import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { get } from "lodash";

import environment from "config/environment";
import { PlaceWithId } from "domain/place.type";
import { Country } from "domain/country-state.type";
import { CustomError } from "domain/custom-error.type";
import useFetch, { fetcher } from "hooks/useFetch";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Label from "shared/Label/Label";
import Select from "shared/Select/Select";
import Button from "shared/Button/Button";
import DragUploader from "components/DragUploader/DragUploader";

export interface PlaceFormProps {
  place?: PlaceWithId;
  className?: string;
  onCancel?: () => void;
  onSuccess?: (place: PlaceWithId) => void;
}

export const initialState: PlaceWithId = {
  id: "",
  name: "",
  slug: "",
  country: {
    code: "",
    name: "",
  },
  state: undefined,
  thumbnail: undefined as never as PlaceWithId["thumbnail"],
  coverPhotos: {
    mobile: [],
    desktop: [],
  },
  marketingPhoto: null as never as PlaceWithId["marketingPhoto"],
};

const PlaceForm: FC<PlaceFormProps> = ({
  place,
  className = "",
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  const countriesUrl = `${environment.apiUrl}/site-data/country`;
  const { isLoading: isCountriesLoading, data: countries = [] } = useFetch<
    Country[]
  >({ url: countriesUrl }, 100);
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<PlaceWithId>({
    defaultValues: place || initialState,
  });

  const onSubmit = async (data: PlaceWithId) => {
    const result = await fetcher({
      url: [`${environment.apiUrl}/place`, place?.id].filter(Boolean).join("/"),
      method: place?.id ? "PUT" : "POST",
      body: JSON.stringify(data),
    });
    if (result.errors) {
      setServerErrors(result.errors);
    } else {
      onSuccess(result.place as PlaceWithId);
    }
  };

  const countryCode = watch("country.code", get(countries, "0.code"));

  return (
    <form
      className={`grid grid-cols-2 gap-6 ${className}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col-span-2">
        <Label>Place name</Label>
        <Input
          type="text"
          placeholder="Name of the place"
          className="mt-1"
          defaultValue={place?.name || ""}
          {...register("name", { required: true })}
        />
        {errors.name && (
          <span className="text-sm text-red-500">Please enter place name</span>
        )}
      </div>

      <div>
        <Label>Country</Label>
        <Select
          className="mt-1"
          isLoading={isCountriesLoading}
          defaultValue={place?.country?.code || ""}
          {...register("country.code", {
            required: true,
            onChange: (e) => {
              setValue("country.code", e.target.value);
              setValue("state", "", { shouldValidate: isSubmitted });
            },
          })}
        >
          <option value="">Select Country</option>
          {countries.map((country) => {
            return (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            );
          })}
        </Select>
        {errors.country && (
          <span className="text-sm text-red-500">Please select country</span>
        )}
      </div>

      <div>
        <Label>State (Optional)</Label>
        <Select
          className="mt-1"
          isLoading={isCountriesLoading}
          defaultValue={place?.state || ""}
          {...register("state", { required: false })}
        >
          <option value="">Select State</option>
          {countries
            .find((country) => country.code === countryCode)
            ?.states.map((state) => {
              return (
                <option key={state} value={state}>
                  {state}
                </option>
              );
            })}
        </Select>
        {errors.state && (
          <span className="text-sm text-red-500">
            Please select state/region/provinance
          </span>
        )}
      </div>

      <div className="col-span-2">
        <Label>Thumbnail</Label>
        <p className="text-xs text-neutral-500">
          Upload a thumbnail which will be displayed as card image (.webp, .jpg,
          .jpeg, .png & .gif). Prefer aspect ratio of 3:4(W:H).
        </p>
        <Controller
          name="thumbnail"
          control={control}
          rules={{
            validate: (file) => {
              return file?.status === "done";
            },
          }}
          render={({ field: { onChange } }) => (
            <DragUploader
              mode="place"
              className="mt-1"
              maxFiles={1}
              defaultValue={place?.thumbnail ? [place?.thumbnail] : []}
              onChange={(files) => {
                onChange(get(files, "0"));
              }}
              assetHost={environment.assetsUrl}
            />
          )}
        />
        {errors.thumbnail && (
          <span className="text-sm text-red-500">
            Please upload a thumbnail
          </span>
        )}
      </div>

      <div className="col-span-2">
        <Label>Cover Photo - Desktop</Label>
        <p className="text-xs text-neutral-500">
          Upload photo which will be displayed as cover image on desktop.
          (.webp, .jpg, .jpeg, .png & .gif). Prefer aspect ratio of 2:1(W:H).
        </p>
        <Controller
          name="coverPhotos.desktop"
          control={control}
          rules={{
            validate: (files) => {
              return (
                Array.isArray(files) &&
                files.length > 0 &&
                files.map((file) => file?.status === "done").filter(Boolean)
                  .length === files.length
              );
            },
          }}
          render={({ field: { onChange } }) => (
            <DragUploader
              mode="place"
              className="mt-1"
              maxFiles={1}
              defaultValue={
                Array.isArray(place?.coverPhotos.desktop)
                  ? place?.coverPhotos.desktop
                  : []
              }
              onChange={onChange}
            />
          )}
        />
        {errors.coverPhotos?.desktop && (
          <span className="text-sm text-red-500">
            Please upload cover photo for desktop.
          </span>
        )}
      </div>

      <div className="col-span-2">
        <Label>Cover Photo - Mobile</Label>
        <p className="text-xs text-neutral-500">
          Upload photo which will be displayed as cover image. (.webp, .jpg,
          .jpeg, .png & .gif). Prefer aspect ratio of 3:4(W:H).
        </p>
        <Controller
          name="coverPhotos.mobile"
          control={control}
          rules={{
            validate: (files) => {
              return (
                Array.isArray(files) &&
                files.length > 0 &&
                files.map((file) => file?.status === "done").filter(Boolean)
                  .length === files.length
              );
            },
          }}
          render={({ field: { onChange } }) => (
            <DragUploader
              mode="place"
              className="mt-1"
              maxFiles={1}
              defaultValue={
                Array.isArray(place?.coverPhotos.mobile)
                  ? place?.coverPhotos.mobile
                  : []
              }
              onChange={onChange}
            />
          )}
        />
        {errors.coverPhotos?.mobile && (
          <span className="text-sm text-red-500">
            Please upload cover photo for mobile.
          </span>
        )}
      </div>

      <div className="col-span-2">
        <Label>Marketing Photo (Optional)</Label>
        <p className="text-xs text-neutral-500">
          Upload a photo which will be displayed as cover image while sharing
          link in social media (.webp, .jpg, .jpeg, .png & .gif). Recommended
          Size is 1200 x 630 px. Image should be less than 300kb to properly
          work in whatsapp.
        </p>
        <Controller
          name="marketingPhoto"
          control={control}
          rules={{
            validate: (file) => {
              return file ? file.status === "done" : true;
            },
          }}
          render={({ field: { onChange } }) => (
            <DragUploader
              mode="place"
              className="mt-1"
              maxFiles={1}
              defaultValue={
                place?.marketingPhoto ? [place?.marketingPhoto] : []
              }
              onChange={(files) => {
                const file = get(files, "0");
                onChange(file ? file : null);
              }}
            />
          )}
        />
        {errors.marketingPhoto && (
          <span className="text-sm text-red-500">
            Please upload a marketing photo
          </span>
        )}
      </div>

      <div className="col-span-2">
        {serverErrors.map((error) => (
          <p key={error.code} className="text-red-500">
            {error.userMessage}
          </p>
        ))}
      </div>

      <Button type="button" bordered onClick={onCancel}>
        Cancel
      </Button>
      <ButtonPrimary type="submit">Submit</ButtonPrimary>
    </form>
  );
};

export default PlaceForm;
