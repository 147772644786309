import React, { ReactNode } from "react";
import { Route, NavLink, Routes } from "react-router-dom";

import Logo from "shared/Logo/Logo";

export type MenuConfig = {
  key: string;
  path: string;
  text: string;
  element: ReactNode;
  icon: ReactNode;
  auth?: Boolean;
  className?: string;
};

export type MenuListConfig = Array<MenuConfig>;

const SiderMenu = ({
  menu,
  showLogo = true,
  menuHeader,
  menuFooter
}: {
  menu: MenuListConfig;
  showLogo?: Boolean;
  menuHeader?: ReactNode;
  menuFooter?: ReactNode;
}) => {
  return (
    <nav className="w-64 max-h-screen overflow-auto flex-none bg-primary-700">
      <div>
        {showLogo && <Logo className="p-4 h-20" />}
        {menuHeader}
        <ul className="menu bg-base-100 w-full text-white divide-y divide-neutral-300 border-y border-neutral-300">
          {menu.map((menuItem) => (
            <li key={menuItem.key} className="px-4 py-2">
              <NavLink
                to={menuItem.path}
                end
                className={({ isActive }) =>
                  isActive ? "text-primary-300" : "hover:text-primary-300"
                }
              >
                <div className="flex flex-row items-center h-10">
                  <div className="w-8 h-8 mr-2">{menuItem.icon}</div>
                  <div>{menuItem.text}</div>
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
        {menuFooter}
      </div>
    </nav>
  );
};

const SiderRoutes = ({ menu }: { menu: MenuListConfig }) => {
  return (
    <main className="flex-1 min-w-0 overflow-auto bg-neutral-100">
      <Routes>
        {menu.map((menuItem) => (
          <Route
            key={menuItem.key}
            path={menuItem.path}
            element={menuItem.element}
          />
        ))}
      </Routes>
    </main>
  );
};

const SiderLayout = ({
  menu,
  showLogo = true,
  menuHeader, 
  menuFooter,
}: {
  menu: MenuListConfig;
  showLogo?: Boolean;
  menuHeader?: ReactNode;
  menuFooter?: ReactNode;
}) => {
  const filteredMenu: MenuListConfig = menu.filter((item) => item.auth);

  return (
    <div className="h-screen flex">
      <SiderMenu
        menu={filteredMenu}
        showLogo={showLogo}
        menuHeader={menuHeader}
        menuFooter={menuFooter}
      />
      <SiderRoutes menu={filteredMenu} />
    </div>
  );
};

export default SiderLayout;