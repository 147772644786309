import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";
import { capitalize } from "lodash";

export interface HotelAgePolicyProps {
  field: keyof HotelWithId["agePolicy"];
  register: UseFormRegister<HotelWithId>;
  error?: FieldError;
  className?: string;
}

const HotelAgePolicy: FC<HotelAgePolicyProps> = ({
  field,
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label>{capitalize(field)}</Label>
      <div className="grid grid-cols-2 gap-2">
        <Input
          type="number"
          className="mt-1"
          placeholder="Min Age"
          min={0}
          max={100}
          {...register(`agePolicy.${field}.min`, {
            valueAsNumber: true,
            required: true,
            min: 0,
            max: 100,
          })}
        />
        <Input
          type="number"
          className="mt-1"
          placeholder="Max Age"
          min={0}
          max={100}
          {...register(`agePolicy.${field}.max`, {
            valueAsNumber: true,
            required: true,
            min: 0,
            max: 100,
          })}
        />
      </div>
      {error && (
        <span className="text-sm text-red-500">
          Please enter valid age range for {field}
        </span>
      )}
    </div>
  );
};

export default HotelAgePolicy;
