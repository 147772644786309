import React, { FC } from "react";

import { useForm } from "react-hook-form";
import { pick } from "lodash";
import { TourWithId } from "domain/tour.type";
import CommonLayout from "./CommonLayout";
import Checkbox from "shared/Checkbox/Checkbox";

export interface TourMetaProps {
  tour?: TourWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<TourWithId>) => void;
}

const TourMeta: FC<TourMetaProps> = ({
  tour,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const { register, handleSubmit } = useForm<TourWithId>({
    defaultValues: pick<TourWithId>(tour, ["idealFor", "components"]),
  });

  const onSubmit = (data: TourWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <div className="grid grid-cols-1 gap-3">
          <div>
            <h2 className="text-2xl font-semibold">Trip Components</h2>
          </div>
          <div className="mt-1 mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            <Checkbox label="Trains" {...register("components.trains")} />
            <Checkbox label="Bus" {...register("components.bus")} />
            <Checkbox label="Flights" {...register("components.flights")} />
            <Checkbox label="Hotels" {...register("components.hotels")} />
            <Checkbox label="Transfers" {...register("components.transfers")} />
            <Checkbox
              label="Sightseeing"
              {...register("components.sightseeing")}
            />
            <Checkbox label="Meals" {...register("components.meals")} />
          </div>
          <div>
            <h2 className="text-2xl font-semibold">Ideal For</h2>
          </div>
          <div className="mt-1 mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            <Checkbox label="Couple" {...register("idealFor.couple")} />
            <Checkbox label="Friends" {...register("idealFor.friends")} />
            <Checkbox label="Family" {...register("idealFor.family")} />
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default TourMeta;
