import React, { FC, useState } from "react";

import { ToursListingWithId } from "domain/tours-listing.type";
import BasicInformation from "components/TourListingForm/BasicInformation";
import ListingPlacesOrTours from "components/TourListingForm/ListingPlacesOrTours";
import ToursListingPreview from "components/TourListingForm/ToursListingPreview";

export interface ToursListingFormProps {
  toursListing?: ToursListingWithId;
  onSuccess?: (toursListing: ToursListingWithId) => void;
}

type State = {
  toursListing: ToursListingWithId;
  step: number;
};

export const initialState: ToursListingWithId = {
  id: "",
  slug: "",
  title: "",
  listingType: "PLACE",
  placeIds: [],
  tourIds: [],
  coverPhotos: {
    desktop: [],
    mobile: [],
  },
  marketingPhoto: null as never as ToursListingWithId["marketingPhoto"],
  status: "INACTIVE",
};

const ToursListingForm: FC<ToursListingFormProps> = ({
  toursListing,
  onSuccess = () => {},
}) => {
  let stepComponent;
  const maxStep = 3;
  const [state, setState] = useState<State>({
    toursListing: toursListing || initialState,
    step: 1,
  });

  const handlePrev = () => {
    setState({
      ...state,
      step: Math.max(state.step - 1, 1),
    });
  };

  const handleNext = (partialToursListing: Partial<ToursListingWithId>) => {
    setState({
      ...state,
      step: Math.min(state.step + 1, maxStep),
      toursListing: { ...state.toursListing, ...partialToursListing },
    });
  };

  switch (state.step) {
    case 1:
      stepComponent = (
        <BasicInformation
          step={1}
          toursListing={state.toursListing}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 2:
      stepComponent = (
        <ListingPlacesOrTours
          step={2}
          toursListing={state.toursListing}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 3:
      stepComponent = (
        <ToursListingPreview
          step={3}
          toursListing={state.toursListing}
          onClickPrev={handlePrev}
          onSuccess={onSuccess}
        />
      );
      break;

    default:
      stepComponent = null;
  }

  return <div>{stepComponent}</div>;
};

export default ToursListingForm;
