import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { SupplierWithId } from "domain/supplier.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface SupplierAdvancePaymentValueProps {
  register: UseFormRegister<SupplierWithId>;
  error?: FieldError;
  className?: string;
}

const SupplierAdvancePaymentValue: FC<SupplierAdvancePaymentValueProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label required>Advance Payment %</Label>
      <Input
        type="number"
        className="mt-1"
        placeholder={`Advance percent`}
        {...register("advancePercent", { valueAsNumber: true, required: true })}
      />
      {error && (
        <span className="text-sm text-red-500">
          Please select advance payment percent needs to done to supplier
        </span>
      )}
    </div>
  );
};

export default SupplierAdvancePaymentValue;
