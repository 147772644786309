import React, { useEffect, useRef } from "react";
import Glide from "@glidejs/glide";
import { StarIcon } from "@heroicons/react/24/solid";

import { ReviewsWithMeta } from "domain/review.type";
import NextPrev from "shared/NextPrev/NextPrev";
import useFetch from "hooks/useFetch";
import environment from "config/environment";
import GoogleFullLogo from "./GoogleFullLogo";
import ReviewCard from "./ReviewCard";

interface SocialReviewsProps {
  media: "google";
}

const SocialReviews: React.FC<SocialReviewsProps> = ({ media = "google" }) => {
  const { data: reviewsWithMeta } = useFetch<ReviewsWithMeta>({
    url: `${environment.apiUrl}/social-reviews/${media}`,
  });
  const glideRef = useRef<Glide | null>(null);

  useEffect(() => {
    if (glideRef.current) return;

    glideRef.current = new Glide(".glide", {
      type: "carousel",
      perView: 3,
      gap: 20,
      startAt: 0,
      breakpoints: {
        1280: {
          perView: 3,
        },
        1024: {
          gap: 20,
          perView: 2,
        },
        768: {
          gap: 20,
          perView: 2,
        },
        640: {
          gap: 20,
          perView: 1,
        },
      },
    });

    glideRef.current.mount();

    return () => {
      if (glideRef.current) {
        glideRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (glideRef.current) {
      glideRef.current.update();
    }
  }, [reviewsWithMeta]);

  return (
    <div className="bg-gray-100 rounded-lg">
      <div className="container glide">
        <div className="flex flex-col">
          <h2 className="w-full text-2xl font-semibold text-center mb-8">
            What our customers say
          </h2>
          <div className="flex flex-row justify-between bg-slate-200 px-4 py-4 mb-4 rounded-xl">
            <div className="flex flex-col justify-center">
              <div className="w-28 mr-2">
                <GoogleFullLogo />
              </div>
              <div className="flex flex-row items-center">
                <span className="text-xl font-semibold">
                  {reviewsWithMeta?.averageRating}
                </span>
                <div className="flex text-yellow-400 ml-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <StarIcon
                      key={star}
                      className={`w-5 h-5 ${
                        (reviewsWithMeta?.averageRating || 5) <= 5
                          ? "text-yellow-400"
                          : "text-gray-400"
                      }`}
                    />
                  ))}
                </div>
                <span className="ml-2 text-gray-600">
                  ({reviewsWithMeta?.totalReviews})
                </span>
              </div>
            </div>
            <div className="w-20 flex items-center">
              <NextPrev
                className="w-full justify-between"
                btnClassName="w-8 h-8"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {reviewsWithMeta?.reviews.map((review) => (
                <ReviewCard key={review.id} review={review} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialReviews;
