import React, { FC, useState, useCallback, useEffect } from "react";
import Upload, { UploadProps } from "rc-upload";
import { drop } from "lodash";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { File } from "domain/file.type";
import LazyImage from "shared/LazyImage/LazyImage";
import environment from "config/environment";

interface DragUploaderProps {
  mode: string;
  defaultValue?: File[];
  value?: File[];
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (fileList: File[]) => void;
  maxFiles?: number;
  assetHost?: string;
}

const DragUploader: FC<DragUploaderProps> = ({
  mode,
  defaultValue = [],
  value,
  className = "",
  onChange = () => {},
  maxFiles = 100,
  placeholder = "Select file",
  disabled = false,
  assetHost = environment.assetsUrl,
}) => {
  const [files, setFiles] = useState<File[]>(defaultValue);

  useEffect(()=> {
    if(Array.isArray(value)) {
      setFiles(value)
    }
  }, [value])

  const handleSuccess = useCallback(
    (res: Record<string, unknown>) => {
      const newFileList = [...files, res as unknown as File];
      const droppedFileList = drop(newFileList, newFileList.length - maxFiles);
      setFiles(droppedFileList);
      onChange(droppedFileList);
    },
    [files]
  );

  const handleDeleteFile = (uid: string) => {
    const newFileList = files.filter((file) => file.uid !== uid);
    setFiles(newFileList);
    onChange(newFileList);
  };

  return (
    <div>
      <Uploader
        mode={mode}
        disabled={disabled}
        placeholder={placeholder}
        onSuccess={handleSuccess}
        className={className}
      />
      <div className="w-full flex flex-wrap gap-3 mt-3">
        {files.map((file) => {
          const fileName = file.name.toLowerCase();
          const validImageExtensions = [
            ".jpg",
            ".png",
            ".jpeg",
            ".webp",
            ".gif",
          ];
          const isImage = validImageExtensions.some((ext) =>
            fileName.endsWith(ext)
          );
          return (
            <div key={file.uid} className="relative">
              {isImage && (
                <LazyImage
                  src={`${assetHost}/${file.path}`}
                  className="h-20 border border-primary-500"
                  meta={file.meta}
                />
              )}
              {!isImage && (
                <div className="flex justify-center items-center w-20 h-20 border border-primary-500 bg-primary-200 whitespace-nowrap overflow-hidden text-ellipsis font-thin text-xs">
                  {file.name}
                </div>
              )}
              <button
                className="absolute top-1 right-1"
                onClick={() => handleDeleteFile(file.uid)}
              >
                <i className="las la-times hover:text-primary-700" />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface UploaderProps {
  className: string;
  disabled: boolean;
  mode: string;
  placeholder: string;
  onSuccess: UploadProps["onSuccess"];
}

const Uploader: FC<UploaderProps> = React.memo(
  ({ disabled, mode, onSuccess, placeholder, className }) => {
    const borderClass =
      "border border-dotted rounded-2xl border-neutral-300 hover:border-primary-300 dark:border-neutral-700 hover:border-primary-700";
    const focusClass =
      "focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 dark:focus:ring-primary-600 dark:focus:ring-opacity-25";
    const backgroundClass = `bg-white-900 dark:bg-neutral-900`;
    return (
      <Upload
        name="file"
        multiple={true}
        disabled={disabled}
        action={`${environment.apiUrl}/upload?mode=${mode}`}
        onSuccess={onSuccess}
        className={`w-full ${backgroundClass} ${borderClass} ${focusClass} text-sm font-normal p-2 flex justify-center items-center ${className}`}
      >
        <span>
          <ArrowUpTrayIcon className="w-4 h-4" />
        </span>
        <p>{placeholder}</p>
      </Upload>
    );
  }
);

export default DragUploader;
