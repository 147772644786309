import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import environment from "config/environment";
import { fetcher } from "hooks/useFetch";
import { useAuth } from "context/AuthProvider";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Flex from "shared/Flex/Flex";
import Logo from "shared/Logo/Logo";
import Captcha from "shared/Captcha/Captcha";

type SignInFormInput = {
  username: string;
  password: string;
  captchaToken: string;
};

export interface SignInProps {
  className?: string;
}

type CustomError = {
  code: string;
  userMessage: string;
};

const SignIn: FC<SignInProps> = ({ className = "" }) => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<CustomError[]>([]);
  const { isAuthenticated, onSignIn } = useAuth();
  const {
    control,
    formState: { errors: formErrors },
    register,
    handleSubmit,
  } = useForm<SignInFormInput>();

  useEffect(() => {
    if (isAuthenticated) {
      const redirect = search.get("redirect");
      if (redirect) {
        return navigate(redirect, { replace: true });
      }
      return navigate("/", { replace: true });
    }
  }, [isAuthenticated]);

  const onSubmit = async (data: SignInFormInput) => {
    const result = await fetcher({
      url: `${environment.apiUrl}/sign-in`,
      method: "POST",
      body: JSON.stringify(data),
    });
    if (result.errors) {
      setErrors(result.errors);
    } else {
      onSignIn(result.accessToken, result.refreshToken, result.user);
    }
  };

  return (
    <div
      className={`app-SignIn min-h-screen ${className}`}
      data-app-id="SignIn"
    >
      <Helmet>
        <title>{environment.appName} | Sign In</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <Flex direction="row" justify="center">
          <Logo className="h-14 my-10" />
        </Flex>
        <div className="max-w-md mx-auto space-y-6">
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email / Mobile
              </span>
              <Input
                type="text"
                placeholder="Your email or mobile"
                className="mt-1"
                {...register("username", { required: true })}
              />
              {formErrors.username && (
                <p className="text-red-500 text-xs">
                  Please enter a valid email address / mobile number
                </p>
              )}
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-password" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input
                type="password"
                placeholder="Your password"
                className="mt-1"
                {...register("password", { required: true })}
              />
              {formErrors.password && (
                <p className="text-red-500 text-xs">
                  Please enter a valid password
                </p>
              )}
            </label>

            <div className="mt-3">
              <Controller
                control={control}
                name="captchaToken"
                rules={{ required: true }}
                render={({ field: { onChange } }) => {
                  return <Captcha onChange={onChange} />;
                }}
              />
              {formErrors.captchaToken && (
                <p className="text-red-500 text-xs">
                  Please verify yourself by clicking above
                </p>
              )}
            </div>

            {errors.map((error) => (
              <p key={error.code} className="text-red-500">
                {error.userMessage}
              </p>
            ))}
            <ButtonPrimary type="submit">Sign In</ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user?{" "}
            <Link to="/sign-up" className="text-primary-500">
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
