import React from "react";
import { PlaceWithId } from "domain/place.type";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import PlaceForm from "./PlaceForm";

interface CreatePlaceProps {
  onAddPlace: (place: PlaceWithId) => void;
}

const CreatePlace = ({ onAddPlace }: CreatePlaceProps) => {
  return (
    <Modal
      modalTitle="Add a place"
      className="w-1/2"
      renderTrigger={({ openModal }) => (
        <ButtonPrimary onClick={openModal}>Add a place</ButtonPrimary>
      )}
      renderContent={({ closeModal }) => {
        return (
          <PlaceForm
            onCancel={closeModal}
            onSuccess={(place) => {
              onAddPlace(place);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

export default CreatePlace;
