import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { SupplierWithId } from "domain/supplier.type";
import Label from "shared/Label/Label";
import Textarea from "shared/Textarea/Textarea";

export interface SupplierInternalNotesProps {
  register: UseFormRegister<SupplierWithId>;
  error?: FieldError;
  className?: string;
}

const SupplierInternalNotes: FC<SupplierInternalNotesProps> = ({ register, error, className }) => {
  return (
    <div className={className}>
      <Label>Internal notes</Label>
      <Textarea
        className="mt-1"
        placeholder="Internal notes"
        {...register("internalNotes", { required: false })}
      />
      {error && (
        <span className="text-sm text-red-500">
          Please enter internal notes if any
        </span>
      )}
    </div>
  );
};

export default SupplierInternalNotes;
