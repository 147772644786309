import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { SupplierWithId } from "domain/supplier.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface SupplierBalanceDueDaysProps {
  register: UseFormRegister<SupplierWithId>;
  error?: FieldError;
  className?: string;
}

const SupplierBalanceDueDays: FC<SupplierBalanceDueDaysProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label required>Balance payment due days</Label>
      <Input
        type="number"
        className="mt-1"
        min={0}
        placeholder="Balance payment due days"
        {...register("balanceDueDays", { valueAsNumber: true, required: true, min: 0 })}
      />
      {error && (
        <span className="text-sm text-red-500">
          Please enter no of days before trip date when balance needs to be
          cleared
        </span>
      )}
    </div>
  );
};

export default SupplierBalanceDueDays;
