import React, { FC, ReactNode } from "react";

import Flex from "shared/Flex/Flex";

export interface SiderContentHeadingProps {
  icon?: ReactNode;
  heading?: string;
  actionsComponent?: ReactNode;
  errorsComponent?: ReactNode;
}

const SiderContentHeading: FC<SiderContentHeadingProps> = ({
  icon,
  heading,
  actionsComponent,
  errorsComponent,
}) => {
  return (
    <div className="sticky top-0 z-10 w-full">
      <Flex
        direction="row"
        justify="between"
        align="center"
        className="bg-white w-full px-5 py-2 min-h-[60px]"
      >
        <Flex direction="row" align="center">
          {icon}
          <h1 className="text-xl font-semibold">{heading}</h1>
        </Flex>
        <Flex direction="row" className="space-x-5">
          {actionsComponent}
        </Flex>
      </Flex>
      {errorsComponent}
    </div>
  );
};

export default SiderContentHeading;
