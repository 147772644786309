import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { SupplierWithId } from "domain/supplier.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface SupplierBankAccountHolderProps {
  register: UseFormRegister<SupplierWithId>;
  error?: FieldError;
  className?: string;
}

const SupplierBankAccountHolder: FC<SupplierBankAccountHolderProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label required>Account holder</Label>
      <Input
        className="mt-1"
        placeholder="Account holder"
        {...register("bankAccount.accountHolder", { required: true })}
      />
      {error && (
        <span className="text-sm text-red-500">
          Please enter bank account holder name
        </span>
      )}
    </div>
  );
};

export default SupplierBankAccountHolder;
