import React, { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Checkbox from "shared/Checkbox/Checkbox";

export interface HotelAmenitiesProps {
  register: UseFormRegister<HotelWithId>;
  className?: string;
}

const HotelAmenities: FC<HotelAmenitiesProps> = ({
  register,
  className,
}) => {
  return (
    <div className={className}>
      <Checkbox label="Free toiletries" {...register("amenities.toiletries")} />
      <Checkbox label="Towels" {...register("amenities.towel")} />
      <Checkbox label="WIFI" {...register("amenities.wifi")} />
      <Checkbox label="TV" {...register("amenities.tv")} />
      <Checkbox label="Air conditioning" {...register("amenities.ac")} />
      <Checkbox label="Room Heater" {...register("amenities.roomHeater")} />
      <Checkbox label="Fan" {...register("amenities.fan")} />
      <Checkbox label="Iron" {...register("amenities.iron")} />
      <Checkbox label="Ironing Board" {...register("amenities.ironingBoard")} />
      <Checkbox label="Coffee Kit" {...register("amenities.coffeeKit")} />
      <Checkbox label="Hair dryer" {...register("amenities.hairDryer")} />
      <Checkbox
        label="Cloth dryer stand"
        {...register("amenities.clothStand")}
      />
      <Checkbox label="Refrigerator" {...register("amenities.refrigerator")} />
      <Checkbox label="Extra cushion" {...register("amenities.extraCushion")} />
      <Checkbox label="Slipper" {...register("amenities.slipper")} />
      <Checkbox label="Intercom" {...register("amenities.intercom")} />
      <Checkbox label="Toaster" {...register("amenities.toaster")} />
      <Checkbox
        label="Electric Kettle"
        {...register("amenities.electricKettle")}
      />
    </div>
  );
};

export default HotelAmenities;
