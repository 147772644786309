import React, { FC } from "react";
import { pick } from "lodash";
import { useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import Label from "shared/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { HotelRoomWithId } from "domain/hotel-room.type";

export interface BasicInformationProps {
  room?: HotelRoomWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<HotelRoomWithId>) => void;
}

const BasicInformation: FC<BasicInformationProps> = ({
  room,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HotelRoomWithId>({
    defaultValues: pick<HotelRoomWithId>(room, ["name", "displayName", "description"]),
  });

  const onSubmit = (data: HotelRoomWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Room Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2">
            <Label>Room name (Internal)</Label>
            <Input
              className="mt-1"
              placeholder="Room internal name"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className="text-sm text-red-500">
                Please enter room name
              </span>
            )}
          </div>

          <div className="col-span-2">
            <Label>Room display name</Label>
            <Input
              className="mt-1"
              placeholder="Room display name"
              {...register("displayName", { required: true })}
            />
            {errors.displayName && (
              <span className="text-sm text-red-500">
                Please enter room display name
              </span>
            )}
          </div>

          <div className="col-span-2">
            <Label>Room description</Label>
            <Textarea
              className="mt-1"
              placeholder="Room description"
              {...register("description", { required: true })}
            />
            {errors.description && (
              <span className="text-sm text-red-500">
                Please enter room description
              </span>
            )}
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default BasicInformation;
