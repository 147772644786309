import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import rightImg from "images/pay-us.jpg";
import environment from "config/environment";
import { fetcher } from "hooks/useFetch";
import { CustomError } from "domain/custom-error.type";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { useAuth } from "context/AuthProvider";

export interface PayUsProps {
  className?: string;
}

type PayUsFormInput = {
  name: string;
  mobile: string;
  amount: string;
};

const PayUs: FC<PayUsProps> = ({ className = "" }) => {
  return (
    <div
      className={`app-PayUs overflow-hidden ${className}`}
      data-app-id="PayUs"
    >
      <Helmet>
        <title>{environment.appName} | Pay Us</title>
      </Helmet>
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 items-center ">
            <div className="max-w-md space-y-8">
              <div
                className="p-2 border border-primary-500 items-center leading-none lg:rounded-full flex lg:inline-flex"
                role="alert"
              >
                <span className="font-semibold mx-2 text-left flex-auto">
                  Hi, Please fill the below form to initiate the payment.
                </span>
              </div>
              <PayUsForm />
            </div>
            <div className="hidden md:block flex-grow">
              <img className="w-full" src={rightImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PayUsForm = () => {
  const { user } = useAuth();
  const [errors, setErrors] = useState<CustomError[]>([]);
  const {
    formState: { errors: formErrors, isSubmitting },
    register,
    handleSubmit,
  } = useForm<PayUsFormInput>({
    defaultValues: {
      name: [user?.firstName, user?.lastName].filter(Boolean).join(" ") || "",
      mobile: user?.mobile || "",
    },
  });

  const onSubmit = async (data: PayUsFormInput) => {
    const result = await fetcher({
      url: `${environment.apiUrl}/pay-order`,
      method: "POST",
      body: JSON.stringify(data),
    });

    if (result.errors) {
      setErrors(result.errors);
    } else {
      window.open(
        result.paymentOrder.paymentData.instrumentResponse.redirectInfo.url,
        "_self"
      );
    }
  };

  return (
    <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">Name</span>
        <Input
          type="text"
          placeholder="Name"
          className="mt-1"
          {...register("name", { required: true })}
        />
        {formErrors.name && (
          <p className="text-red-500 text-xs">Please enter your name</p>
        )}
      </label>

      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Mobile no
        </span>
        <Input
          type="text"
          placeholder="Mobile no"
          className="mt-1"
          {...register("mobile", { required: true })}
        />
        {formErrors.mobile && (
          <p className="text-red-500 text-xs">
            Please enter your mobile number
          </p>
        )}
      </label>

      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">Amount</span>
        <Input
          type="number"
          placeholder="Amount"
          className="mt-1"
          {...register("amount", { valueAsNumber: true, required: true, min: 1 })}
        />
        {formErrors.amount && (
          <p className="text-red-500 text-xs">
            Please enter the amount you would like to pay
          </p>
        )}
      </label>

      {errors.map((error) => (
        <p key={error.code} className="text-red-500">
          {error.userMessage}
        </p>
      ))}
      <p className="text-red-500 text-xs">
        We are facing payment gateway issues at the moment. Please try after
        sometime.
      </p>
      <ButtonPrimary type="submit" loading={isSubmitting} disabled={true}>
        Proceed
      </ButtonPrimary>
    </form>
  );
};

export default PayUs;
