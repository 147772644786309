import React, { FC, useState } from "react";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/solid";

import { HotelRoomWithId } from "domain/hotel-room.type";
import environment from "config/environment";
import Flex from "shared/Flex/Flex";
import ModalPhotosGallery from "components/ModalPhotosGallery";
import LazyImage from "shared/LazyImage/LazyImage";

export interface HotelRoomCardProps {
  room: HotelRoomWithId;
  className?: string;
}

const HotelRoomCard: FC<HotelRoomCardProps> = ({ room, className = "" }) => {
  const [showRoomPhotosModal, setShowRoomPhotosModal] = useState(false);
  const {
    id,
    thumbnail,
    name,
    description,
    details: { hasBalcony, isSmoking },
  } = room;

  const imagesPaths = [
    thumbnail?.path,
    ...room.photos.map((photo) => photo.path),
  ];

  return (
    <div
      className={`hotel-room-card flex flex-row group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform transition-shadow ${className}`}
    >
      <div
        className="relative w-60"
        onClick={() => setShowRoomPhotosModal(true)}
      >
        <LazyImage
          src={`${environment.assetsUrl}/${thumbnail?.path}`}
          className="w-full"
          containerClassName="aspect-w-4 aspect-h-3 w-60"
        />
        <ArrowsPointingOutIcon className="absolute h-6 top-2 right-2 text-white cursor-pointer" fill="white" stroke="gray" />
      </div>

      <div className="p-4 space-y-4">
        <div className="space-y-2">
          <Flex
            direction="row"
            className="text-sm text-neutral-500 dark:text-neutral-400"
          ></Flex>
          <div className="flex flex-col">
            <Flex direction="row" className="gap-2 text-xs">
              {hasBalcony && (
                <span className="border border-primary-500 text-primary-500 rounded-full px-2">
                  <i className="las la-building mr-1"></i>Balcony
                </span>
              )}
              {isSmoking ? (
                <span className="border border-primary-500 text-primary-500 rounded-full px-2">
                  <i className="las la-smoking mr-1"></i>Smoking
                </span>
              ) : (
                <span className="border border-red-400 text-red-400 rounded-full px-2">
                  <i className="las la-smoking-ban mr-1 "></i>Non-smoking
                </span>
              )}
            </Flex>
            <h2 className="font-medium capitalize text-lg mt-2">
              <span className="line-clamp-1 break-words">{name}</span>
            </h2>
            <div className="line-clamp-3">{description}</div>
          </div>
        </div>
      </div>

      <ModalPhotosGallery
        isOpen={showRoomPhotosModal}
        title={name}
        imgs={imagesPaths.map((path) => `${environment.assetsUrl}/${path}`)}
        initFocus={0}
        onClose={() => setShowRoomPhotosModal(false)}
        uniqueClassName={`HotelRoomPhotos-${id}`}
      />
    </div>
  );
};

export default HotelRoomCard;
