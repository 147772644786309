import React, { FC, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import environment from "config/environment";
import { HotelWithId } from "domain/hotel.type";
import { PlaceWithId } from "domain/place.type";
import useFetch from "hooks/useFetch";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import HotelsGrid from "./HotelsGrid";
import Loader from "shared/Loader/Loader";
import Modal from "shared/Modal/Modal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import TourRequestDetails from "components/TourDetails/TourRequestDetails";
import LazyImage from "shared/LazyImage/LazyImage";
import SocialReviews from "components/SocialReviews";

export interface PlaceHotelsProps {
  className?: string;
}

const PlaceHotels: FC<PlaceHotelsProps> = ({ className = "" }) => {
  const [search] = useSearchParams();
  const placeId = search.get("id");

  const {
    isLoading: isPlaceLoading,
    data: places = [],
    reFetch: reFetchPlaces,
  } = useFetch<PlaceWithId[]>(
    { url: `${environment.apiUrl}/place/${placeId}` },
    1,
    !placeId
  );
  const {
    isLoading: isHotelsLoading,
    data: hotels = [],
    reFetch: reFetchHotels,
  } = useFetch<HotelWithId[]>(
    { url: `${environment.apiUrl}/hotel?placeId=${placeId}` },
    500,
    !placeId
  );

  const place = useMemo(
    () => places.find((i) => i.id === placeId),
    [places, placeId]
  );

  useEffect(() => {
    if (placeId) {
      reFetchPlaces({
        url: `${environment.apiUrl}/place/${placeId}`,
        reset: true,
      });
      reFetchHotels({
        url: `${environment.apiUrl}/hotel?placeId=${placeId}`,
        reset: true,
      });
    }
  }, [placeId]);

  if (isPlaceLoading || isHotelsLoading) {
    return <Loader />;
  }
  return (
    <div
      className={`app-PlaceHotels bg-gray-100  relative overflow-hidden ${className}`}
      data-app-id="PlaceHotels"
    >
      <Helmet>
        <title>{environment.appName} | Hotels</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="w-full relative">
        {/* <GallerySlider
          uniqueID={`TourPackageCard_${toursListing.id}`}
          images={coverPhotos.desktop.map(
            (image) => `${environment.assetsUrl}/${image?.path}`
          )}
          ratioClass="aspect-w-2 aspect-h-1"
          className="max-h-screen-without-header overflow-hidden will-change-transform"
          imageClass="w-full h-full max-h-screen-without-header object-cover"
          glideOptions={{
            type: "carousel",
          }}
        /> */}
        <LazyImage
          src={`${environment.assetsUrl}/${place?.coverPhotos.desktop[0]?.path}`}
          className="max-h-screen-without-header w-full"
          containerClassName="hidden sm:block"
        />
        <LazyImage
          src={`${environment.assetsUrl}/${place?.coverPhotos.mobile[0]?.path}`}
          className="max-h-screen-without-header w-full"
          containerClassName="sm:hidden"
        />
        <div className="absolute bottom-0 w-full py-10 bg-gradient-to-t from-neutral-900 bg-opacity-40">
          <div
            className="container flex flex-col items-center justify-end text-white"
            style={{ textShadow: "#000 0 0 1px" }}
          >
            <h1 className="font-semibold text-3xl sm:text-4xl md:text-5xl ">
              Hotels in{" "}
              {[place?.name, place?.country.name].filter(Boolean).join(", ")}{" "}
            </h1>
            <hr className="w-full my-4 border-dotted " />

            <Modal
              modalTitle="Request a callback"
              className="w-screen sm:w-96"
              renderTrigger={({ openModal }) => (
                <ButtonPrimary onClick={openModal} className="mt-2">
                  Request a callback
                </ButtonPrimary>
              )}
              renderContent={() => {
                return <TourRequestDetails btnText="Request Callback" />;
              }}
            />
          </div>
        </div>
      </div>
      <div className="container relative overflow-hidden">
        <div className="w-full z-10 my-4 md:my-8">
          <HotelsGrid hotels={hotels} />
        </div>
        <div className="w-full z-10 my-4 md:my-8">
          <SocialReviews media="google" />
        </div>
      </div>
    </div>
  );
};

export default PlaceHotels;
