import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { SupplierWithId } from "domain/supplier.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface SupplierNameProps {
  register: UseFormRegister<SupplierWithId>;
  error?: FieldError;
  className?: string;
}

const SupplierName: FC<SupplierNameProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label required>Supplier name</Label>
      <Input
        className="mt-1"
        placeholder="Supplier name"
        {...register("name", { required: true })}
      />
      {error && (
        <span className="text-sm text-red-500">Please enter supplier name</span>
      )}
    </div>
  );
};

export default SupplierName;
