import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { pick } from "lodash";
import { HotelWithId } from "domain/hotel.type";
import CommonLayout from "./CommonLayout";
import HotelType from "../Inputs/HotelType";
import HotelCode from "../Inputs/HotelCode";
import HotelName from "../Inputs/HotelName";
import HotelDescription from "../Inputs/HotelDescription";

export interface BasicInformationProps {
  hotel?: HotelWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<HotelWithId>) => void;
}

const BasicInformation: FC<BasicInformationProps> = ({
  hotel,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HotelWithId>({
    defaultValues: pick<HotelWithId>(hotel, [
      "type",
      "code",
      "name",
      "description",
    ]),
  });

  const onSubmit = (data: HotelWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Basic Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-2 gap-6">
          <HotelType register={register} error={errors.type} />
          <HotelCode register={register} error={errors.code} />
          <HotelName register={register} error={errors.name} className="col-span-2"/>
          <HotelDescription register={register} error={errors.description} className="col-span-2"/>
        </div>
      </>
    </CommonLayout>
  );
};

export default BasicInformation;
