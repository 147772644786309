import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CommonLayoutProps {
  step: number;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  children: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({
  step,
  children,
  onClickNext,
  onClickPrev,
}) => {
  const showNext = true;
  const showPrev = step > 1;
  const nextText = step === 3 ? "Publish" : "Continue";
  const prevText = "Go Back";
  return (
    <div
      className={`app-CommonLayout px-4 max-w-3xl mx-auto`}
      data-app-id="CommonLayout"
    >
      <div className="space-y-4">
        <div>
          <span className="text-4xl font-semibold">{step}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 3
          </span>
        </div>

        {/* --------------------- */}
        <div className="w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8 ">{children}</div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          {showPrev && (
            <ButtonSecondary onClick={onClickPrev}>{prevText}</ButtonSecondary>
          )}
          {showNext && (
            <ButtonPrimary onClick={onClickNext}>{nextText}</ButtonPrimary>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
