import { FC } from "react";
import { BuildingOfficeIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { SupplierServiceWithId } from "domain/supplier-service.type";
import { SupplierWithId } from "domain/supplier.type";
import { generatePathWithQuery } from "utils/url";
import environment from "config/environment";
import useFetch from "hooks/useFetch";
import List from "shared/List/List";
import Flex from "shared/Flex/Flex";
import Button from "shared/Button/Button";
import ListLoader from "shared/Loader/ListLoader";
import SiderContentHeading from "components/SiderContentHeading";
import CreateSupplierService from "components/SupplierServiceForm/CreateSupplierService";
import SupplierServiceStatus from "components/SupplierServiceForm/SupplierServiceStatus";
import EditSupplierService from "components/SupplierServiceForm/EditSupplierService";
import DeleteSupplierService from "components/SupplierServiceForm/DeleteSupplierService";

export interface SupplierServiceProps {
  supplier?: SupplierWithId;
}

const SupplierServices: FC<SupplierServiceProps> = ({ supplier }) => {
  const {
    isLoading,
    data = [],
    showLoadMore,
    loadMoreData,
    setData,
  } = useFetch<SupplierServiceWithId[]>(
    {
      url: `${environment.apiUrl}/supplier-service?supplierId=${supplier?.id}`,
    },
    50
  );
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: showLoadMore,
    onLoadMore: loadMoreData,
    disabled: false,
    rootMargin: "0px 0px 10px 0px",
  });

  const handleEditSupplierService = (
    supplierService: SupplierServiceWithId
  ) => {
    setData(
      data.map((i) => {
        if (i.id === supplierService.id) {
          return { ...i, ...supplierService } as SupplierServiceWithId;
        }
        return i;
      })
    );
  };

  const handleDeleteSupplierService = (
    supplierService: SupplierServiceWithId
  ) => {
    setData(data.filter((i) => i.id !== supplierService.id));
  };

  return (
    <Flex className="w-full">
      <SiderContentHeading
        icon={<GlobeAltIcon className="w-6 mr-3" />}
        heading={"Services"}
        actionsComponent={
          <CreateSupplierService
            formType="service"
            supplier={supplier}
            onAddSupplierService={(supplierService) =>
              setData([...data, supplierService])
            }
          />
        }
      />
      <div className="p-5 w-full">
        <List
          loading={isLoading}
          items={data}
          rowKey="id"
          renderItem={({ item }) => (
            <div className="grid grid-cols-12 p-2 bg-white">
              <Flex direction="row" align="center" className="col-span-5">
                <BuildingOfficeIcon width={30} />
                <Flex className="ml-2">
                  <Flex direction="row" align="center" className="text-base">
                    <span>{item.service?.name}</span>
                  </Flex>
                  <div className="text-sm text-neutral-400">
                    {item.serviceType === "HOTEL" &&
                      [
                        item.service?.place?.name,
                        item.service?.place?.country.name,
                      ].join(", ")}
                  </div>
                </Flex>
              </Flex>
              <Flex
                direction="row"
                align="center"
                justify="center"
                className="col-span-3"
              >
                <span className="w-full text-center text-base">
                  {item.margin?.b2bPercent ??
                    item.supplier?.b2bMarginPercent ??
                    0}
                  %<span className="mx-1">-</span>
                  {item.margin?.b2cPercent ??
                    item.supplier?.b2cMarginPercent ??
                    0}
                  %
                </span>
              </Flex>
              <Flex
                direction="row"
                justify="end"
                align="center"
                className="col-span-4"
              >
                <SupplierServiceStatus
                  supplierService={item}
                  onSuccess={(supplierService) => {
                    setData(
                      data.map((i) =>
                        i.id === supplierService.id
                          ? { ...i, status: supplierService.status }
                          : i
                      )
                    );
                  }}
                />
                <div className="ml-4" />

                {item.service && item.serviceType === "HOTEL" && (
                  <Button
                    sizeClass="px-5 py-1"
                    targetBlank
                    href={generatePathWithQuery("/hotel/:slug?id=:hotelId", {
                      slug: item.service?.slug,
                      hotelId: item.service?.id,
                    })}
                  >
                    View
                  </Button>
                )}
                <EditSupplierService
                  formType="service"
                  supplierService={item}
                  onEditSupplierService={handleEditSupplierService}
                />
                <DeleteSupplierService
                  formType="service"
                  supplierService={item}
                  onDeleteSupplierService={handleDeleteSupplierService}
                />
              </Flex>
            </div>
          )}
        />
        {(isLoading || showLoadMore) && (
          <div ref={sentryRef}>
            <ListLoader />
          </div>
        )}
      </div>
    </Flex>
  );
};

export default SupplierServices;
