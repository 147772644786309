import React from "react";
import { HotelWithId } from "domain/hotel.type";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import HotelForm from "./HotelForm";

interface CreateHotelProps {
  onAddHotel: (hotel: HotelWithId) => void;
}

const CreateHotel = ({ onAddHotel }: CreateHotelProps) => {
  return (
    <Modal
      modalTitle="Add a hotel"
      fullScreen
      renderTrigger={({ openModal }) => (
        <ButtonPrimary onClick={openModal}>Add a hotel</ButtonPrimary>
      )}
      renderContent={({ closeModal }) => {
        return (
          <HotelForm
            onSuccess={(hotel) => {
              onAddHotel(hotel);
              closeModal();
            }} 
          />
        );
      }}
    />
  );
};

export default CreateHotel;
