import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { SupplierWithId } from "domain/supplier.type";
import Label from "shared/Label/Label";
import Select from "shared/Select/Select";
import { Country } from "domain/country-state.type";
import environment from "config/environment";
import useFetch from "hooks/useFetch";

export interface SupplierCountryProps {
  register: UseFormRegister<SupplierWithId>;
  error?: FieldError;
  className?: string;
}

const SupplierCountry: FC<SupplierCountryProps> = ({
  register,
  error,
  className,
}) => {
  const countriesUrl = `${environment.apiUrl}/site-data/country`;
  const { isLoading: isCountriesLoading, data: countries = [] } = useFetch<
    Country[]
  >({ url: countriesUrl }, 100);

  return (
    <div className={className}>
      <Label required>Country</Label>
      <Select
        className="mt-1"
        isLoading={isCountriesLoading}
        {...register("country.code", {
          required: true,
        })}
      >
        <option value="">Select Country</option>
        {countries.map((country) => {
          return (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          );
        })}
      </Select>
      {error && (
        <span className="text-sm text-red-500">Please select country</span>
      )}
    </div>
  );
};

export default SupplierCountry;
