import React, { FC } from "react";
import { pick } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { HotelRoomWithId } from "domain/hotel-room.type";
import Label from "shared/Label/Label";
import Select from "shared/Select/Select";
import RadioGroup from "shared/RadioGroup/RadioGroup";
import Flex from "shared/Flex/Flex";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";

export interface HotelRoomDetailsProps {
  room?: HotelRoomWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<HotelRoomWithId>) => void;
}

const HotelRoomDetails: FC<HotelRoomDetailsProps> = ({
  room,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HotelRoomWithId>({
    defaultValues: pick<HotelRoomWithId>(room, ["details"]),
  });

  const onSubmit = (data: HotelRoomWithId) => {
    onClickNext({
      ...data,
      details: {
        ...data.details,
        sizeInSqFt: Number(data.details.sizeInSqFt),
        defaultOccupancy: Number(data.details.defaultOccupancy),
        maxOccupancy: Number(data.details.maxOccupancy),
        maxNoOfAdults: Number(data.details.maxNoOfAdults),
        maxNoOfChildrens: Number(data.details.maxNoOfChildrens),
        maxNoOfInfants: Number(data.details.maxNoOfInfants),
      },
    });
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Room Details</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <Label>Room size (in sqft)</Label>
            <Input
              type="number"
              className="mt-1"
              placeholder="Room size"
              min={0}
              {...register("details.sizeInSqFt", { valueAsNumber: true, required: true, min: 0 })}
            />
            {errors.details?.sizeInSqFt && (
              <span className="text-sm text-red-500">
                Please enter room size in sqft
              </span>
            )}
          </div>

          <div>
            <Label>Default Occupancy</Label>
            <Select
              className="mt-1"
              {...register("details.defaultOccupancy", { required: true })}
            >
              <option value="">Select</option>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>
            {errors.details?.defaultOccupancy && (
              <span className="text-sm text-red-500">
                Please select default occupancy of room for which rates are
                applicable
              </span>
            )}
          </div>

          <div className="bg-neutral-100 p-5 grid grid-cols-2 gap-6 col-span-2">
            <h3 className="text-sm font-light col-span-2">
              Maximum number of guests that can stay in the room including extra
              guests with or without bed.
            </h3>
            <div>
              <Label>Maximum Occupancy</Label>
              <Select
                className="mt-1"
                {...register("details.maxOccupancy", { required: true })}
              >
                <option value="">Select</option>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
              {errors.details?.maxOccupancy && (
                <span className="text-sm text-red-500">
                  Please select maximum no of guests allowed in the room
                  including extra guests.
                </span>
              )}
            </div>

            <div>
              <Label>Max Adults</Label>
              <Select
                className="mt-1"
                {...register("details.maxNoOfAdults", { required: true })}
              >
                <option value="">Select</option>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
              {errors.details?.maxNoOfAdults && (
                <span className="text-sm text-red-500">
                  Please select maximum adults allowed in the room including
                  extra guests
                </span>
              )}
            </div>

            <div>
              <Label>Max Childrens</Label>
              <Select
                className="mt-1"
                {...register("details.maxNoOfChildrens", { required: true })}
              >
                <option value="">Select</option>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
              {errors.details?.maxNoOfChildrens && (
                <span className="text-sm text-red-500">
                  Please select maximum childrens allowed in the room including
                  extra guests
                </span>
              )}
            </div>

            <div>
              <Label>Max Infants</Label>
              <Select
                className="mt-1"
                {...register("details.maxNoOfInfants", { required: true })}
              >
                <option value="">Select</option>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
              {errors.details?.maxNoOfInfants && (
                <span className="text-sm text-red-500">
                  Please select maximum infants allowed in the room including
                  extra guests
                </span>
              )}
            </div>
          </div>

          <Flex direction="row" justify="between" className="col-span-2">
            <Label>Balcony</Label>
            <Controller
              name="details.hasBalcony"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <RadioGroup
                    value={value}
                    onChange={onChange}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                  />
                );
              }}
            />
          </Flex>

          <Flex direction="row" justify="between" className="col-span-2">
            <Label>Smoking</Label>
            <Controller
              name="details.isSmoking"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <RadioGroup
                    value={value}
                    onChange={onChange}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                  />
                );
              }}
            />
          </Flex>
        </div>
      </>
    </CommonLayout>
  );
};

export default HotelRoomDetails;
