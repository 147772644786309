import React, { useState } from "react";
import environment from "config/environment";
import { PlaceWithId } from "domain/place.type";
import { CustomError } from "domain/custom-error.type";
import { fetcher } from "hooks/useFetch";
import Modal from "shared/Modal/Modal";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Flex from "shared/Flex/Flex";

interface DeletePlaceProps {
  place: PlaceWithId;
  onDeletePlace: (place: PlaceWithId) => void;
}

const DeletePlace = ({ place, onDeletePlace }: DeletePlaceProps) => {
  return (
    <Modal
      modalTitle={`Delete ${place.name}`}
      className="w-96"
      renderTrigger={({ openModal }) => (
        <Button sizeClass="px-5 py-1" onClick={openModal}>
          Delete
        </Button>
      )}
      renderContent={({ closeModal }) => {
        return (
          <DeletePlaceForm
            place={place}
            onSuccess={() => {
              onDeletePlace(place);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

interface DeletePlaceFormProps {
  place: PlaceWithId;
  onSuccess: () => void;
}

const DeletePlaceForm = ({
  place,
  onSuccess = () => {},
}: DeletePlaceFormProps) => {
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);

  const onSubmit = async () => {
    const result = await fetcher({
      url: `${environment.apiUrl}/place/${place.id}`,
      method: "DELETE",
      body: JSON.stringify({}),
    });

    if (result.errors) {
      setServerErrors(result.errors);
    } else {
      onSuccess();
    }
  };

  return (
    <div>
      <div>Do you want to delete {place.name} ?</div>
      <div className="col-span-2">
        {serverErrors.map((error) => (
          <p key={error.code} className="text-red-500">
            {error.userMessage}
          </p>
        ))}
      </div>
      <Flex direction="row" justify="end" className="mt-4">
        <ButtonPrimary onClick={onSubmit}>Delete</ButtonPrimary>
      </Flex>
    </div>
  );
};

export default DeletePlace;
