import React from "react";
import { PlaceWithId } from "domain/place.type";
import Modal from "shared/Modal/Modal";
import Button from "shared/Button/Button";
import PlaceForm from "./PlaceForm";

interface EditPlaceProps {
  place: PlaceWithId;
  onEditPlace: (place: PlaceWithId) => void;
}

const EditPlace = ({ place, onEditPlace }: EditPlaceProps) => {
  return (
    <Modal
      modalTitle={`Edit ${place.name}`}
      className="w-1/2"
      renderTrigger={({ openModal }) => (
        <Button sizeClass="px-5 py-1" onClick={openModal}>
          Edit
        </Button>
      )}
      renderContent={({ closeModal }) => {
        return (
          <PlaceForm
            place={place}
            onCancel={closeModal}
            onSuccess={(place) => {
              onEditPlace(place);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

export default EditPlace;
