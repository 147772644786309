import React, { useState } from "react";
import { HotelRoomWithId } from "domain/hotel-room.type";
import Modal from "shared/Modal/Modal";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Flex from "shared/Flex/Flex";
import { fetcher } from "hooks/useFetch";
import { CustomError } from "domain/custom-error.type";
import environment from "config/environment";

interface DeleteHotelRoomProps {
  hotelRoom: HotelRoomWithId;
  onDeleteHotelRoom: (hotel: HotelRoomWithId) => void;
}

const DeleteHotelRoom = ({ hotelRoom, onDeleteHotelRoom }: DeleteHotelRoomProps) => {
  return (
    <Modal
      modalTitle={`Delete ${hotelRoom.name}`}
      className="w-96"
      renderTrigger={({ openModal }) => (
        <Button sizeClass="px-5 py-1" onClick={openModal}>
          Delete
        </Button>
      )}
      renderContent={({ closeModal }) => {
        return (
          <DeleteHotelRoomForm
            hotelRoom={hotelRoom}
            onSuccess={() => {
              onDeleteHotelRoom(hotelRoom);
              closeModal();
            }}
          />
        );
      }}
    />
  );
};

interface DeleteHotelRoomFormProps {
  hotelRoom: HotelRoomWithId;
  onSuccess: () => void;
}

const DeleteHotelRoomForm = ({
  hotelRoom,
  onSuccess = () => {},
}: DeleteHotelRoomFormProps) => {
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);

  const onSubmit = async () => {
    const result = await fetcher({
      url: `${environment.apiUrl}/hotel-room/${hotelRoom.id}`,
      method: "DELETE",
      body: JSON.stringify({}),
    });

    if (result.errors) {
      setServerErrors(result.errors);
    } else {
      onSuccess();
    }
  };

  return (
    <div>
      <div>Do you want to delete {hotelRoom.name} ?</div>
      <div className="col-span-2">
        {serverErrors.map((error) => (
          <p key={error.code} className="text-red-500">
            {error.userMessage}
          </p>
        ))}
      </div>
      <Flex direction="row" justify="end" className="mt-4">
        <ButtonPrimary onClick={onSubmit}>Delete</ButtonPrimary>
      </Flex>
    </div>
  );
};

export default DeleteHotelRoom;
