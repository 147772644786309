import React from "react";
import Heading from "shared/Heading/Heading";

const SectionContent = () => {
  return (
    <div className={`app-SectionContent relative`} data-app-id="SectionContent">
      <Heading desc="">Privacy Policy</Heading>
      <p className="mb-6">
        Trust and belief are the pillars of Diskounto Holidays, and they include
        trusting us to do the right thing with your information. Our values
        guide us in providing discounts and offers on our products and services,
        and they help you understand how we approach your information and
        privacy. This Privacy Policy discloses the privacy practices for
        Diskounto Holidays and applies solely to information collected by our
        Website.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        1. Basic Eligibility to Access Services
      </h2>
      <p className="mb-6">
        By accessing this website, we assume you accept these terms and
        conditions in full. Do not continue to use the Diskounto Holidays
        website (
        <a href="https://holidays.diskounto.com" className="underline">
          https://holidays.diskounto.com
        </a>
        ) if you do not accept all of the terms and conditions stated on this
        page. You hereby represent and warrant to the Company that you are at
        least eighteen (18) years of age or above and are capable of entering,
        performing, and adhering to these terms.
      </p>
      <p className="mb-6">
        Individuals under the age of 18 may utilize the services of the site
        only with the involvement and guidance of their parents or guardians.
        You are responsible for regularly checking the Terms of Use to remain in
        compliance with these terms. Your continued use of the website following
        any amendments to the Terms of Use constitutes your acceptance of these
        terms and your agreement to be bound by any such changes.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        2. Your Information Belongs to You
      </h2>
      <p className="mb-6">
        We carefully consider the information we need to provide our services
        and try to limit the collection to only what is necessary. When
        possible, we delete information that we no longer need. In all of our
        work, our guiding principle is that your information belongs to you, and
        we aim to use it only to your benefit.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        3. Protecting Your Information
      </h2>
      <p className="mb-6">
        If a third party requests your personal information, we will refuse to
        share it unless you give us permission or we are legally required to do
        so. If we are legally required to share your personal information, we
        will notify you in advance unless we are legally forbidden from doing
        so.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        4. How We Use Cookies
      </h2>
      <p className="mb-6">
        We employ the use of cookies. By using the Diskounto website, you
        consent to the use of cookies in accordance with our Privacy Policy.
        Most modern interactive websites use cookies to retrieve user details
        for each visit. Cookies are used in some areas of our site to enable
        functionality and ease of use for visitors. Some of our affiliate or
        advertising partners may also use cookies.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        5. Prohibited Activities
      </h2>
      <p className="mb-6">
        You must not engage in the following activities without prior written
        consent:
      </p>
      <ul className="list-disc list-inside mb-6">
        <li>
          Republish material from{" "}
          <a href="https://holidays.diskounto.com" className="underline">
            https://holidays.diskounto.com
          </a>
        </li>
        <li>
          Sell, rent, or sub-license material from{" "}
          <a href="https://holidays.diskounto.com" className="underline">
            https://holidays.diskounto.com
          </a>
        </li>
        <li>
          Reproduce, duplicate, or copy material from{" "}
          <a href="https://holidays.diskounto.com" className="underline">
            https://holidays.diskounto.com
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SectionContent;
