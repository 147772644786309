import { FC, Fragment } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import {
  ArrowTopRightOnSquareIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";

import { TourWithId } from "domain/tour.type";
import Flex from "shared/Flex/Flex";
import LazyImage from "shared/LazyImage/LazyImage";
import Modal from "shared/Modal/Modal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ContactUsToPlan from "components/ContactUsToPlan";
import environment from "config/environment";
import { handlePlural } from "utils/string";
import { getTourNoOfNights } from "utils/tourUtils";
import PhotoSliderCards from "./PhotoSliderCards";
import TourRequestDetails from "./TourRequestDetails";
import "./TourDetails.style.scss";
import { generatePathWithQuery } from "utils/url";

export interface TourDetailsProps {
  isPreview?: boolean;
  tour: TourWithId;
  className?: string;
}

const renderInMultiline = (text = "") => {
  const texts = text.split("\n");
  return texts.map((item, index) =>
    item ? (
      <Fragment key={index}>
        <p>{item}</p>
        {index !== texts.length - 1 && <br />}
      </Fragment>
    ) : (
      ""
    )
  );
};

const TourDetails: FC<TourDetailsProps> = ({
  isPreview = false,
  tour,
  className = "",
}) => {
  const {
    title,
    overview,
    coverPhotos,
    placesWithOptions,
    dayByDay,
    inclusions,
    exclusions,
  } = tour;

  const noOfNights = getTourNoOfNights(tour);
  const nights = handlePlural(noOfNights, "Night", "s");
  const days = handlePlural(noOfNights + 1, "Day", "s");
  return (
    <Flex className={`${className}`}>
      <div className="w-full relative">
        {/* <GallerySlider
          uniqueID={`TourPackageCard_${toursListing.id}`}
          images={coverPhotos.desktop.map(
            (image) => `${environment.assetsUrl}/${image?.path}`
          )}
          ratioClass="aspect-w-2 aspect-h-1"
          className="max-h-screen-without-header overflow-hidden will-change-transform"
          imageClass="w-full h-full max-h-screen-without-header object-cover"
          glideOptions={{
            type: "carousel",
          }}
        /> */}
        <LazyImage
          src={`${environment.assetsUrl}/${coverPhotos.desktop[0]?.path}`}
          className="max-h-screen-without-header w-full"
          containerClassName="hidden sm:block"
          meta={coverPhotos.desktop[0]?.meta}
        />
        <LazyImage
          src={`${environment.assetsUrl}/${coverPhotos.mobile[0]?.path}`}
          className="max-h-screen-without-header w-full"
          containerClassName="sm:hidden"
          meta={coverPhotos.mobile[0]?.meta}
        />
        <div className="absolute bottom-0 w-full py-10 bg-gradient-to-t from-neutral-900 bg-opacity-40">
          <div
            className="container flex flex-col items-center justify-end text-white"
            style={{ textShadow: "#000 0 0 1px" }}
          >
            <span>
              {nights} {days}
            </span>

            <h1 className="font-semibold text-3xl sm:text-4xl md:text-5xl text-center">
              {title}
            </h1>
            <hr className="w-full my-4 border-dotted " />
            <span className="my-2">Starting at ₹ {tour.pricePerPax}/-</span>

            <Modal
              modalTitle="Request a callback"
              className="w-screen sm:w-96"
              renderTrigger={({ openModal }) => (
                <ButtonPrimary onClick={openModal} className="mt-2">
                  Request a callback
                </ButtonPrimary>
              )}
              renderContent={() => {
                return <TourRequestDetails btnText="Request Callback" />;
              }}
            />
          </div>
        </div>
      </div>

      <div className={`w-full z-20 ${!isPreview ? "sticky top-[88px]" : ""}`}>
        <div className="w-full bg-white border-solid border-b border-y-neutral-200 p-2">
          <div className="container rounded-2xl">
            {[
              { key: "overview", text: "Overview" },
              { key: "summary", text: "Summary" },
              { key: "itinerary", text: "Itinerary" },
              { key: "inclusions-exclusions", text: "Exclusions" },
            ].map((item) => (
              <ScrollLink key={item.key} to={item.key} offset={-157} smooth>
                <span className="text-sm font-medium text-neutral-700 leading-10 mr-4 cursor-pointer">
                  {item.text}
                </span>
              </ScrollLink>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full z-10">
        <div className="container bg-white rounded-2xl">
          <main className="relative z-10 mt-4 flex flex-col lg:flex-row text-slate-500">
            <div className="w-full lg:w-3/5 xl:w-2/3 mr-8 mb-8">
              <div id="overview" className="w-full text-base">
                <div>{renderInMultiline(overview)}</div>
              </div>

              <div className="lg:hidden my-8">
                <TourRequestDetails tour={tour} isPreview={isPreview} />
              </div>

              <Heading id="highlights" heading="Highlights" />
              <Highlights placesWithOptions={placesWithOptions} />

              <Heading
                id="summary"
                heading="Summary"
                description="Explore destinations and enjoy comfortable accommodations tailored for you"
              />
              <Summary placesWithOptions={placesWithOptions} />

              <Heading id="itinerary" heading="Itinerary" />
              <DayByDay dayByDay={dayByDay} />

              <Heading
                id="inclusions-exclusions"
                heading="Inclusions & Exclusions"
              />
              <InclusionExclusion
                data={inclusions}
                iconClass="las la-check-circle text-green-500"
              />
              <InclusionExclusion
                data={exclusions}
                iconClass="las la-times-circle text-red-500"
              />
            </div>
            <div className="hidden lg:block flex-grow pb-4">
              <div className="sticky top-40">
                <TourRequestDetails tour={tour} isPreview={isPreview} />
              </div>
            </div>
          </main>
        </div>
      </div>

      <ContactUsToPlan />
    </Flex>
  );
};

const Heading = ({
  id,
  heading,
  description,
}: {
  id: string;
  heading: string;
  description?: string;
}) => {
  return (
    <>
      <h3 id={id} className="text-black text-4xl font-semibold mt-12 mb-3">
        {heading}
      </h3>
      {description && <p className="text-base">{description}</p>}
    </>
  );
};

const Highlights = ({
  placesWithOptions,
}: {
  placesWithOptions: TourWithId["placesWithOptions"];
}) => {
  return (
    <div className="flex flex-col bg-neutral-100 p-8 rounded-xl">
      <div className="mt-3 grid grid-cols-2 gap-6">
        <div>
          <div className="text-sm">TOTAL DURATION</div>
          <div className="text-2xl font-semibold text-black">
            {handlePlural(
              placesWithOptions.reduce((s, p) => s + p.noOfNights, 0),
              "Night",
              "s"
            )}
          </div>
        </div>
        <div>
          <div className="text-sm">ACROSS</div>
          <div className="text-2xl font-semibold text-black">
            {handlePlural(placesWithOptions.length, "City", "", "Cities")}
          </div>
        </div>
        <div className="col-span-2">
          <div className="text-sm ">PLACES COVERED</div>
          <span
            className={`${
              placesWithOptions.length > 3 ? "text-lg" : "text-lg md:text-2xl"
            } font-semibold  text-black`}
          >
            {placesWithOptions
              .map((item) => `${item.place?.name}(${item.noOfNights}N)`)
              .join(" → ")}
          </span>
        </div>
      </div>
    </div>
  );
};

const Summary = ({
  placesWithOptions,
}: {
  placesWithOptions: TourWithId["placesWithOptions"];
}) => {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light text-surface">
              <thead className="border-b border-neutral-200 font-semibold text-black ">
                <tr>
                  <th scope="col" className="px-6 py-4">
                    DAY
                  </th>
                  <th scope="col" className="px-6 py-4">
                    PLACE
                  </th>
                  <th scope="col" className="px-6 py-4">
                    HOTEL OPTIONS
                  </th>
                </tr>
              </thead>
              <tbody>
                {placesWithOptions.map((placesWithOption, index) => {
                  const startDay = placesWithOptions.reduce(
                    (sum, place, i) => sum + (i < index ? place.noOfNights : 0),
                    1
                  );
                  return (
                    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100">
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {startDay} - {startDay + placesWithOption.noOfNights}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {placesWithOption.place?.name}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <ul className="space-y-4">
                          {placesWithOption.hotels?.map((hotel, index) => (
                            <li key={`${index}-${hotel.id}`}>
                              <span>
                                <span className="flex flex-row">
                                  {Array(Number(hotel.details.starRating)).fill(
                                    <StarIcon className="h-4 text-yellow-500" />
                                  )}
                                </span>
                                <Link
                                  className="flex flex-row hover:underline"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  to={generatePathWithQuery(
                                    "/hotel/:slug?id=:hotelId",
                                    {
                                      slug: hotel.slug,
                                      hotelId: hotel.id,
                                    }
                                  )}
                                >
                                  {hotel.name}
                                  <ArrowTopRightOnSquareIcon
                                    width="12"
                                    className="ml-2"
                                  />
                                </Link>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const DayByDay = ({ dayByDay }: { dayByDay: TourWithId["dayByDay"] }) => {
  return (
    <>
      {dayByDay.map((day, index) => (
        <div key={day.title} className="relative pl-8 sm:pl-32 pb-6 group">
          <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-indigo-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
            <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full">
              Day {index + 1}
            </time>
            <div className="relative w-full">
              <Disclosure defaultOpen={index === 0}>
                {({ open }) => (
                  <>
                    <DisclosureButton className="w-full flex flex-row items-center justify-between">
                      <h3 className="text-left text-sm md:text-lg font-bold text-slate-900">
                        {day.title}
                      </h3>
                      {open ? (
                        <MinusCircleIcon className="w-6 text-primary-500" />
                      ) : (
                        <PlusCircleIcon className="w-6 text-primary-500" />
                      )}
                    </DisclosureButton>
                    <DisclosurePanel>
                      <div className="text-slate-500 text-sm md:text-base">
                        {renderInMultiline(day.plan)}
                      </div>
                      <div>
                        <PhotoSliderCards
                          modalTitle={`Day ${index + 1}: ${day.title} `}
                          uniqueClassName={`day-${index}`}
                          photos={day.photos}
                        />
                      </div>
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const InclusionExclusion = ({
  data,
  iconClass = "",
}: {
  data: string[];
  iconClass: string;
}) => {
  return (
    <ul className="w-full">
      {data.map((data) => (
        <li className="flex items-start space-x-2 space-y-1">
          <i className={`${iconClass} text-2xl mt-1`}></i>
          <span className="text-base text-slate-500">{data}</span>
        </li>
      ))}
    </ul>
  );
};
export default TourDetails;
