import React, { FC } from "react";
import { PropagateLoader } from "react-spinners";

export interface ListLoaderProps {
  className?: string;
}

const ListLoader: FC<ListLoaderProps> = ({ className = "" }) => {
  return (
    <div
      className={`app-ListLoader flex flex-row items-center justify-center my-2 ${className}`}
    >
      <PropagateLoader color="#36d7b7" />
    </div>
  );
};

export default ListLoader;
