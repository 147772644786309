import React, { FC, ReactNode } from "react";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";

import { SupplierWithId } from "domain/supplier.type";
import SiderContentHeading from "components/SiderContentHeading";

export interface SupplierHeadingProps {
  supplier?: SupplierWithId;
  actionsComponent?: ReactNode;
  errorsComponent?: ReactNode;
}

const SupplierHeading: FC<SupplierHeadingProps> = ({
  supplier,
  actionsComponent,
  errorsComponent,
}) => {
  return (
    <SiderContentHeading
      icon={<BuildingOfficeIcon className="w-6 mr-3" />}
      heading={supplier?.name}
      actionsComponent={actionsComponent}
      errorsComponent={errorsComponent}
    />
  );
};

export default SupplierHeading;
