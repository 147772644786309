import { useState } from "react";
import { Switch } from "@headlessui/react";

import { ToursListingWithId } from "domain/tours-listing.type";
import environment from "config/environment";
import { fetcher } from "hooks/useFetch";

const ToursListingStatus = ({
  toursListing,
  onSuccess,
}: {
  toursListing: ToursListingWithId;
  onSuccess: (toursListing: ToursListingWithId) => void;
}) => {
  const [loading, setLoading] = useState(false);

  const handleChangeSwitch = async (checked: boolean) => {
    setLoading(true);
    try {
      const path = toursListing?.id
        ? `/tours-listing/${toursListing?.id}`
        : "/tours-listing";
      const method = toursListing?.id ? "PUT" : "POST";
      const result = await fetcher({
        url: `${environment.apiUrl}${path}`,
        method,
        body: JSON.stringify({ status: checked ? "ACTIVE" : "INACTIVE" }),
      });
      if (result.errors) {
        //setServerErrors(result.errors);
      } else {
        onSuccess(result.toursListing as ToursListingWithId);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const enabled = toursListing.status === "ACTIVE";
  return (
    <Switch
      disabled={loading}
      checked={enabled}
      onChange={() => handleChangeSwitch(!enabled)}
      className={`${
        enabled ? "bg-green-600" : "bg-red-400"
      } relative inline-flex h-6 w-11 items-center rounded-full`}
    >
      <span className="sr-only">{enabled ? "Active" : "Inactive"}</span>
      <span
        className={`${
          enabled ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  );
};

export default ToursListingStatus;
