import React, { FC, useState } from "react";

import BasicInformation from "components/HotelRoomForm/BasicInformation";
import { HotelRoomWithId } from "domain/hotel-room.type";
import HotelRoomDetails from "components/HotelRoomForm/HotelRoomDetails";
import HotelRoomPhotos from "components/HotelRoomForm/HotelRoomPhotos";
import HotelRoomPreview from "components/HotelRoomForm/HotelRoomPreview";

export interface HotelRoomFormProps {
  hotelId?: string;
  room?: HotelRoomWithId;
  onSuccess?: (room: HotelRoomWithId) => void;
}

type State = {
  room: HotelRoomWithId;
  step: number;
};

export const initialState: HotelRoomWithId = {
  hotelId: "",
  id: "",
  name: "",
  displayName: "",
  description: "",
  details: {
    sizeInSqFt: null as unknown as number,
    defaultOccupancy: null as unknown as number,
    maxOccupancy: null as unknown as number,
    maxNoOfAdults: null as unknown as number,
    maxNoOfChildrens: null as unknown as number,
    maxNoOfInfants: null as unknown as number,
    isSmoking: false,
    hasBalcony: false,
  },
  thumbnail: undefined,
  photos: [],
  status: "ACTIVE",
};

const HotelRoomForm: FC<HotelRoomFormProps> = ({
  hotelId,
  room,
  onSuccess = () => {},
}) => {
  let stepComponent;
  const [state, setState] = useState<State>({
    room: room || {
      ...initialState,
      hotelId: hotelId || initialState.hotelId,
    },
    step: 1,
  });

  const handlePrev = () => {
    setState({
      ...state,
      step: Math.max(state.step - 1, 1),
    });
  };

  const handleNext = (partialHotelRoom: Partial<HotelRoomWithId>) => {
    setState({
      ...state,
      step: Math.min(state.step + 1, 6),
      room: { ...state.room, ...partialHotelRoom },
    });
  };

  switch (state.step) {
    case 1:
      stepComponent = (
        <BasicInformation
          step={1}
          room={state.room}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 2:
      stepComponent = (
        <HotelRoomDetails
          step={2}
          room={state.room}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 3:
      stepComponent = (
        <HotelRoomPhotos
          step={3}
          room={state.room}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 4:
      stepComponent = (
        <HotelRoomPreview
          step={4}
          room={state.room}
          onClickPrev={handlePrev}
          onSuccess={onSuccess}
        />
      );
      break;

    default:
      stepComponent = null;
  }

  return <div>{stepComponent}</div>;
};

export default HotelRoomForm;
