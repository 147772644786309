import React, { FC, useState } from "react";

import { HotelWithId } from "domain/hotel.type";
import BasicInformationForm from "components/HotelForm/StepForm/BasicInformationForm";
import HotelLocationForm from "components/HotelForm/StepForm/HotelLocationForm";
import HotelDetailsForm from "components/HotelForm/StepForm/HotelDetailsForm";
import HotelAmenitiesForm from "components/HotelForm/StepForm/HotelAmenitiesForm";
import HotelPhotosForm from "components/HotelForm/StepForm/HotelPhotosForm";
import HotelPreview from "components/HotelForm/StepForm/HotelPreview";

export interface HotelFormProps {
  hotel?: HotelWithId;
  onSuccess?: (hotel: HotelWithId) => void;
}

type State = {
  hotel: HotelWithId;
  step: number;
};

export const initialState: HotelWithId = {
  id: "",
  type: "",
  code: "",
  name: "",
  slug: "",
  description: "",
  placeId: "",
  address: {
    street: "",
    zip: "",
    googlePlaceId: "",
    coordinates: [15.2993, 74.124],
  },
  details: {
    checkOutTime: "11:00",
    checkInTime: "14:00",
    starRating: 3,
    noOfRooms: 20,
    hasSwimmingPool: true,
    hasRestaurant: true,
    hasParking: true,
    hasGym: false,
    hasSpa: false,
  },
  amenities: {
    toiletries: true,
    towel: true,
    wifi: true,
    tv: true,
    roomHeater: false,
    ac: true,
    fan: false,
    iron: false,
    ironingBoard: false,
    coffeeKit: false,
    hairDryer: false,
    clothStand: false,
    refrigerator: false,
    extraCushion: false,
    slipper: false,
    intercom: false,
    toaster: false,
    electricKettle: false,
  },
  thumbnail: undefined,
  photos: [],
  rules: [],
  agePolicy: {
    infant: { min: 0, max: 5 },
    child: { min: 6, max: 10 },
    adult: { min: 11, max: 100 },
  },
};

const HotelForm: FC<HotelFormProps> = ({ hotel, onSuccess = () => {} }) => {
  let stepComponent;
  const [state, setState] = useState<State>({
    hotel: hotel || initialState,
    step: 1,
  });

  const handlePrev = () => {
    setState({
      ...state,
      step: Math.max(state.step - 1, 1),
    });
  };

  const handleNext = (partialHotel: Partial<HotelWithId>) => {
    setState({
      ...state,
      step: Math.min(state.step + 1, 6),
      hotel: { ...state.hotel, ...partialHotel },
    });
  };

  switch (state.step) {
    case 1:
      stepComponent = (
        <BasicInformationForm
          step={1}
          hotel={state.hotel}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 2:
      stepComponent = (
        <HotelLocationForm
          step={2}
          hotel={state.hotel}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 3:
      stepComponent = (
        <HotelDetailsForm
          step={3}
          hotel={state.hotel}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 4:
      stepComponent = (
        <HotelAmenitiesForm
          step={4}
          hotel={state.hotel}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 5:
      stepComponent = (
        <HotelPhotosForm
          step={5}
          hotel={state.hotel}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 6:
      stepComponent = (
        <HotelPreview
          step={6}
          hotel={state.hotel}
          onClickPrev={handlePrev}
          onSuccess={onSuccess}
        />
      );
      break;

    default:
      stepComponent = null;
  }

  return <div>{stepComponent}</div>;
};

export default HotelForm;
