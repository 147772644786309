import React, { FC } from "react";
import { Helmet } from "react-helmet";

import BgGlassmorphism from "components/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection";

import SectionContent from "./SectionContent";
import environment from "config/environment";

export interface TermsOfUseProps {
  className?: string;
}

const TermsOfUse: FC<TermsOfUseProps> = ({ className = "" }) => {
  return (
    <div
      className={`app-TermsOfUse overflow-hidden relative ${className}`}
      data-app-id="TermsOfUse"
    >
      <Helmet>
        <title>{environment.appName} | Terms of use</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container py-6 lg:py-12 space-y-16 lg:space-y-28">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionContent />
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
