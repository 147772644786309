import React, { useState } from "react";

import { HotelWithId } from "domain/hotel.type";
import { HotelRoomWithId } from "domain/hotel-room.type";
import Button from "shared/Button/Button";
import Flex from "shared/Flex/Flex";
import List from "shared/List/List";
import LazyImage from "shared/LazyImage/LazyImage";
import environment from "config/environment";
import AddEditHotelRoom from "./AddEditHotelRoom";
import HotelHeading from "../HotelHeading";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HotelRoomStatus from "./HotelRoomStatus";
import DeleteHotelRoom from "./DeleteHotelRoom";

interface HotelRoomsProps {
  hotel: HotelWithId;
  onUpdateHotel: (hotel: HotelWithId) => void;
}

type State = {
  modalData?: HotelRoomWithId;
  showModal: boolean;
};

const HotelRooms = ({ hotel, onUpdateHotel }: HotelRoomsProps) => {
  const [state, setState] = useState<State>({ showModal: false });
  return (
    <Flex className="w-full">
      <HotelHeading
        hotel={hotel}
        actionsComponent={
          <ButtonPrimary
            bordered
            className="w-40"
            onClick={() => setState({ modalData: undefined, showModal: true })}
          >
            Add New Room
          </ButtonPrimary>
        }
      />

      {/* <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" /> */}
      <List
        items={hotel.rooms || []}
        rowKey="id"
        className="p-5 w-full"
        emptyMessage="No room available"
        renderItem={({ item }) => (
          <div className="grid grid-cols-12 p-3 bg-white">
            <Flex direction="row" align="center" className="col-span-6">
              <LazyImage
                src={`${environment.assetsUrl}/${item.thumbnail?.path}`}
                className="w-20 h-20 border border-primary-500 rounded-sm"
                containerClassName="!w-20"
              />
              <Flex className="flex-1 ml-2">
                <div className="text-base">{item.name}</div>
                <div className="text-sm text-neutral-400 line-clamp-2">
                  {item.description}
                </div>
              </Flex>
            </Flex>
            <Flex
              direction="row"
              justify="end"
              align="center"
              className="col-span-6"
            >
              <HotelRoomStatus
                room={item}
                onSuccess={(room: HotelRoomWithId) => {
                  onUpdateHotel({
                    ...hotel,
                    rooms: (hotel.rooms || []).map((i) =>
                      i.id === room.id ? room : i
                    ),
                  });
                }}
              />
              <Button
                className="ml-4"
                onClick={() =>
                  setState((state) => ({
                    ...state,
                    modalData: item,
                    showModal: true,
                  }))
                }
              >
                Edit
              </Button>
              <DeleteHotelRoom
                hotelRoom={item}
                onDeleteHotelRoom={(hotelRoom) => {
                  onUpdateHotel({
                    ...hotel,
                    rooms: (hotel.rooms || []).filter(
                      (i) => i.id !== hotelRoom.id
                    ),
                  });
                }}
              />
            </Flex>
          </div>
        )}
      />
      <AddEditHotelRoom
        hotelId={hotel.id}
        isOpen={state.showModal}
        onCloseModal={() =>
          setState({ modalData: undefined, showModal: false })
        }
        room={state.modalData}
        onAddEditHotelRoom={(room: HotelRoomWithId) => {
          const existingRooms = hotel.rooms || [];
          const isNewRoom = !existingRooms.find((i) => i.id === room.id);
          onUpdateHotel({
            ...hotel,
            rooms: isNewRoom
              ? [...existingRooms, room]
              : existingRooms.map((i) => (i.id === room.id ? room : i)),
          });
        }}
      />
    </Flex>
  );
};

export default HotelRooms;
