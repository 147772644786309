import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

import environment from "config/environment";
import { fetcher } from "hooks/useFetch";
import { CustomError } from "domain/custom-error.type";
import { HotelRoomWithId } from "domain/hotel-room.type";
import HotelRoomCard from "components/HotelRoomCard";
import CommonLayout from "./CommonLayout";

export interface HotelRoomPreviewProps {
  room?: HotelRoomWithId;
  step: number;
  onClickPrev: () => void;
  onSuccess: (data: HotelRoomWithId) => void;
}

const HotelRoomPreview: FC<HotelRoomPreviewProps> = ({
  room,
  step,
  onClickPrev = () => {},
  onSuccess = () => {},
}) => {
  const { handleSubmit } = useForm<HotelRoomWithId>();
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);

  const onSubmit = async () => {
    const path = room?.id ? `/hotel-room/${room?.id}` : '/hotel-room'
    const method = room?.id ? "PUT" : "POST"
    const result = await fetcher({
      url: `${environment.apiUrl}${path}`,
      method,
      body: JSON.stringify(room),
    });
    if (result.errors) {
      setServerErrors(result.errors);
    } else {
      onSuccess(result.hotelRoom as HotelRoomWithId);
    }
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Congratulations 🎉</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div>
          <h3 className="text-lg font-semibold">
            This is how the room will look like to guests
          </h3>
          <div className="max-w-full">
            <HotelRoomCard
              className="mt-8"
              room={{ id: "", ...room } as HotelRoomWithId}
            />
          </div>
        </div>
        <div className="col-span-2">
          {serverErrors.map((error) => (
            <p key={error.code} className="text-red-500">
              {error.userMessage}
            </p>
          ))}
        </div>
      </>
    </CommonLayout>
  );
};

export default HotelRoomPreview;
