import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import React from "react";

interface ExpandCollapseProps {
  width?: number;
  className?: string;
  expanded: boolean;
  onToggle: (value: boolean) => void;
}

const ExpandCollapse: React.FC<ExpandCollapseProps> = ({
  width = 30,
  expanded,
  className = "",
  onToggle,
}) => {
  return (
    <>
      <PlusCircleIcon
        width={width}
        className={`ml-1 text-primary-500 cursor-pointer ${
          expanded ? "hidden" : "block"
        } ${className}`}
        onClick={() => onToggle(true)}
      />
      <MinusCircleIcon
        width={width}
        className={`ml-1 text-primary-500 cursor-pointer ${
          expanded ? "block" : "hidden"
        } ${className}`}
        onClick={() => onToggle(false)}
      />
    </>
  );
};

export default ExpandCollapse;
