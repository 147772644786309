import React, { ReactNode, useState } from "react";
import { DayPickerSingleDateController } from "react-dates-17";
import { usePopper } from "react-popper";
import moment, { Moment } from "moment";

interface DateSelectProps {
  children: ReactNode;
  initialDate?: Moment | null;
  onDateChange?: (date: Moment | null) => void;
  isOutsideRange?: (day: Moment) => boolean;
}

const DateSelect: React.FC<DateSelectProps> = ({
  children,
  initialDate = null,
  onDateChange,
  isOutsideRange = () => false,
}) => {
  const [date, setDate] = useState<Moment | null>(initialDate);
  const [showPicker, setShowPicker] = useState(false);

  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(
    null
  );
  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>(
    null
  );

  const { styles, attributes } = usePopper(triggerElement, popoverElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "preventOverflow",
        options: { boundary: "clippingParents" },
      },
      {
        name: "offset",
        options: { offset: [0, 8] },
      },
    ],
  });

  const handleDateChange = (newDate: Moment | null) => {
    setDate(newDate);
    onDateChange?.(newDate);
    setShowPicker(false);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <div
        ref={setTriggerElement}
        onClick={() => setShowPicker((prev) => !prev)}
        className="focus:text-primary-500"
        style={{ cursor: "pointer", display: "inline-block" }}
      >
        {children || <button>Select Date</button>}
      </div>

      {showPicker && (
        <div
          ref={setPopoverElement}
          style={{...styles.popper, zIndex: 1000}}
          {...attributes.popper}
        >
          <DayPickerSingleDateController
            date={date}
            initialVisibleMonth={() => moment()}
            onDateChange={handleDateChange}
            focused={true}
            onFocusChange={() => {}}
            isOutsideRange={isOutsideRange}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
          />
        </div>
      )}
    </div>
  );
};

export default DateSelect;
