import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";

import environment from "config/environment";
import { generatePathWithQuery } from "utils/url";
import Image from "shared/LazyImage/LazyImage";
import { PlaceWithId } from "domain/place.type";
import { handlePlural } from "utils/string";
import CountryFlag from "shared/CountryFlag/CountryFlag";

export interface DestinationCardOutlinedProps {
  className?: string;
  destination: PlaceWithId;
  showHotels?: boolean;
}

export const DestinationCardOutlined: FC<DestinationCardOutlinedProps> = ({
  className = "",
  destination,
  showHotels = false,
}) => {
  const navigate = useNavigate();
  const {
    id: placeId,
    slug,
    name,
    country,
    thumbnail,
    noOfTours,
    noOfHotels,
  } = destination;
  const isCardClickable = !showHotels;
  return (
    <button
      onClick={() => {
        if (isCardClickable) {
          navigate(
            generatePathWithQuery(
              "/place/:slug/holiday-packages?id=:placeId",
              { placeId, slug }
            )
          );
        }
      }}
      className={`app-DestinationCardOutlined p-4 relative flex items-center [ app-box-has-hover ] [ app-dark-box-bg-has-hover ] ${
        isCardClickable ? "cursor-pointer" : "cursor-default"
      }  ${className}`}
      data-app-id="DestinationCardOutlined"
    >
      <CountryFlag
        countryCode={country.code}
        title={country.name}
        className="absolute right-4 top-4 w-5"
      />

      <div className="relative flex-shrink-0 w-24 overflow-hidden">
        <Image
          src={`${environment.assetsUrl}/${thumbnail.path}`}
          className="w-full h-full"
          containerClassName="absolute inset-0 aspect-w-3 aspect-h-4 rounded-2xl "
        />
      </div>
      <div className="ml-4 flex-grow overflow-hidden text-left">
        <span
          className={`mt-2 text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1`}
        >
          {country.name}
        </span>
        <h2 className="text-base font-medium">
          <span className="line-clamp-1">{name}</span>
        </h2>
        {noOfTours ? (
          <Link
            to={generatePathWithQuery(
              "/place/:slug/holiday-packages?id=:placeId",
              { placeId, slug }
            )}
            className={`mt-2 text-sm underline text-neutral-500 dark:text-neutral-400 line-clamp-1`}
          >
            {noOfTours ? handlePlural(noOfTours, "package", "s") : ""}
          </Link>
        ) : (
          <></>
        )}
        {showHotels && noOfHotels ? (
          <Link
            to={generatePathWithQuery("/place/:slug/hotels?id=:placeId", {
              slug,
              placeId,
            })}
            className={`mt-2 text-sm underline text-neutral-500 dark:text-neutral-400 line-clamp-1`}
          >
            {noOfHotels ? handlePlural(noOfHotels, "hotel", "s") : ""}
          </Link>
        ) : (
          <></>
        )}
      </div>
    </button>
  );
};

export default DestinationCardOutlined;
