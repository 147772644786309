import { TourWithId } from "domain/tour.type";

export const getTourNoOfNights = (tour?: TourWithId) => {
  if (!tour) {
    return 0;
  }
  return tour.placesWithOptions.reduce(
    (sum, place) => sum + Number(place.noOfNights),
    0
  );
};
