import React from "react";
import Heading from "shared/Heading/Heading";

const SectionContent = () => {
  return (
    <div className={`app-SectionContent relative`} data-app-id="SectionContent">
      <Heading desc="">Cancellation and Refund</Heading>

      <p className="text-base lg:text-lg  mb-4">
        At Diskounto Holidays, we understand that sometimes plans change, and
        cancellations may be necessary. We strive to offer the best possible
        service and flexibility to our guests, and we will always do our utmost
        to provide a fair and transparent process for cancellations and refunds.
        Please take a moment to review our policy to ensure you are fully
        informed.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">
        1. General Cancellation Policy
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">
          <strong>Cancellation Charges:</strong> Cancellation charges are
          determined based on the timing of the cancellation relative to the
          travel date and the specific package booked. The charges will be
          calculated as a percentage of the total ground package cost as per the
          detailed cancellation timeline below.
        </li>
        <li className="mb-2">
          <strong>Natural Calamities and Unforeseen Events:</strong> In the
          event of natural calamities, severe weather conditions, or other
          unforeseen circumstances beyond our control, Diskounto Holidays will
          endeavor to provide the maximum possible refund. This is subject to
          agreements with our trade partners/vendors, and some costs may be
          non-refundable.
        </li>
        <li className="mb-2">
          <strong>Cancellations Due to Personal Reasons:</strong> If you need to
          cancel your trip due to personal reasons, a minimum cancellation fee
          of 20% of the total package cost will apply. The specific cancellation
          fee will be communicated at the time of cancellation, considering
          factors such as the timing and nature of the booking.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">2. Refund Processing</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">
          <strong>Refund Timeline:</strong> Refunds are typically processed
          within 5-10 working days from the date of cancellation. However, for
          any paid activities that are rendered non-operational due to
          unforeseen reasons, refunds may take up to 45 days to be processed.
        </li>
        <li className="mb-2">
          <strong>Refund for Unconfirmed Bookings:</strong> In cases where a
          booking is not confirmed, a full refund of the booking amount will be
          processed. Diskounto Holidays is not obligated to provide alternate
          bookings or compensation for unconfirmed bookings. Any subsequent
          bookings will be treated as new transactions.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">
        3. Cancellation Timeline
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">
          <strong>More than 30 Days Before Travel:</strong> 20% of the ground
          package cost will be deducted.
        </li>
        <li className="mb-2">
          <strong>30 to 21 Days Before Travel:</strong> 30% of the ground
          package cost will be deducted.
        </li>
        <li className="mb-2">
          <strong>20 to 10 Days Before Travel:</strong> 50% of the ground
          package cost will be deducted.
        </li>
        <li className="mb-2">
          <strong>14 to 10 Days Before Travel:</strong> 75% of the ground
          package cost will be deducted.
        </li>
        <li className="mb-2">
          <strong>Less Than 10 Days Before Travel:</strong> 100% of the ground
          package cost will be deducted.
        </li>
        <li className="mb-2">
          <strong>Flight/Train/Cruise Cancellations:</strong> Cancellations for
          flights, trains, or cruises will be handled according to the
          respective policies of the service providers. Additional service
          charges and taxes may apply. Diskounto Holidays is not responsible for
          changes or cancellations made by airlines, railways, or cruise
          operators.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">
        4. Specific Refund Terms
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">
          <strong>Paid Activities:</strong> If any paid activity included in
          your package becomes non-operational due to unforeseen circumstances,
          we will process a refund for that activity. The refund will be
          processed within 45 days from the date of the activity becoming
          non-operational.
        </li>
        <li className="mb-2">
          <strong>Complimentary Activities:</strong> No refunds will be
          processed for activities or services that are provided complimentary
          by Diskounto Holidays or third parties.
        </li>
        <li className="mb-2">
          <strong>Exclusions:</strong> Entrance fees, parking charges, guide
          fees, and other items not specifically mentioned as inclusions in the
          package are not covered by the package price and will not be refunded.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">
        5. Modifications and Third-Party Cancellations
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">
          <strong>Itinerary Modifications:</strong> Diskounto Holidays reserves
          the right to modify the itinerary due to reasons including but not
          limited to Force Majeure events, strikes, festivals, weather
          conditions, traffic issues, overbooking, or closure of attractions.
          While we will make every effort to provide suitable alternate
          arrangements, we will not be liable for any additional costs or
          compensation claims arising from such modifications.
        </li>
        <li className="mb-2">
          <strong>Third-Party Cancellations:</strong> Diskounto Holidays is not
          responsible for cancellations made by hosts, vendors, or any third
          parties involved in your travel arrangements. We cannot be held liable
          for any losses, damages, or additional expenses incurred due to such
          cancellations.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">
        6. Responsibility for Charges
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">
          <strong>Charges and Fees:</strong> The user is fully responsible for
          all charges, fees, duties, taxes, and assessments arising from the use
          of our services. This includes any additional costs incurred due to
          modifications, cancellations, or other changes in travel plans.
        </li>
      </ul>
    </div>
  );
};

export default SectionContent;
