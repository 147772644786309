import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { get, pick } from "lodash";
import { TourWithId } from "domain/tour.type";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import Flex from "shared/Flex/Flex";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { TrashIcon } from "@heroicons/react/24/outline";

export interface InclusionsAndExclusionsProps {
  tour?: TourWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<TourWithId>) => void;
}

const InclusionsAndExclusions: FC<InclusionsAndExclusionsProps> = ({
  tour,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TourWithId>({
    defaultValues: pick<TourWithId>(tour, ["inclusions", "exclusions"]),
  });

  const inclusions = watch("inclusions");
  const exclusions = watch("exclusions");

  const onSubmit = (data: TourWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <div className="grid grid-cols-1 gap-3">
          <div>
            <h2 className="text-2xl font-semibold">Inclusions</h2>
          </div>
          {inclusions.map((inclusion, index) => {
            return (
              <div key={index}>
                <Flex  direction="row" align="center">
                  <Input
                    className="mt-1"
                    placeholder="Enter an inclusion"
                    {...register(`inclusions.${index}`, { required: true })}
                  />

                  <TrashIcon
                    className="ml-2 w-5 h-5 hover:text-red-800 cursor-pointer"
                    onClick={() => {
                      const newInclusions = [...inclusions];
                      newInclusions.splice(index, 1);
                      setValue("inclusions", newInclusions);
                    }}
                  />
                </Flex>
                {get(errors, `inclusions.${index}`) && (
                  <span className="text-sm text-red-500">
                    Please enter the inclusion
                  </span>
                )}
              </div>
            );
          })}
          <ButtonSecondary
            className="font-thin text-sm border-dotted border-neutral-400 hover:border-primary-400 rounded-2xl dark:border-neutral-700"
            onClick={() => setValue("inclusions", [...inclusions, ""])}
          >
            + Add more inclusion
          </ButtonSecondary>
          <div>
            <h2 className="text-2xl font-semibold">Exclusions</h2>
          </div>
          {exclusions.map((exclusion, index) => {
            return (
              <div key={index}>
                <Flex direction="row" align="center">
                  <Input
                    className="mt-1 text-sm font-normal h-11 px-4 py-3"
                    placeholder="Enter an exclusion"
                    {...register(`exclusions.${index}`, { required: true })}
                  />
                  <TrashIcon
                    className="ml-2 w-5 h-5 hover:text-red-800 cursor-pointer"
                    onClick={() => {
                      const newExclusions = [...exclusions];
                      newExclusions.splice(index, 1);
                      setValue("exclusions", newExclusions);
                    }}
                  />
                </Flex>
                {get(errors, `exclusions.${index}`) && (
                  <span className="text-sm text-red-500">
                    Please enter the exclusion
                  </span>
                )}
              </div>
            );
          })}
          <ButtonSecondary
            className="font-thin text-sm border-dotted border-neutral-400 hover:border-primary-400 rounded-2xl dark:border-neutral-700"
            onClick={() => setValue("exclusions", [...exclusions, ""])}
          >
            + Add more exclusion
          </ButtonSecondary>
        </div>
      </>
    </CommonLayout>
  );
};

export default InclusionsAndExclusions;
