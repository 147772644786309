import React, { SelectHTMLAttributes, useEffect, useState } from "react";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  sizeClass?: string;
  isLoading?: boolean;
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className = "",
      sizeClass = "h-11",
      children,
      isLoading = false,
      ...args
    },
    ref
  ) => {
    const [renderId, setRenderId] = useState(1);
   
    useEffect(() => {
      setRenderId(renderId + 1)
    }, [isLoading]);
    
    return (
      <select
        key={renderId}
        ref={ref}
        className={`app-Select ${sizeClass} ${className} block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white disabled:opacity-100 disabled:bg-neutral-100 dark:border-neutral-700 dark:focus:ring-primary-600 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
        {...args}
      >
        {children}
      </select>
    );
  }
);

export default Select;
