import { FC } from "react";
import { BuildingOfficeIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { SupplierServiceWithId } from "domain/supplier-service.type";
import environment from "config/environment";
import useFetch from "hooks/useFetch";
import List from "shared/List/List";
import Flex from "shared/Flex/Flex";
import ListLoader from "shared/Loader/ListLoader";
import SiderContentHeading from "components/SiderContentHeading";
import CreateSupplierService from "../../../components/SupplierServiceForm/CreateSupplierService";
import EditSupplierService from "../../../components/SupplierServiceForm/EditSupplierService";
import DeleteSupplierService from "../../../components/SupplierServiceForm/DeleteSupplierService";
import SupplierServiceStatus from "../../../components/SupplierServiceForm/SupplierServiceStatus";

export interface SupplierServiceProps {
  service?: SupplierServiceWithId["service"];
}

const SupplierServices: FC<SupplierServiceProps> = ({ service }) => {
  const {
    isLoading,
    data = [],
    showLoadMore,
    loadMoreData,
    setData,
  } = useFetch<SupplierServiceWithId[]>(
    {
      url: `${environment.apiUrl}/supplier-service?serviceId=${service?.id}`,
    },
    50
  );
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: showLoadMore,
    onLoadMore: loadMoreData,
    disabled: false,
    rootMargin: "0px 0px 10px 0px",
  });

  const handleEditSupplierService = (
    supplierService: SupplierServiceWithId
  ) => {
    setData(
      data.map((i) => {
        if (i.id === supplierService.id) {
          return { ...i, ...supplierService } as SupplierServiceWithId;
        }
        return i;
      })
    );
  };

  const handleDeleteSupplierService = (
    supplierService: SupplierServiceWithId
  ) => {
    setData(data.filter((i) => i.id !== supplierService.id));
  };

  return (
    <Flex className="w-full">
      <SiderContentHeading
        icon={<GlobeAltIcon className="w-6 mr-3" />}
        heading={"Suppliers"}
        actionsComponent={
          <CreateSupplierService
            formType="supplier"
            serviceType="HOTEL"
            service={service}
            onAddSupplierService={(supplierService) =>
              setData([...data, supplierService])
            }
          />
        }
      />
      <div className="p-5 w-full">
        {/* <div className="grid grid-cols-12 p-2 text-xs font-thin divide-x-2">
          <Flex className="col-span-5 pl-10">Name</Flex>
          <Flex className="col-span-2" align="center" justify="center">
            Margin
          </Flex>
          <Flex className="col-span-2" align="center" justify="center">
            Status
          </Flex>
          <Flex className="col-span-3" align="center"></Flex>
        </div> */}
        <List
          loading={isLoading}
          items={data}
          rowKey="id"
          renderItem={({ item }) => (
            <div className="grid grid-cols-12 p-2 bg-white">
              <Flex direction="row" align="center" className="col-span-5">
                <BuildingOfficeIcon width={30} className="mr-2" />
                <Flex direction="col" justify="center" className="text-base">
                  {[item.supplier?.name, item.supplier?.country.name]
                    .filter(Boolean)
                    .join(", ")}
                </Flex>
              </Flex>
              <Flex
                direction="row"
                align="center"
                justify="center"
                className="col-span-2 text-base"
              >
                <span>
                  {item.margin?.b2bPercent ??
                    item.supplier?.b2bMarginPercent ??
                    0}
                  %<span className="mx-1">-</span>
                  {item.margin?.b2cPercent ??
                    item.supplier?.b2cMarginPercent ??
                    0}
                  %
                </span>
              </Flex>
              <Flex
                direction="row"
                align="center"
                justify="center"
                className="col-span-2"
              >
                <SupplierServiceStatus
                  supplierService={item}
                  onSuccess={(supplierService) => {
                    setData(
                      data.map((i) =>
                        i.id === supplierService.id
                          ? { ...i, status: supplierService.status }
                          : i
                      )
                    );
                  }}
                />
              </Flex>
              <Flex
                direction="row"
                align="center"
                justify="end"
                className="col-span-3"
              >
                <EditSupplierService
                  formType="supplier"
                  supplierService={item}
                  onEditSupplierService={handleEditSupplierService}
                />
                <DeleteSupplierService
                  formType="supplier"
                  supplierService={item}
                  onDeleteSupplierService={handleDeleteSupplierService}
                />
              </Flex>
            </div>
          )}
        />
        {(isLoading || showLoadMore) && (
          <div ref={sentryRef}>
            <ListLoader />
          </div>
        )}
      </div>
    </Flex>
  );
};

export default SupplierServices;
