import { useEffect, useReducer, useState } from "react";
import {
  UseFetchAction,
  UseFetchState,
  FetchConfig,
  ReFetchConfig,
  UseFetch,
} from "./useFetch.types";
import { addQueryToUrl } from "utils/url";

export const fetcher = async (config: FetchConfig) => {
  const { url, method = "GET", header, body } = config;
  let options = {
    method: method,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      ...(header ? { ...header } : {}),
    },
    body: method === "POST" || method === "PUT" ? body : null,
  };
  return fetch(url, options)
    .then(async (res) => {
      if (!res.ok) {
        return Promise.reject({
          status: res.status,
          info: await res
            .clone()
            .json()
            .catch(() => res.text()),
        });
      }
      return Promise.resolve(
        await res
          .clone()
          .json()
          .catch(() => res.text())
      );
    })
    .catch((error) => Promise.reject(error));
};

const reducer =
  <T,>() =>
  (state: UseFetchState<T>, action: UseFetchAction<T>): UseFetchState<T> => {
    switch (action.type) {
      case "PENDING":
        return {
          ...state,
          isLoading: true,
          error: undefined,
        };
      case "SUCCESS": {
        return {
          isLoading: false,
          isError: false,
          error: undefined,
          data:
            Array.isArray(action.payload) && Array.isArray(state.data)
              ? ([...state.data, ...action.payload] as T)
              : action.payload,
        };
      }
      case "FAILED":
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.error,
        };
      case "UPDATE":
        return {
          ...state,
          data: action.payload,
        };
    }
  };

export const useFetch = <T,>(
  config: FetchConfig,
  initialLimit = 20,
  lazy?: boolean,
  delay: number = 0 // New parameter for delay
): UseFetch<T> => {
  const [lazyFetch, setlazyFetch] = useState(lazy);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [options, setOptions] = useState({
    ...config,
    cursor: { offset: 0, limit: initialLimit },
    resetKey: 0,
  });
  const [state, dispatch] = useReducer(reducer<T>(), {
    isLoading: lazy ? false : true,
    isError: false,
    data: undefined,
    error: undefined,
  });

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      dispatch({ type: "PENDING" });

      const fetchUrl = addQueryToUrl(options.url, {
        limit: options.cursor.limit,
        skip: options.cursor.offset * options.cursor.limit,
      });
      return fetcher({ ...options, url: fetchUrl }).then(
        (result) => {
          if (!cancel) {
            if (!result.length || result.length < options.cursor.limit) {
              setShowLoadMore(false);
            }
            setTimeout(() => {
              dispatch({ type: "SUCCESS", payload: result });
            }, delay);
          }
        },
        (error) => {
          dispatch({ type: "FAILED", error });
        }
      );
    };

    if (!lazyFetch) {
      fetchData();
    } else {
      dispatch({ type: "SUCCESS", payload: undefined as T });
      setlazyFetch(false);
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.url, options.resetKey]);

  const reFetch = (config: ReFetchConfig) => {
    dispatch({ type: "UPDATE", payload: [] as T });
    setOptions((options) => ({
      ...options,
      ...config,
      cursor: { offset: 0, limit: initialLimit },
      resetKey: config.reset ? options.resetKey + 1 : options.resetKey,
    }));
    setShowLoadMore(true);
  };

  const setData = (newData: T) => {
    dispatch({ type: "UPDATE", payload: newData });
  };

  const upsertData = (
    newItem: T extends (infer U)[] ? U : T,
    compareKeys: (keyof (T extends (infer U)[] ? U : T))[] = []
  ) => {
    let newData: T;

    if (Array.isArray(state.data) && newItem) {
      const itemExists = state.data.some((item) =>
        compareKeys.every((key) => item[key] === newItem[key])
      );

      if (itemExists) {
        newData = state.data.map((item) =>
          compareKeys.every((key) => item[key] === newItem[key])
            ? { ...item, ...newItem }
            : item
        ) as T;
      } else {
        newData = [...state.data, newItem] as T;
      }
    } else {
      newData = newItem as T;
    }

    setData(newData);
  };

  const loadMoreData = () => {
    setOptions((options) => ({
      ...options,
      cursor: { ...options.cursor, offset: options.cursor.offset + 1 },
      resetKey: options.resetKey + 1,
    }));
  };

  return {
    ...state,
    options,
    showLoadMore,
    reFetch,
    setData,
    upsertData,
    loadMoreData,
  };
};
