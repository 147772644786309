import React, { FC, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import moment from "moment";
import { get } from "lodash";

import environment from "config/environment";
import { HotelRoomRateWithId } from "domain/hotel-room-rate.type";
import { HotelRoomWithId } from "domain/hotel-room.type";
import { CustomError } from "domain/custom-error.type";
import { fetcher } from "hooks/useFetch";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Label from "shared/Label/Label";
import Select from "shared/Select/Select";
import Button from "shared/Button/Button";
import DateRangeInput, {
  DateRange,
} from "shared/DateRangeInput/DateRangeInput";
import DaySelect, { Day } from "shared/DaySelect/DaySelect";
import Input from "shared/Input/Input";
import Flex from "shared/Flex/Flex";
import { TrashIcon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { mealPlans } from "utils/hotelUtils";
import { addQueryToUrl } from "utils/url";

export interface HotelRoomRateFormProps {
  rooms: HotelRoomWithId[];
  hotelRoomRate?: Partial<HotelRoomRateWithId>;
  className?: string;
  onCancel?: () => void;
  onSuccess?: (hotelRoomRate?: HotelRoomRateWithId) => void;
}

type HotelRoomRateInput = HotelRoomRateWithId & {
  applicableDays: Day[];
  dateRange: DateRange;
};

export const initialState: HotelRoomRateWithId = {
  id: "",
  supplierId: "",
  hotelId: "",
  roomId: "",
  date: "",
  availableQty: 0,
  currency: "",
  buyPrices: [
    {
      mealPlan: "",
      base: 0,
      extraAdult: 0,
      extraChild: 0,
      extraInfant: 0,
    },
  ],
};

const HotelRoomRateForm: FC<HotelRoomRateFormProps> = ({
  rooms = [],
  hotelRoomRate,
  className = "",
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HotelRoomRateInput>({
    defaultValues: {
      ...initialState,
      ...hotelRoomRate,
      applicableDays: hotelRoomRate?.date
        ? [moment(hotelRoomRate?.date).format("ddd").toUpperCase() as Day]
        : [],
      dateRange: {
        startDate: hotelRoomRate?.date ? moment(hotelRoomRate?.date) : null,
        endDate: hotelRoomRate?.date ? moment(hotelRoomRate?.date) : null,
      },
    },
  });
  const {
    fields: buyPrices,
    append: appendBuyPrice,
    remove: removeBuyPrice,
  } = useFieldArray({
    control,
    name: "buyPrices",
  });

  const onSubmit = async (data: HotelRoomRateInput) => {
    console.log({ data });
    try {
      const method = hotelRoomRate?.id ? "PUT" : "POST";
      const payload =
        method === "PUT"
          ? {
              currency: data.currency,
              buyPrices: data.buyPrices,
              availableQty: data.availableQty,
            }
          : {
              supplierId: data.supplierId,
              hotelId: data.hotelId,
              roomId: data.roomId,
              fromDate: data.dateRange.startDate?.format("YYYY-MM-DD"),
              toDate: data.dateRange.endDate?.format("YYYY-MM-DD"),
              applicableDays: data.applicableDays,
              currency: data.currency,
              buyPrices: data.buyPrices,
              availableQty: data.availableQty,
            };
      const result = await fetcher({
        url: [`${environment.apiUrl}/hotel-room-rate`, hotelRoomRate?.id]
          .filter(Boolean)
          .join("/"),
        method: method,
        body: JSON.stringify(payload),
      });

      if (result.errors) {
        setServerErrors(result.errors);
        if (method === "POST") {
          toast.error(`Failed to add rate. Please check the errors.`);
        } else {
          toast.error(`Failed to update rate. Please check the errors.`);
        }
      } else {
        if (method === "POST") {
          if (payload.fromDate === payload.toDate) {
            const hotelRoomRate = await fetcher({
              url: addQueryToUrl(`${environment.apiUrl}/hotel-room-rate`, {
                supplierId: payload.supplierId,
                hotelId: payload.hotelId,
                roomId: payload.roomId,
                fromDate: payload.fromDate,
                toDate: payload.toDate,
              }),
            });
            if (Array.isArray(hotelRoomRate) && hotelRoomRate[0]) {
              onSuccess(hotelRoomRate[0]);
            }
          } else {
            onSuccess();
          }
          toast.success(`The rate is added successfully!`);
        } else {
          onSuccess({ ...result.hotelRoomRate });
          toast.success(`The rate is updated successfully!`);
        }
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const hasFixedDate = !!hotelRoomRate?.date;
  return (
    <form
      className={`grid grid-cols-2 gap-6 ${className}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex className="col-span-2" show={!hasFixedDate}>
        <Label required>Applicable Date</Label>
        <Controller
          name="dateRange"
          control={control}
          rules={{
            validate: (value) => {
              return !!value.startDate && !!value.endDate;
            },
          }}
          render={({ field: { value, onChange } }) => {
            return <DateRangeInput defaultValue={value} onChange={onChange} />;
          }}
        />
        {errors.dateRange && (
          <span className="text-sm text-red-500">
            Please select applicable date range
          </span>
        )}
      </Flex>

      <Flex className="col-span-1" show={hasFixedDate}>
        <Label required>Date</Label>
        <Input
          disabled
          value={moment(hotelRoomRate?.date).format("DD/MM/YYYY")}
        />
      </Flex>

      <Flex className="col-span-2" show={!hasFixedDate}>
        <Label required>Applicable Days</Label>
        <Controller
          name="applicableDays"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <DaySelect defaultValue={[]} value={value} onChange={onChange} />
            );
          }}
        />
        {errors.applicableDays && (
          <span className="text-sm text-red-500">
            Please select applicable days
          </span>
        )}
      </Flex>

      <Flex className="col-span-1">
        <Label required>Room</Label>
        <Select
          className="mt-1"
          disabled={hasFixedDate}
          {...register("roomId", { required: true })}
        >
          <option value="">Select room</option>
          {rooms.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
        {errors.roomId && (
          <span className="text-sm text-red-500">Please select room</span>
        )}
      </Flex>

      <Flex className="col-span-1">
        <Label required>Currency</Label>
        <Select className="mt-1" {...register("currency", { required: true })}>
          <option value="">Select currency</option>
          {["INR", "USD", "EUR", "AED", "THB", "SGD", "IDR", "MVR"].map(
            (item) => (
              <option key={item} value={item}>
                {item}
              </option>
            )
          )}
        </Select>
        {errors.currency && (
          <span className="text-sm text-red-500">Please select currency</span>
        )}
      </Flex>

      <Flex className="col-span-1" show={hasFixedDate}>
        <Label required>Available Quantity</Label>
        <Input
          type="number"
          className="mt-1"
          step={1}
          min={0}
          placeholder="Available Quantity"
          {...register("availableQty", {
            valueAsNumber: true,
            required: true,
            min: 0,
          })}
        />
        {errors.availableQty && (
          <span className="text-sm text-red-500">
            Please enter available quantity
          </span>
        )}
      </Flex>

      <Flex show={!!buyPrices.length} className="col-span-2 mt-4 p-4 bg-gray-200 rounded-lg">
        {buyPrices.map((buyPrice, index) => {
          return (
            <Flex key={index}>
              <Flex direction="row" align="center" className="w-full">
                <Flex className="flex-1 grid grid-cols-6 gap-2">
                  <div className="col-span-2">
                    <Label required show={index === 0}>
                      Meal Plan
                    </Label>
                    <Select
                      className="mt-1"
                      {...register(`buyPrices.${index}.mealPlan`, {
                        required: true,
                      })}
                    >
                      <option value="">Select meal plan</option>
                      {mealPlans.map(({ key, value }) => (
                        <option key={key} value={key}>
                          {key} - {value}
                        </option>
                      ))}
                    </Select>
                    {get(errors, `buyPrices.${index}.mealPlan`) && (
                      <span className="text-sm text-red-500">
                        Please select meal plan
                      </span>
                    )}
                  </div>

                  <div className="col-span-1">
                    <Label required show={index === 0}>
                      Base Rate
                    </Label>
                    <Input
                      type="number"
                      className="mt-1"
                      placeholder=""
                      min={0}
                      {...register(`buyPrices.${index}.base`, {
                        valueAsNumber: true,
                        required: true,
                        min: 0,
                      })}
                    />
                  </div>

                  <div className="col-span-1">
                    <Label required show={index === 0}>
                      Extra Adult
                    </Label>
                    <Input
                      type="number"
                      className="mt-1"
                      placeholder=""
                      min={0}
                      {...register(`buyPrices.${index}.extraAdult`, {
                        valueAsNumber: true,
                        required: true,
                        min: 0,
                      })}
                    />
                  </div>

                  <div className="col-span-1">
                    <Label required show={index === 0}>
                      Extra Child
                    </Label>
                    <Input
                      type="number"
                      className="mt-1"
                      placeholder=""
                      min={0}
                      {...register(`buyPrices.${index}.extraChild`, {
                        valueAsNumber: true,
                        required: true,
                        min: 0,
                      })}
                    />
                  </div>

                  <div className="col-span-1">
                    <Label required show={index === 0}>
                      Extra Infant
                    </Label>
                    <Input
                      type="number"
                      className="mt-1"
                      placeholder="Extra infant"
                      min={0}
                      {...register(`buyPrices.${index}.extraInfant`, {
                        valueAsNumber: true,
                        required: true,
                        min: 0,
                      })}
                    />
                  </div>
                </Flex>
                <TrashIcon
                  className={`ml-3 w-5 h-5 hover:text-red-800 cursor-pointer ${
                    index === 0 ? "mt-5" : ""
                  }`}
                  onClick={() => {
                    removeBuyPrice(index);
                  }}
                />
              </Flex>

              {index + 1 !== buyPrices.length && (
                <hr className="bg-black my-4 w-full" />
              )}
            </Flex>
          );
        })}
      </Flex>

      <Flex className="col-span-2">
        <ButtonSecondary
          type="button"
          className="w-full mb-6 font-thin text-sm border-dotted border-neutral-400 hover:border-primary-400 rounded-2xl dark:border-neutral-700"
          onClick={() => {
            appendBuyPrice({
              mealPlan: "",
              base: 0,
              extraAdult: 0,
              extraChild: 0,
              extraInfant: 0,
            });
          }}
        >
          {buyPrices.length ? "+ Add rates for more meal plan" : "+ Add rate for a meal plan"}
        </ButtonSecondary>
      </Flex>

      <Flex className="col-span-2">
        {serverErrors.map((error) => (
          <p key={error.code} className="text-red-500">
            {error.userMessage}
          </p>
        ))}
      </Flex>

      <Button type="button" bordered onClick={onCancel}>
        Cancel
      </Button>
      <ButtonPrimary type="submit">Submit</ButtonPrimary>
    </form>
  );
};

export default HotelRoomRateForm;
