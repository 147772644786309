import { generatePath } from "react-router-dom";

type QueryObject = {
  [k: string]: any;
};

export function getSearchStringFromObject(object: QueryObject) {
  const keys = Object.keys(object);
  let search = "?";
  keys.forEach((key) => {
    if (
      object[key] !== null &&
      object[key] !== undefined &&
      object[key] !== ""
    ) {
      search += `${key}=${encodeURIComponent(object[key])}&`;
    }
  });
  return search.substring(0, search.length - 1);
}

export function addQueryToUrl(url: string, addQueryObject: QueryObject = {}) {
  const index = url.indexOf("?");
  const domain = index > 0 ? url.substring(0, index) : url;
  const search = index > 0 ? url.slice(index + 1).split("&") : [];

  const existingQueryObject: QueryObject = {};
  search.forEach((attribute) => {
    try {
      const parts = attribute.split("=");
      existingQueryObject[parts[0]] = decodeURIComponent(parts[1]);
    } catch (e) {}
  });
  return `${domain}${getSearchStringFromObject({
    ...existingQueryObject,
    ...addQueryObject,
  })}`;
}

type ExtractPathParams<Path extends string> =
  Path extends `${string}:${infer Param}/${infer Rest}`
    ? Param | ExtractPathParams<`/${Rest}`>
    : Path extends `${string}:${infer Param}?${string}`
    ? Param
    : Path extends `${string}:${infer Param}`
    ? Param
    : never;

type ExtractQueryParams<QueryString extends string> =
  QueryString extends `${string}?${infer Rest}`
    ? Rest extends `${string}=:${infer ParamValue}&${infer Next}`
      ? Record<ParamValue, string | number | boolean> &
          ExtractQueryParams<`?${Next}`>
      : Rest extends `${string}=:${infer ParamValue}`
      ? Record<ParamValue, string | number | boolean>
      : {}
    : {};

type Params<Path extends string> = Record<
  ExtractPathParams<Path>,
  string | number | boolean
> &
  ExtractQueryParams<Path>;

export function generatePathWithQuery<Path extends string>(
  path: Path,
  params: Params<Path>
): string {
  const [basePath, queryString] = path.split("?");
  const generatedPath = generatePath(basePath as string, params);

  if (!queryString) {
    return generatedPath;
  }

  const updatedQueryString = queryString.replace(/:([^&]+)/g, (_, key) => {
    const value = params[key as keyof Params<Path>];
    return encodeURIComponent(String(value ?? ""));
  });

  return `${generatedPath}?${updatedQueryString}`;
}
