import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import environment from "config/environment";
import { CustomError } from "domain/custom-error.type";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAuth } from "context/AuthProvider";
import { fetcher } from "hooks/useFetch";
import Flex from "shared/Flex/Flex";
import Logo from "shared/Logo/Logo";

export interface UpdatePasswordProps {
  className?: string;
}

type UpdatePasswordFormInput = {
  token: string;
  newPassword: string;
  confirmPassword: string;
};


const UpdatePassword: FC<UpdatePasswordProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const [errors, setErrors] = useState<CustomError[]>([]);
  const { isAuthenticated, onSignIn } = useAuth();
  const { register, handleSubmit } = useForm<UpdatePasswordFormInput>({
    defaultValues: {
      token: search.get('rt') as string
    }
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const onSubmit = async (data: UpdatePasswordFormInput) => {
    const result = await fetcher({
      url: `${environment.apiUrl}/update-password`,
      method: "PUT",
      body: JSON.stringify(data),
    });
    if (result.errors) {
      setErrors(result.errors);
    } else {
      onSignIn(result.accessToken, result.refreshToken, result.user);
    }
  };

  return (
    <div className={`app-UpdatePassword  ${className}`} data-app-id="UpdatePassword">
      <Helmet>
        <title>{environment.appName} | Sign up</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <Flex direction="row" justify="center" className="my-10">
          <Logo className="h-14" />
        </Flex>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit(onSubmit)}
          >
        
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input
                type="password"
                className="mt-1"
                {...register("newPassword")}
              />
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input
                type="password"
                className="mt-1"
                {...register("confirmPassword")}
              />
            </label>

            {errors.map((error) => (
              <p key={error.code} className="text-red-500">
                {error.userMessage}
              </p>
            ))}
            <ButtonPrimary type="submit">Submit</ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Remember your Password? <Link to="/sign-in">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
