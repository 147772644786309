 const environment = {
    appName: "Diskounto Holidays",
    appFullName: "Diskounto Holidays Private Limited",
    apiUrl: process.env.REACT_APP_API_URL,
    assetsUrl: process.env.REACT_APP_ASSETS_URL,
    metaPixelId: process.env.REACT_APP_META_PIXEL_ID,
    googleMapEmbedApiKey: process.env.REACT_APP_GOOGLE_MAP_EMBED_API_KEY,
    googleReCaptchaSitekey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
    hCaptchaSitekey: process.env.REACT_APP_HCAPTCHA_SITE_KEY
}
export default environment