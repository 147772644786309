import React from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { UsersIcon } from "@heroicons/react/24/outline";

import environment from "config/environment";
import { UserWithId } from "domain/user.type";
import useFetch from "hooks/useFetch";
import List from "shared/List/List";
import Flex from "shared/Flex/Flex";
import Badge from "shared/Badge/Badge";
import ListLoader from "shared/Loader/ListLoader";
import SiderContentHeading from "components/SiderContentHeading";
import ViewBookingRequest from "./ViewUsers";

const Users = () => {
  const {
    isLoading,
    data = [],
    showLoadMore,
    loadMoreData,
  } = useFetch<UserWithId[]>({ url: `${environment.apiUrl}/user` }, 100);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: showLoadMore,
    onLoadMore: loadMoreData,
    disabled: false,
    rootMargin: "0px 0px 10px 0px",
  });

  return (
    <Flex className="w-full">
      <SiderContentHeading
        icon={<UsersIcon className="w-6 mr-3" />}
        heading={"Users"}
      />
      <div className="p-5 w-full">
        <List
          loading={isLoading}
          items={data}
          rowKey="id"
          renderItem={({ item }) => (
            <div className="grid grid-cols-12 p-2 bg-white">
              <Flex direction="row" align="center" className="col-span-5">
                <Flex className="ml-2">
                  <div className="text-md">
                    {`${item?.firstName} ${item?.lastName}`}
                  </div>

                  <div className="text-sm text-neutral-500">
                    {[item.email, item.mobile].join("/")}
                  </div>
                </Flex>
              </Flex>
              <Flex
                direction="row"
                align="center"
                justify="start"
                className="col-span-4 gap-1"
              >
                {item.roles.map((role) => (
                  <Badge name={role} color="red" />
                ))}
              </Flex>
              <Flex
                direction="row"
                justify="end"
                align="center"
                className="col-span-3"
              >
                <ViewBookingRequest user={item} />
              </Flex>
            </div>
          )}
        />
        {(isLoading || showLoadMore) && (
          <div ref={sentryRef}>
            <ListLoader />
          </div>
        )}
      </div>
    </Flex>
  );
};

export default Users;
