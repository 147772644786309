import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import environment from "config/environment";
import { CustomError } from "domain/custom-error.type";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAuth } from "context/AuthProvider";
import { fetcher } from "hooks/useFetch";
import Flex from "shared/Flex/Flex";
import Logo from "shared/Logo/Logo";
import Captcha from "shared/Captcha/Captcha";

export interface SignUpProps {
  className?: string;
}

type SignUpFormInput = {
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  password: string;
  captchaToken: string;
};

const SignUp: FC<SignUpProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<CustomError[]>([]);
  const { isAuthenticated, onSignUp } = useAuth();
  const {
    control,
    formState: { errors: formErrors },
    register,
    handleSubmit,
  } = useForm<SignUpFormInput>();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const onSubmit = async (data: SignUpFormInput) => {
    const result = await fetcher({
      url: `${environment.apiUrl}/sign-up`,
      method: "POST",
      body: JSON.stringify(data),
    });
    if (result.errors) {
      setErrors(result.errors);
    } else {
      onSignUp(result.accessToken, result.refreshToken, result.user);
    }
  };

  return (
    <div className={`app-SignUp  ${className}`} data-app-id="SignUp">
      <Helmet>
        <title>{environment.appName} | Sign up</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <Flex direction="row" justify="center" className="my-10">
          <Logo className="h-14" />
        </Flex>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                First name
              </span>
              <Input
                type="text"
                placeholder="First name"
                className="mt-1"
                {...register("firstName", { required: true })}
              />
              {formErrors.firstName && (
                <p className="text-red-500 text-xs">
                  Please enter a valid first name
                </p>
              )}
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Last name
              </span>
              <Input
                type="text"
                placeholder="Last name"
                className="mt-1"
                {...register("lastName", { required: true })}
              />
              {formErrors.lastName && (
                <p className="text-red-500 text-xs">
                  Please enter a valid last name
                </p>
              )}
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Mobile no
              </span>
              <Input
                type="text"
                placeholder="Mobile no"
                className="mt-1"
                {...register("mobile", { required: true })}
              />
              {formErrors.mobile && (
                <p className="text-red-500 text-xs">
                  Please enter a valid mobile number
                </p>
              )}
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                {...register("email", { required: true })}
              />
              {formErrors.email && (
                <p className="text-red-500 text-xs">
                  Please enter a valid email address
                </p>
              )}
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input
                type="password"
                placeholder="********"
                className="mt-1"
                {...register("password", { required: true })}
              />
              {formErrors.password && (
                <p className="text-red-500 text-xs">
                  Please enter a new password
                </p>
              )}
            </label>

            <div className="mt-3">
              <Controller
                control={control}
                name="captchaToken"
                rules={{ required: true }}
                render={({ field: { onChange } }) => {
                  return <Captcha onChange={onChange} />;
                }}
              />
              {formErrors.captchaToken && (
                <p className="text-red-500 text-xs">
                  Please verify yourself by clicking above
                </p>
              )}
            </div>

            {errors.map((error) => (
              <p key={error.code} className="text-red-500">
                {error.userMessage}
              </p>
            ))}
            <ButtonPrimary type="submit">Sign up</ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account?{" "}
            <Link to="/sign-in" className="text-primary-500">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
