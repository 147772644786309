import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { Dialog, DialogBackdrop, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface RenderProps {
  openModal: () => void;
  closeModal: () => void;
}

export interface ModalProps {
  renderContent: (modal: RenderProps) => ReactNode;
  renderTrigger?: (modal: RenderProps) => ReactNode;
  fullScreen?: boolean;
  className?: string;
  contentClassName?: string;
  modalTitle?: ReactNode;
  isOpenProp?: boolean;
  showHeader?: boolean;
  onCloseModal?: () => void;
}

const Modal: FC<ModalProps> = ({
  renderTrigger,
  renderContent,
  fullScreen = false,
  className = "",
  contentClassName = "",
  modalTitle = "Modal title",
  showHeader = true,
  isOpenProp,
  onCloseModal = () => {},
}) => {
  let [isOpen, setIsOpen] = useState(!!isOpenProp);

  const closeModal = () => {
    if (typeof isOpenProp !== "boolean") {
      setIsOpen(false);
    }
    onCloseModal();
  };

  const openModal = () => {
    if (typeof isOpenProp !== "boolean") {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    setIsOpen(!!isOpenProp);
  }, [isOpenProp]);

  return (
    <div className="app-Modal">
      {renderTrigger ? renderTrigger({ openModal, closeModal }) : null}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogBackdrop className="fixed inset-0 bg-neutral-900 bg-opacity-50 dark:bg-opacity-80" />
            </TransitionChild>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-75"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`inline-block w-full text-left align-middle transition-all transform bg-white border border-black border-opacity-5 shadow-xl rounded-2xl dark:bg-neutral-800 dark:border-neutral-700 text-neutral-900 dark:text-neutral-300 ${
                  fullScreen ? "w-screen h-screen" : ""
                } ${className}`}
              >
                {showHeader && (
                  <div className="py-4 px-4 relative border-b border-neutral-100 dark:border-neutral-700">
                    <ButtonClose
                      onClick={closeModal}
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 sm:right-4 text-primary-500"
                    />
                    {modalTitle && (
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold text-neutral-900 lg:text-lg dark:text-neutral-200 mr-8"
                      >
                        {modalTitle}
                      </DialogTitle>
                    )}
                  </div>
                )}
                <div
                  className={`px-4 py-4 ${
                    fullScreen ? "overflow-y-scroll h-[calc(100vh-4rem)]" : ""
                  } ${contentClassName}`}
                >
                  {renderContent({ openModal, closeModal })}
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Modal;
