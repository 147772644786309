import { FC } from "react";
import { maxBy, minBy, orderBy } from "lodash";

import { ToursListingWithId } from "domain/tours-listing.type";
import { TourWithId } from "domain/tour.type";
import environment from "config/environment";
import useFetch from "hooks/useFetch";
import Flex from "shared/Flex/Flex";
import LazyImage from "shared/LazyImage/LazyImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import TourPackageCard from "components/TourPackageCard/TourPackageCard";
import TourRequestDetails from "components/TourDetails/TourRequestDetails";
import SocialReviews from "../SocialReviews";

export interface TourListingSectionProps {
  toursListing: ToursListingWithId;
  className?: string;
}

const getDiscountPercent = (maxPricePerPax: number, pricePerPax: number) => {
  return Math.round(((maxPricePerPax - pricePerPax) / maxPricePerPax) * 100);
};

const TourListingSection: FC<TourListingSectionProps> = ({
  toursListing,
  className = "",
}) => {
  const { coverPhotos, listingType } = toursListing;
  const toursByPlacesPath = `/tour?placeIds=${toursListing.placeIds.join(",")}`;
  const toursByIdsPath = `/tour?tourIds=${toursListing.tourIds.join(",")}`;
  const apiPath = listingType === "PLACE" ? toursByPlacesPath : toursByIdsPath;
  const { data: tours = [] } = useFetch<TourWithId[]>(
    {
      url: `${environment.apiUrl}${apiPath}&status=ACTIVE`,
    },
    200
  );

  const tourWithMinPrice = minBy(tours, "pricePerPax");
  const tourWithMaxDiscount = maxBy(tours, (t) =>
    getDiscountPercent(t.maxPricePerPax, t.pricePerPax)
  );
  const minPrice = tourWithMinPrice?.pricePerPax || "";
  const maxDiscountPercent =
    getDiscountPercent(
      tourWithMaxDiscount?.maxPricePerPax as number,
      tourWithMaxDiscount?.pricePerPax as number
    ) || 0;

  return (
    <Flex className={`bg-slate-100 ${className}`}>
      <div className="w-full relative">
        {/* <GallerySlider
          uniqueID={`TourPackageCard_${toursListing.id}`}
          images={coverPhotos.desktop.map(
            (image) => `${environment.assetsUrl}/${image?.path}`
          )}
          ratioClass="aspect-w-2 aspect-h-1"
          className="max-h-screen-without-header overflow-hidden will-change-transform"
          imageClass="w-full h-full max-h-screen-without-header object-cover"
          glideOptions={{
            type: "carousel",
          }}
        /> */}
        <LazyImage
          src={`${environment.assetsUrl}/${coverPhotos.desktop[0]?.path}`}
          className="max-h-screen-without-header w-full"
          containerClassName="hidden sm:block"
          meta={coverPhotos.desktop[0]?.meta}
        />
        <LazyImage
          src={`${environment.assetsUrl}/${coverPhotos.mobile[0]?.path}`}
          className="max-h-screen-without-header w-full"
          containerClassName="sm:hidden"
          meta={coverPhotos.mobile[0]?.meta}
        />
        <div className="absolute bottom-0 w-full py-10 bg-gradient-to-t from-neutral-900 bg-opacity-40">
          <div
            className="container flex flex-col items-center justify-end text-white"
            style={{ textShadow: "#000 0 0 1px" }}
          >
            {maxDiscountPercent > 0 && (
              <span>Upto {maxDiscountPercent}% off on</span>
            )}
            <h1 className="font-semibold text-3xl sm:text-4xl md:text-5xl ">
              {toursListing.title}
            </h1>
            <hr className="w-full my-4 border-dotted " />
            <span className="my-2">Starting at ₹ {minPrice}/-</span>

            <Modal
              modalTitle="Request a callback"
              className="w-screen sm:w-96"
              renderTrigger={({ openModal }) => (
                <ButtonPrimary onClick={openModal} className="mt-2">
                  Request a callback
                </ButtonPrimary>
              )}
              renderContent={() => {
                return <TourRequestDetails btnText="Request Callback" />;
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full z-10 my-4 md:my-8">
        <div className="container">
          <main className="relative z-10 flex flex-col lg:flex-row ">
            <div className="w-full">
              <div id="tour-cards" className="grid grid-cols-12 gap-4">
                {orderBy(tours, "pricePerPax").map((tour) => (
                  <div key={tour.id} className="col-span-12">
                    <TourPackageCard tour={tour} />
                  </div>
                ))}
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="w-full z-10 my-4 md:my-8">
        <SocialReviews media="google" />
      </div>
    </Flex>
  );
};

export default TourListingSection;
