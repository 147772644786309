import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import environment from "config/environment";
import { fetcher } from "hooks/useFetch";
import { useForm } from "react-hook-form";
import Logo from "shared/Logo/Logo";
import Flex from "shared/Flex/Flex";

export interface ForgotPasswordProps {
  className?: string;
}

type ForgotPasswordFormInput = {
  email: string;
};

const ForgotPassword: FC<ForgotPasswordProps> = ({ className = "" }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitted },
  } = useForm<ForgotPasswordFormInput>();

  const onSubmit = async (data: ForgotPasswordFormInput) => {
    await fetcher({
      url: `${environment.apiUrl}/forgot-password`,
      method: "PUT",
      body: JSON.stringify(data),
    });
  };

  return (
    <div
      className={`app-ForgotPassword ${className}`}
      data-app-id="ForgotPassword"
    >
      <Helmet>
        <title>{environment.appName} | Reset Password</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <Flex direction="row" justify="center" className="my-10">
          <Logo className="h-14" />
        </Flex>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="Email address"
                className="mt-1"
                {...register("email")}
              />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                <div></div>
                <Link to="/sign-in" className="text-sm">
                  Back to login
                </Link>
              </span>
            </label>

            {isSubmitted && (
              <p className="text-green-500">
                We have sent an email to reset the password. Please check your
                email.
              </p>
            )}
            <ButtonPrimary type="submit">Reset my password</ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? <Link to="/sign-up" className="text-primary-500">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
