import { HotelWithId } from "domain/hotel.type";

type HotelType = { key: HotelWithId["type"]; value: string };

export const hotelTypes: HotelType[] = [
  {
    key: "resort",
    value: "Resort",
  },
  {
    key: "hotel",
    value: "Hotel",
  },
  {
    key: "villa",
    value: "Villa",
  },
  {
    key: "dome",
    value: "Dome",
  },
  {
    key: "camp",
    value: "Camp",
  },
  {
    key: "treehouse",
    value: "Treehouse",
  },
  {
    key: "houseboat",
    value: "Houseboat",
  },
  {
    key: "homestay",
    value: "Homestay",
  },
];

export const mealPlans = [
  { key: "EP", value: "Room Only" },
  { key: "CP", value: "With Breakfast" },
  { key: "MAP", value: "Half Board" },
  { key: "AP", value: "Full Board" },
]

export const getHotelTypeText = (type: HotelType["key"]) => {
  return hotelTypes.find((item) => item.key === type)?.value || type;
};
