import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Select from "shared/Select/Select";

export interface HotelCheckOutTimeProps {
  register: UseFormRegister<HotelWithId>;
  error?: FieldError;
  className?: string;
}

const HotelCheckOutTime: FC<HotelCheckOutTimeProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label>Check-out</Label>
      <Select
        className="mt-1"
        {...register("details.checkOutTime", { required: true })}
      >
        <option value="">Select check-out time</option>
        <option value="08:00">08:00 AM</option>
        <option value="09:00">09:00 AM</option>
        <option value="10:00">10:00 AM</option>
        <option value="11:00">11:00 AM</option>
        <option value="12:00">12:00 PM</option>
        <option value="13:00">01:00 PM</option>
        <option value="14:00">02:00 PM</option>
        <option value="15:00">03:00 PM</option>
        <option value="16:00">04:00 PM</option>
      </Select>
      {error && (
        <span className="text-sm text-red-500">
          Please enter hotel check-out time
        </span>
      )}
    </div>
  );
};

export default HotelCheckOutTime;
