import { FC } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import ReCaptcha from "react-google-recaptcha";
import environment from "config/environment";

export type CaptchaType = "reCAPTCHA" | "hCaptcha";

export interface CaptchaProps {
  captchaType?: CaptchaType;
  onChange(token: string | undefined): void;
}

export type CaptchaConfig = {
  [CaptchaType: string]: {
    terms: string;
    privacy: string;
  };
};

const Captcha: FC<CaptchaProps> = ({ captchaType = "reCAPTCHA", onChange }) => {
  const captchaConfig: CaptchaConfig = {
    hCaptcha: {
      terms: "https://www.hcaptcha.com/terms",
      privacy: "https://www.hcaptcha.com/privacy",
    },
    reCAPTCHA: {
      terms: "https://policies.google.com/terms",
      privacy: "https://policies.google.com/privacy",
    },
  };

  return (
    <div className="flex flex-row items-center justify-center">
      {captchaType === "hCaptcha" && (
        <HCaptcha
          size="compact"
          sitekey={String(environment.hCaptchaSitekey)}
          onVerify={(token) => onChange(token)}
          onExpire={() => onChange(undefined)}
        />
      )}
      {captchaType === "reCAPTCHA" && (
        <ReCaptcha
          size="compact"
          sitekey={String(environment.googleReCaptchaSitekey)}
          onChange={(token) => onChange(token ? token : undefined)}
          onErrored={() => onChange(undefined)}
          onExpired={() => onChange(undefined)}
        />
      )}

      <span className="text-neutral-800 dark:text-neutral-200 text-sm p-1 mb-1 border-neutral-100 border-r-2 border-2">
        This site is protected by {captchaType} and its{" "}
        <a
          className="text-primary-500"
          href={captchaConfig[captchaType].privacy}
        >
          privacy policy
        </a>{" "}
        and{" "}
        <a className="text-primary-500" href={captchaConfig[captchaType].terms}>
          terms of service
        </a>{" "}
        apply. By procceeding you we assume that you also agree to diskounto
        holidays{" "}
        <a className="text-primary-500" href="/privacy-policy">
          privacy policy
        </a>{" "}
        and{" "}
        <a className="text-primary-500" href="/terms-of-use">
          terms of use.
        </a>
      </span>
    </div>
  );
};

export default Captcha;
