import React from "react";
import moment from "moment";
import { StarIcon } from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { Review } from "domain/review.type";
import Avatar from "shared/Avatar/Avatar";

interface ReviewPopupCardProps {
  review: Review;
  onClose: () => void;
}

const ReviewPopupCard: React.FC<ReviewPopupCardProps> = ({ review, onClose }) => {
  return (
    <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
      <div className="flex justify-between items-start mb-4">
        <div className="flex items-center mb-2">
          <Avatar
            sizeClass="w-8 h-8 sm:w-10 sm:h-10 mr-3"
            imgUrl={review.avatar}
          />
          <div>
            <p className="font-semibold">{review.name}</p>
            <p className="text-sm font-thin text-gray-600">
              {moment(review.date).fromNow()}
            </p>
          </div>
        </div>

        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <XMarkIcon className="w-5 h-5" />
        </button>
      </div>

      <div className="flex text-yellow-400 mb-4">
        {[1, 2, 3, 4, 5].map((star) => (
          <StarIcon
            className={`w-5 h-5 ${
              star <= review.rating ? "text-yellow-400" : "text-gray-400"
            }`}
          />
        ))}
      </div>
      <p className="text-gray-700">{review.comment}</p>
    </div>
  );
};

export default ReviewPopupCard;
