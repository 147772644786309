import React, { FC } from "react";
import { Helmet } from "react-helmet";

import BgGlassmorphism from "components/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection";

import SectionContent from "./SectionContent";
import environment from "config/environment";

export interface AboutUsProps {
  className?: string;
}

const AboutUs: FC<AboutUsProps> = ({ className = "" }) => {
  return (
    <div
      className={`app-AboutUs overflow-hidden relative ${className}`}
      data-app-id="AboutUs"
    >
      <Helmet>
        <title>{environment.appName} | Bank Account</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container py-6 lg:py-12 space-y-16 lg:space-y-28">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionContent />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
