import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import environment from "config/environment";
import { fetcher } from "hooks/useFetch";
import { CustomError } from "domain/custom-error.type";
import CommonLayout from "./CommonLayout";

export interface ChangePasswordProps {
  className?: string;
}

type ChangePasswordInput = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ChangePassword: FC<ChangePasswordProps> = ({ className = "" }) => {
  const [serverErrors, setServerErrors] = useState<CustomError[]>([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ChangePasswordInput>();

  const onSubmit = async (data: ChangePasswordInput) => {
    setServerErrors([]);
    const result = await fetcher({
      url: `${environment.apiUrl}/change-password`,
      method: "PUT",
      body: JSON.stringify(data),
    });
    if (result.errors) {
      setServerErrors(result.errors);
    } else {
      reset({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }
  };

  return (
    <div
      className={`app-ChangePassword ${className}`}
      data-app-id="ChangePassword"
    >
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Update your Password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <form
                className="grid grid-cols-1 gap-6"
                onSubmit={handleSubmit(onSubmit)}
              >
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Current Password
                  </span>
                  <Input
                    type="password"
                    placeholder="Your current password"
                    className="mt-1"
                    {...register("oldPassword", { required: true })}
                  />
                  {errors.oldPassword && (
                    <span className="text-sm text-red-500">
                      Please enter your current password
                    </span>
                  )}
                </label>

                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    New Password
                  </span>
                  <Input
                    type="password"
                    placeholder="Your new password"
                    className="mt-1"
                    {...register("newPassword", { required: true })}
                  />
                  {errors.newPassword && (
                    <span className="text-sm text-red-500">
                      Please confirm your password
                    </span>
                  )}
                </label>

                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Confirm New Password
                  </span>
                  <Input
                    type="password"
                    placeholder="Confirm your new password"
                    className="mt-1"
                    {...register("confirmPassword", { required: true })}
                  />
                  {errors.confirmPassword && (
                    <span className="text-sm text-red-500">
                      Please confirm your new password
                    </span>
                  )}
                </label>

                {serverErrors.map((error) => (
                  <p key={error.code} className="text-red-500">
                    {error.userMessage}
                  </p>
                ))}
                <div className="pt-2">
                  <ButtonPrimary>Update</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default ChangePassword;
