import React, { FC } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HotelWithId } from "domain/hotel.type";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";

export interface HotelCoordinatesProps {
  register: UseFormRegister<HotelWithId>;
  error?: FieldError;
  className?: string;
}

const HotelCoordinates: FC<HotelCoordinatesProps> = ({
  register,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <Label>Coordinates</Label>
      <div className="grid grid-cols-2 gap-2">
        <Input
          type="number"
          className="mt-1"
          placeholder="Latitude"
          {...register("address.coordinates.0", { required: true })}
        />
        <Input
          type="number"
          className="mt-1"
          placeholder="Longitude"
          {...register("address.coordinates.1", { required: true })}
        />
      </div>
      {error && (
        <span className="text-sm text-red-500">
          Please enter valid coordinates
        </span>
      )}
    </div>
  );
};

export default HotelCoordinates;
