import React from "react";
import { HotelWithId } from "domain/hotel.type";

type HotelDescriptionProps = {
    hotel: HotelWithId
};

const HotelDescription = ({ hotel }: HotelDescriptionProps) => {
  return (
    <div className="w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8">
      <h2 className="text-2xl font-semibold">About us</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="text-neutral-600 dark:text-neutral-300">
        {hotel.description}
      </div>
    </div>
  );
};

export default HotelDescription;
