import copyToClipboard from "copy-to-clipboard";
import useFetch from "hooks/useFetch";
import {
  ArrowUpOnSquareStackIcon,
  QueueListIcon,
} from "@heroicons/react/24/outline";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { generatePathWithQuery } from "utils/url";
import { ToursListingWithId } from "domain/tours-listing.type";
import environment from "config/environment";
import List from "shared/List/List";
import LazyImage from "shared/LazyImage/LazyImage";
import Flex from "shared/Flex/Flex";
import ListLoader from "shared/Loader/ListLoader";
import Button from "shared/Button/Button";
import SiderContentHeading from "components/SiderContentHeading";
import CreateToursListing from "./CreateToursListing";
import EditToursListing from "./EditToursListing";
import DeleteToursListing from "./DeleteToursListing";
import ToursListingStatus from "./ToursListingStatus";

const ToursListing = () => {
  const {
    isLoading,
    data = [],
    setData,
    showLoadMore,
    loadMoreData,
  } = useFetch<ToursListingWithId[]>(
    { url: `${environment.apiUrl}/tours-listing` },
    50
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: showLoadMore,
    onLoadMore: loadMoreData,
    disabled: false,
    rootMargin: "0px 0px 10px 0px",
  });

  return (
    <Flex className="w-full">
      <SiderContentHeading
        icon={<QueueListIcon className="w-6 mr-3" />}
        heading={"Tour Listings"}
        actionsComponent={
          <CreateToursListing
            onAddToursListing={(toursListing) =>
              setData([...data, toursListing])
            }
          />
        }
      />
      <div className="p-5 w-full">
        <List
          loading={isLoading}
          items={data}
          rowKey="id"
          renderItem={({ item }) => (
            <div className="grid grid-cols-12 p-2 bg-white">
              <Flex direction="row" align="center" className="col-span-7">
                <ArrowUpOnSquareStackIcon
                  className="text-base cursor-pointer h-6 mr-2 hover:text-primary-700"
                  onClick={() =>
                    copyToClipboard(
                      `${environment.apiUrl}/view/holiday-packages/${item.id}`
                    )
                  }
                />
                <LazyImage
                  src={`${environment.assetsUrl}/${item.marketingPhoto?.path}`}
                  className="w-10 h-10 border border-primary-500 rounded-full"
                  containerClassName="w-auto"
                />
                <Flex className="ml-2">
                  <div className="text-base text-">{item.title}</div>
                  <div className="text-sm text-neutral-400"></div>
                </Flex>
              </Flex>

              <Flex
                direction="row"
                justify="end"
                align="center"
                className="col-span-5"
              >
                <ToursListingStatus
                  toursListing={item}
                  onSuccess={(toursListing) => {
                    setData(
                      data.map((i) =>
                        i.id === toursListing.id ? { ...i, ...toursListing } : i
                      )
                    );
                  }}
                />
                <Button
                  className="ml-4"
                  sizeClass="px-5 py-1"
                  targetBlank
                  href={generatePathWithQuery(
                    "/holiday-packages/:slug?id=:listingId",
                    {
                      slug: item.slug,
                      listingId: item.id,
                    }
                  )}
                >
                  View
                </Button>
                <EditToursListing
                  toursListing={item}
                  onEditToursListing={(toursListing) =>
                    setData(
                      data.map((i) =>
                        i.id === toursListing.id ? { ...i, ...toursListing } : i
                      )
                    )
                  }
                />
                <DeleteToursListing
                  toursListing={item}
                  onDeleteToursListing={(toursListing) =>
                    setData(data.filter((i) => i.id !== toursListing.id))
                  }
                />
              </Flex>
            </div>
          )}
        />
        {(isLoading || showLoadMore) && (
          <div ref={sentryRef}>
            <ListLoader />
          </div>
        )}
      </div>
    </Flex>
  );
};

export default ToursListing;
