export const handlePlural = (
  value: number = 0,
  text: string = "",
  pluralSuffix: string = "",
  plural?: string
) => {
  if(value > 1 && plural) {
    return `${value} ${plural}`
  }
  return value > 1 ? `${value} ${text}${pluralSuffix}` : `${value} ${text}`;
};
