import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { get, pick } from "lodash";

import { ToursListingWithId } from "domain/tours-listing.type";
import Input from "shared/Input/Input";
import Label from "shared/Label/Label";
import Select from "shared/Select/Select";
import DragUploader from "components/DragUploader/DragUploader";
import CommonLayout from "./CommonLayout";

export interface BasicInformationProps {
  toursListing?: ToursListingWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<ToursListingWithId>) => void;
}

const BasicInformation: FC<BasicInformationProps> = ({
  toursListing,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ToursListingWithId>({
    defaultValues: pick<ToursListingWithId>(toursListing, [
      "title",
      "listingType",
      "coverPhotos",
      "marketingPhoto",
    ]),
  });

  const onSubmit = (data: ToursListingWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Package Basic Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-2">
            <Label>Title</Label>
            <Input
              className="mt-1"
              placeholder="Listing title"
              {...register("title", { required: true })}
            />
            {errors.title && (
              <span className="text-sm text-red-500">
                Please enter listing title
              </span>
            )}
          </div>

          <div className="col-span-1">
            <Label>Listing Type</Label>
            <Select
              className="mt-1"
              {...register("listingType", { required: true })}
            >
              <option value="PLACE">Places</option>
              <option value="TOUR">Tours</option>
            </Select>
            {errors.listingType && (
              <span className="text-sm text-red-500">
                Please select a listing type
              </span>
            )}
          </div>

          <div className="col-span-3">
            <Label>Cover Photo - Desktop</Label>
            <p className="text-xs text-neutral-500">
              Upload photo which will be displayed as cover image on desktop.
              (.webp, .jpg, .jpeg, .png & .gif). Prefer aspect ratio of
              2:1(W:H).
            </p>
            <Controller
              name="coverPhotos.desktop"
              control={control}
              rules={{
                validate: (files) => {
                  return (
                    Array.isArray(files) &&
                    files.length > 0 &&
                    files.map((file) => file?.status === "done").filter(Boolean)
                      .length === files.length
                  );
                },
              }}
              render={({ field: { onChange } }) => (
                <DragUploader
                  mode="tour-listing"
                  className="mt-1"
                  maxFiles={1}
                  defaultValue={
                    Array.isArray(toursListing?.coverPhotos.desktop)
                      ? toursListing?.coverPhotos.desktop
                      : []
                  }
                  onChange={onChange}
                />
              )}
            />
            {errors.coverPhotos?.desktop && (
              <span className="text-sm text-red-500">
                Please upload cover photo for desktop.
              </span>
            )}
          </div>

          <div className="col-span-3">
            <Label>Cover Photo - Mobile</Label>
            <p className="text-xs text-neutral-500">
              Upload photo which will be displayed as cover image. (.webp, .jpg,
              .jpeg, .png & .gif). Prefer aspect ratio of 3:4(W:H).
            </p>
            <Controller
              name="coverPhotos.mobile"
              control={control}
              rules={{
                validate: (files) => {
                  return (
                    Array.isArray(files) &&
                    files.length > 0 &&
                    files.map((file) => file?.status === "done").filter(Boolean)
                      .length === files.length
                  );
                },
              }}
              render={({ field: { onChange } }) => (
                <DragUploader
                  mode="tour-listing"
                  className="mt-1"
                  maxFiles={1}
                  defaultValue={
                    Array.isArray(toursListing?.coverPhotos.mobile)
                      ? toursListing?.coverPhotos.mobile
                      : []
                  }
                  onChange={onChange}
                />
              )}
            />
            {errors.coverPhotos?.mobile && (
              <span className="text-sm text-red-500">
                Please upload cover photo for mobile.
              </span>
            )}
          </div>

          <div className="col-span-3">
            <Label>Marketing Photo (Optional)</Label>
            <p className="text-xs text-neutral-500">
              <p className="text-xs text-neutral-500">
                Upload a photo which will be displayed as cover image while
                sharing link in social media (.webp, .jpg, .jpeg, .png & .gif).
                Recommended Size is 1200 x 630 px. Image should be less than
                300kb to properly work in whatsapp.
              </p>
            </p>
            <Controller
              name="marketingPhoto"
              control={control}
              rules={{
                validate: (file) => {
                  return file ? file.status === "done" : true;
                },
              }}
              render={({ field: { onChange } }) => (
                <DragUploader
                  mode="tour-listing"
                  className="mt-1"
                  maxFiles={1}
                  defaultValue={
                    toursListing?.marketingPhoto
                      ? [toursListing?.marketingPhoto]
                      : []
                  }
                  onChange={(files) => {
                    onChange(get(files, "0"));
                  }}
                />
              )}
            />
            {errors.marketingPhoto && (
              <span className="text-sm text-red-500">
                Please upload a marketing photo
              </span>
            )}
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default BasicInformation;
