import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { pick } from "lodash";
import { HotelWithId } from "domain/hotel.type";
import CommonLayout from "./CommonLayout";
import HotelCheckOutTime from "../Inputs/HotelCheckOutTime";
import HotelCheckInTime from "../Inputs/HotelCheckInTime";
import HotelStarRating from "../Inputs/HotelStarRating";
import HotelNoOfRooms from "../Inputs/HotelNoOfRooms";
import HotelPrimeAmenities from "../Inputs/HotelPrimeAmenities";

export interface HotelDetailsFormProps {
  hotel?: HotelWithId;
  step: number;
  onClickPrev: () => void;
  onClickNext: (data: Partial<HotelWithId>) => void;
}

const HotelDetailsForm: FC<HotelDetailsFormProps> = ({
  hotel,
  step,
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HotelWithId>({
    defaultValues: pick<HotelWithId>(hotel, ["details"]),
  });

  const onSubmit = (data: HotelWithId) => {
    onClickNext(data);
  };

  return (
    <CommonLayout
      step={step}
      onClickPrev={onClickPrev}
      onClickNext={handleSubmit(onSubmit)}
    >
      <>
        <h2 className="text-2xl font-semibold">Hotel Details</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-2 gap-6">
          <HotelCheckOutTime
            register={register}
            error={errors.details?.checkOutTime}
          />

          <HotelCheckInTime
            register={register}
            error={errors.details?.checkInTime}
          />

          <HotelStarRating
            register={register}
            error={errors.details?.starRating}
          />

          <HotelNoOfRooms
            register={register}
            error={errors.details?.noOfRooms}
          />

         <HotelPrimeAmenities control={control} className="col-span-2 space-y-2" />
        </div>
      </>
    </CommonLayout>
  );
};

export default HotelDetailsForm;
