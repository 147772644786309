import React, { FC, useEffect, useMemo, useState } from "react";
import Glide from "@glidejs/glide";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/solid";

import { File } from "domain/file.type";
import useUniqueId from "hooks/useUniqueId";
import NextPrev from "shared/NextPrev/NextPrev";
import LazyImage from "shared/LazyImage/LazyImage";
import environment from "config/environment";
import ModalPhotosGallery from "components/ModalPhotosGallery";

export interface PhotoSliderCardsProps {
  modalTitle?: string;
  uniqueClassName: string;
  className?: string;
  itemClassName?: string;
  itemPerRow?: 3 | 4 | 5;
  sliderButtons?: "top-right" | "bottom-center";
  photos?: File[];
}

type PhotosModal = {
  title: string;
  show: boolean;
  photos: string[];
};

const PhotoSliderCards: FC<PhotoSliderCardsProps> = ({
  modalTitle = "",
  uniqueClassName = "",
  className = "",
  itemClassName = "",
  itemPerRow = 3,
  photos = [],
}) => {
  const [photosModal, setPhotosModal] = useState<PhotosModal>({
    show: false,
    title: "",
    photos: [],
  });
  const UNIQUE_CLASS = "PhotoSliderCards__" + uniqueClassName + useUniqueId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 20,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 1.6,
        },
        640: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    if (MY_GLIDEJS) {
      MY_GLIDEJS.mount();
    }
    return () => {
      MY_GLIDEJS.destroy();
    };
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  return (
    <div className={`app-PhotoSliderCards ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <div
          className={`app-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
        >
          <div />
          <div className="my-1 flex justify-end sm:ml-2 flex-shrink-0">
            {photos.length > itemPerRow && <NextPrev btnClassName="w-8 h-8" />}
          </div>
        </div>

        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {photos.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <div
                  className="relative flex-shrink-0 rounded-sm overflow-hidden cursor-pointer"
                  onClick={() =>
                    setPhotosModal({
                      show: true,
                      title: modalTitle,
                      photos: photos.map(
                        (item) => `${environment.assetsUrl}/${item.path}`
                      ),
                    })
                  }
                >
                  <LazyImage
                    src={`${environment.assetsUrl}/${item.path}`}
                    className="hover:opacity-90"
                    meta={item.meta}
                  />
                  <ArrowsPointingOutIcon
                    className="absolute h-6 top-2 right-2 text-white cursor-pointer"
                    fill="white"
                    stroke="gray"
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ModalPhotosGallery
        title={photosModal.title}
        imgs={photosModal.photos}
        isOpen={photosModal.show}
        onClose={() => setPhotosModal({ show: false, title: "", photos: [] })}
        uniqueClassName="app-HotelDetails-modalPhotos"
      />
    </div>
  );
};

export default PhotoSliderCards;
