import { useEffect, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import ReactFacebookPixel from "library/react-facebook-pixel";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import { TourWithId } from "domain/tour.type";
import Input from "shared/Input/Input";
import Label from "shared/Label/Label";
import Flex from "shared/Flex/Flex";
import Badge from "shared/Badge/Badge";
import { handlePlural } from "utils/string";
import { fetcher } from "hooks/useFetch";
import environment from "config/environment";
import { getTourNoOfNights } from "utils/tourUtils";

interface TourRequestDetailsProps {
  tour?: TourWithId;
  btnText?: string;
  isPreview?: boolean;
}

interface TourRequestInput {
  name: string;
  mobile: string;
  referLink: string;
}

const TourRequestDetails = ({
  tour,
  btnText = "Enquire now",
  isPreview = false,
}: TourRequestDetailsProps) => {
  const [showLeadSuccess, setShowLeadSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TourRequestInput>({
    defaultValues: {
      name: "",
      mobile: "",
      referLink: window.location.href,
    },
  });

  useEffect(() => {
    ReactFacebookPixel.trackCustom("EnquiryInitiated", {});
  }, []);

  const onSubmit = async (data: TourRequestInput) => {
    const path = `/lead`;
    const method = "POST";
    const result = await fetcher({
      url: `${environment.apiUrl}${path}`,
      method,
      body: JSON.stringify({
        ...data,
      }),
    });
    if (result.lead) {
      ReactFacebookPixel.trackCustom("EnquirySubmitted", {
        tourId: tour?.id,
        tourName: tour?.title,
        contactName: data.name,
        contactMobile: data.mobile,
      });
      setShowLeadSuccess(true);
    }
  };

  const basePrice = tour?.pricePerPax;
  const noOfNights = getTourNoOfNights(tour);
  const nights = handlePlural(noOfNights, "Night", "s");
  const days = handlePlural(noOfNights + 1, "Day", "s");

  const className =
    "w-full flex flex-col rounded-2xl border border-neutral-200 text-black shadow-xl p-6";
  if (showLeadSuccess) {
    return (
      <div className={className}>
        <Flex align="center">
          <CheckCircleIcon color="green" height={50} width={50} />
          <b className="text-2xl">Congratulations!</b>
          <span>We have received your request.</span>
          <span>We will call you shortly.</span>
        </Flex>
      </div>
    );
  }
  return (
    <div className={className}>
      <Flex direction="row" justify="between" show={!!tour}>
        <Flex align="start">
          <Badge
            color="red"
            name={`${nights} ${days}`}
            className="hidden sm:block"
          />
          <Badge
            color="red"
            name={`${noOfNights}N ${noOfNights + 1}D`}
            className="block sm:hidden"
          />
        </Flex>
        <Flex className="items-end">
          <span className="text-xs font-thin text-green-600">PRICE STARTS</span>
          <span className="text-xl font-semibold">
            ₹ {basePrice}
            <span className="font-thin text-xs"> + GST</span>
          </span>
          <span className="text-xs font-thin">per person on twin sharing</span>
        </Flex>
      </Flex>

      {/* FORM */}
      <form className="!mt-2" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Label>Name</Label>
          <Input
            className="mt-1"
            placeholder="Your Name"
            {...register("name", { required: true })}
          />
          {errors.name && (
            <span className="text-sm text-red-500">Please enter your name</span>
          )}
        </div>

        <div>
          <Label>Mobile No</Label>
          <Input
            className="mt-1"
            placeholder="Your Mobile No"
            {...register("mobile", { required: true })}
          />
          {errors.name && (
            <span className="text-sm text-red-500">
              Please enter your mobile
            </span>
          )}
        </div>

        <div className="flex flex-col space-y-4 mt-4">
          <ButtonPrimary className="flex flex-1 w-full" disabled={isPreview}>
            {btnText}
          </ButtonPrimary>
        </div>
      </form>
    </div>
  );
};

export default TourRequestDetails;
