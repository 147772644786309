import React, { FC, useState } from "react";

import { SupplierWithId } from "domain/supplier.type";
import BasicInformationForm from "components/SupplierForm/StepForm/BasicInformationForm";
import SupplierDetailsForm from "components/SupplierForm/StepForm/SupplierDetailsForm";
import SupplierMarginAndPaymentForm from "components/SupplierForm/StepForm/SupplierMarginAndPaymentForm";

export interface SupplierFormProps {
  supplier?: SupplierWithId;
  onSuccess?: (supplier: SupplierWithId) => void;
}

type State = {
  supplier: SupplierWithId;
  step: number;
};

export const initialState: SupplierWithId = {
  id: "",
  name: "",
  bankAccount: {
    accountHolder: "",
    accountNo: "",
    ifsc: "",
  },
  tradeName: "",
  address: "",
  country: {
    code: "",
    name: "",
  },
  mobile: "",
  email: "",
  pan: "",
  gstn: "",
  b2cMarginPercent: null as unknown as number,
  b2bMarginPercent:  null as unknown as number,
  advancePercent: 0,
  balanceDueDays: 0,
};

const SupplierForm: FC<SupplierFormProps> = ({
  supplier,
  onSuccess = () => {},
}) => {
  let stepComponent;
  const [state, setState] = useState<State>({
    supplier: supplier || initialState,
    step: 1,
  });

  const handlePrev = () => {
    setState({
      ...state,
      step: Math.max(state.step - 1, 1),
    });
  };

  const handleNext = (partialSupplier: Partial<SupplierWithId>) => {
    setState({
      ...state,
      step: Math.min(state.step + 1, 6),
      supplier: { ...state.supplier, ...partialSupplier },
    });
  };

  switch (state.step) {
    case 1:
      stepComponent = (
        <BasicInformationForm
          step={1}
          supplier={state.supplier}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 2:
      stepComponent = (
        <SupplierDetailsForm
          step={2}
          supplier={state.supplier}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        />
      );
      break;

    case 3:
      stepComponent = (
        <SupplierMarginAndPaymentForm
          step={3}
          supplier={state.supplier}
          onClickPrev={handlePrev}
          onSuccess={onSuccess}
        />
      );
      break;


    // case 4:
    //   stepComponent = (
    //     <SupplierPreview
    //       step={6}
    //       supplier={state.supplier}
    //       onClickPrev={handlePrev}
    //       onSuccess={onSuccess}
    //     />
    //   );
    //   break;

    default:
      stepComponent = null;
  }

  return <div>{stepComponent}</div>;
};

export default SupplierForm;
