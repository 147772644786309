import moment from "moment";
import { StarIcon } from "@heroicons/react/24/solid";

import { Review } from "domain/review.type";
import Avatar from "shared/Avatar/Avatar";
import ReviewPopupCard from "./ReviewPopupCard";
import Modal from "shared/Modal/Modal";

const ReviewCard = ({ review }: { review: Review }) => {
  return (
    <li className="glide__slide h-48">
      <div className="bg-white p-4 rounded-lg shadow h-full flex flex-col text-sm">
        <div className="flex items-center mb-2">
          <Avatar
            sizeClass="w-8 h-8 sm:w-9 sm:h-9 mr-3"
            imgUrl={review.avatar}
          />
          <div>
            <p className="font-semibold">{review.name}</p>
            <p className="text-sm font-thin text-gray-600">
              {moment(review.date).fromNow()}
            </p>
          </div>
        </div>
        <div className="flex mb-2">
          {[1, 2, 3, 4, 5].map((star) => (
            <StarIcon
              key={star}
              className={`w-5 h-5 ${
                star <= review.rating ? "text-yellow-400" : "text-gray-400"
              }`}
            />
          ))}
        </div>
        <div className="flex-grow">
          <p className="text-gray-700 line-clamp-3">
            {review.comment.length > 140
              ? review.comment.slice(0, 140).concat("...")
              : review.comment}
          </p>
          {review.comment.length > 140 && (
            <Modal
              className="w-auto"
              showHeader={false}
              renderTrigger={({ openModal }) => (
                <button
                  className="text-gray-800 hover:underline mt-2"
                  onClick={openModal}
                >
                  Read More
                </button>
              )}
              renderContent={({ closeModal }) => {
                return <ReviewPopupCard review={review} onClose={closeModal} />;
              }}
            />
          )}
        </div>
      </div>
    </li>
  );
};

export default ReviewCard;
